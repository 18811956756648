/* eslint-disable max-len */
export const BIC_VALIDATION_PATTERN: string =
  '^[a-zA-Z]{4}\\s?[a-zA-Z]{2}\\s?[a-zA-Z0-9]{2}\\s?[a-zA-Z0-9]{0,3}$';

export const URL_SECURE_VALIDATION_PATTERN: string =
  '^(https:\\/\\/)([\\da-z.-]+)\\.([a-z.]{2,6})[\\/\\w ,.\\-=%&?#()]*\\/?$';

export const DOMAIN_SECURE_VALIDATION_PATTERN: string =
  '^(https:\\/\\/)([\\da-z.-]+)\\.([a-z.]{2,6})$';

export const ZIP_VALIDATION_PATTERN: string =
  '0100[1-9]|010[1-9]\\d|01[1-9]\\d{2}|0[2-9]\\d{3}|[1-8]\\d{4}|9[0-8]\\d{3}|99[0-8]\\d{2}|999[0-8]\\d|9999[0-8]';

// Recommendation: Use this in combination with an maxLength-validator of 253 chars to limit domain to max spec charts
export const DOMAIN_VALIDATION_PATTERN: string =
  '^([a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\\.)*[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]\\.[a-zA-Z]{2,}$';

export const PHONE_VALIDATION_PATTERN: string = '^[0-9+\\- ]*$';
