import { MfUserRolesEnum } from '@app/shared/authentication/types/user_roles.enum';
import { BankFeatures } from '@app/shared/bank-customization/bank-customization.type';

export type MfCurrentNavigationItems =
  | []
  | [
      MfSidenavOptimizedTopLevelItem,
      MfSidenavOptimizedSecondLevelItem,
      MfSidenavOptimizedThirdLevelItem?,
    ];

export interface MfSidenavOptimizedTopLevelItem {
  title: string;
  children: MfSidenavOptimizedSecondLevelItem[];
  hidden: boolean;
}

export interface MfSidenavOptimizedSecondLevelItem {
  title: string;
  link: string;
  linkSegments: string[];
  active: boolean;
  hidden: boolean;
  children?: MfSidenavOptimizedThirdLevelItem[];
}

export interface MfSidenavOptimizedThirdLevelItem {
  title: string;
  link: string;
  linkSegments: string[];
}

export type MfSidenavConfig = Record<MfAppArea, MfSidenavTopLevelItemInterface[]>;

export interface MfSidenavItemInterface {
  title: string;
  link: string;
  visibleForRoles?: MfUserRolesEnum[]; // User needs to have at least one of these roles
  requiredBankFeatures?: BankFeatures[]; // Users bank needs to have at least one of these features
  children?: Omit<MfSidenavItemInterface, 'children' | 'visibleForRoles' | 'hideInNavigation'>[];
  doNotUseAsDefaultRoute?: boolean;
  hideInNavigation?: boolean; // Item should not be shown in navigation
}

export interface MfSidenavTopLevelItemInterface extends Omit<MfSidenavItemInterface, 'link'> {
  children: MfSidenavItemInterface[];
}

export enum MfAppArea {
  MARKETINGFABRIK = 'marketingfabrik',
  MARKETPLACE = 'marketplace',
}
export const isMfAppArea = (value: any): value is MfAppArea =>
  Object.values(MfAppArea).includes(value);
