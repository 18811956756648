<button
  class="btn-tertiary"
  [attr.cdkFocusInitial]="hasInitialFocus ? '' : null"
  [attr.type]="type"
  [color]="'primary'"
  [disabled]="disabled || isLoading"
  [ngClass]="{
    'auto-stretch': autoStretch,
    small: small,
  }"
  (click)="onClicked($event)"
  mat-stroked-button
>
  <span class="flex items-center justify-center" [ngStyle]="{ opacity: isLoading ? 0 : 1 }">
    <mat-icon *ngIf="icon && iconPosition === 'before'" class="mr-1" [svgIcon]="icon" />
    <span *ngIf="label" [ngStyle]="{ 'padding-top.px': icon ? 1 : 0 }">{{ label }}</span>
    <mat-icon *ngIf="icon && iconPosition === 'after'" class="ml-1" [svgIcon]="icon" />
    <mat-icon *ngIf="icon && iconPosition === 'alone'" class="py-1" [svgIcon]="icon" />
  </span>
  <span *ngIf="isLoading" class="is-spinner-inside-button">
    <mat-progress-spinner [color]="'primary'" [diameter]="18" [mode]="'indeterminate'" />
  </span>
</button>
