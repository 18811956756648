import { Maybe } from '@shared/types/util.types';
import { addMinutes, parseISO, toDate } from 'date-fns';

class InvalidDateError extends Error {}

// DateUtil: Handle Date variables with ease
export class DateUtil {
  static safeParseDate(date: Maybe<Date | string | number>): Date | null {
    if (!date) {
      return null;
    }

    let parsedDate: Date;
    if (typeof date === 'string') {
      parsedDate = parseISO(date);
    } else {
      parsedDate = toDate(date);
    }

    // Catch "Invalid Date"
    if (this.isInvalidDate(parsedDate)) {
      return null;
    } else {
      return parsedDate;
    }
  }

  static parseDate(date: Maybe<Date | string | number>): Date {
    const result = this.safeParseDate(date);

    if (!result) {
      throw new InvalidDateError('Invalid Date!');
    }

    return result;
  }

  /** Checks if the supplied parameter is not an object of type date with proper value (not "Invalid Date") */
  static isInvalidDate(date: any | Date): date is any {
    return !this.isValidDate(date);
  }

  /** Checks if the supplied parameter is an object of type date with proper value (not "Invalid Date") */
  static isValidDate(date: any | Date): date is Date {
    return !!date && date instanceof Date && !Number.isNaN(date.getTime());
  }

  /** Clones subject and only set's its day, month and year information to the date of the donor. */
  static updateOnlyDate(getTimeFrom: Date, getDateFrom: Date | null | undefined): Date;
  static updateOnlyDate(getTimeFrom: Date | null | undefined, getDateFrom: Date): Date;
  static updateOnlyDate(
    getTimeFrom: Date | null | undefined,
    getDateFrom: Date | null | undefined
  ): Maybe<Date>;
  static updateOnlyDate(
    getTimeFrom: Date | null | undefined,
    getDateFrom: Date | null | undefined
  ): Maybe<Date> {
    if (!getDateFrom || !getTimeFrom) {
      return getTimeFrom || getDateFrom || null;
    }

    const updatedSubject: Date = new Date(getTimeFrom.getTime());
    updatedSubject.setFullYear(
      getDateFrom.getFullYear(),
      getDateFrom.getMonth(),
      getDateFrom.getDate()
    );

    return updatedSubject;
  }

  static getTodayWithTime(
    hours: number = 0,
    minutes: number = 0,
    seconds: number = 0,
    milliseconds: number = 0
  ): Date {
    const date = new Date();
    date.setHours(hours, minutes, seconds, milliseconds);

    return date;
  }

  static roundToNextHalfHour(inputDate: Date) {
    return this.roundToMinuteStep(inputDate, 30, true);
  }

  static roundToMinuteStep(inputDate: Date, step: number, forceNextArg?: boolean) {
    if (step < 1 || step > 60) {
      throw new RangeError('step can not be outside of range 1-60');
    }

    let forceNextStep = !!forceNextArg;
    const date = new Date(inputDate.getTime());
    const hasNonZeroSecondsOrMilliseconds = !!(date.getMilliseconds() || date.getSeconds());

    // Reset seconds and milliseconds
    date.setSeconds(0);
    date.setMilliseconds(0);

    const minutes = date.getMinutes();
    // Force next step when minutes of date are aligned to the next step, but we are at least one ms after it
    if (!(minutes % step) && hasNonZeroSecondsOrMilliseconds) {
      forceNextStep = true;
    }

    let nextStep = 0;
    while (minutes > nextStep || (forceNextStep && minutes === nextStep)) {
      nextStep += step;
    }

    const distanceToNextStep = nextStep - minutes;

    return addMinutes(date, distanceToNextStep);
  }

  static setToMidnight(date: Date) {
    date.setHours(0, 0, 0, 0);

    return date;
  }
}
