import { DateUtil } from '@shared/util/date/date.util';

export class DateRange<D = Date> {
  constructor(
    public start: D | null = null,
    public end: D | null = null
  ) {}

  static fromObject(value: DateRange<Date | string> | null): DateRange | null {
    if (value === null) {
      return null;
    }

    if (typeof value === 'object') {
      return new DateRange(DateUtil.safeParseDate(value.start), DateUtil.safeParseDate(value.end));
    }

    return null;
  }
}
