import { Attribute, Component, EventEmitter, Input, Output, booleanAttribute } from '@angular/core';
import { Params, Router } from '@angular/router';
import { MfIcon } from '@app/material/icons/icons.const';
import { Maybe } from '@shared/types/util.types';

@Component({ template: '' })
export abstract class MfFormAbstractButtonComponent {
  @Input() link?: string | string[];
  @Input() queryParams?: Params;
  @Input() label: Maybe<string>;
  @Input() icon?: MfIcon;
  @Input() iconPosition: string = 'before';
  @Input({ transform: booleanAttribute }) isLoading: boolean = false;
  @Input({ transform: booleanAttribute }) hasInitialFocus: boolean = false;
  @Input({ transform: booleanAttribute }) disabled: boolean = false;
  @Input({ transform: booleanAttribute }) small: boolean = false;
  @Input({ transform: booleanAttribute }) autoStretch: boolean = false;

  @Output() clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor(
    @Attribute('type') public readonly type: string,
    private router: Router
  ) {
    this.type = type || 'button';
  }

  onClicked($event: any): void {
    if (this.link) {
      if (this.queryParams) {
        this.router.navigate(Array.isArray(this.link) ? this.link : [this.link], {
          queryParams: this.queryParams,
        });
      } else {
        this.router.navigate(Array.isArray(this.link) ? this.link : [this.link]);
      }
    } else {
      this.clicked.emit($event);
    }
  }
}
