export class SerializationUtil {
  static toInstance<T extends Record<PropertyKey, any>>(target: T, json: any): T {
    let jsonObject: any;

    if (typeof json === 'string') {
      try {
        jsonObject = JSON.parse(json);
      } catch {
        jsonObject = {};
      }
    } else {
      jsonObject = json;
    }

    if (typeof target['fromJSON'] === 'function') {
      target['fromJSON'](jsonObject);
    } else {
      for (const key of Object.keys(jsonObject)) {
        // @ts-ignore
        target[key] = jsonObject[key] !== null ? jsonObject[key] : undefined;
      }
    }

    return target;
  }
}
