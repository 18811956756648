import { camelCase, kebabCase, snakeCase } from 'lodash-es';

export class StringUtil {
  static lengthOfTextInHtml(html: string): number {
    const element: HTMLDivElement = document.createElement('div');
    element.innerHTML = html;

    return element.innerText.length;
  }

  static countOccurrences(value: string, search: string): number {
    // Special case for empty search strings as they match nothing
    if (!search) {
      return 0;
    }

    return value.split(search).length - 1;
  }

  /** Converts a string from kebab-case or snake_case to camelCase */
  static toCamelCase(value: string): string {
    return value
      .split('.')
      .map((part) => camelCase(part))
      .join('.');
  }

  /** Converts a string from camelCase or snake_case to kebab-case */
  static toKebabCase(value: string): string {
    return value
      .split('.')
      .map((part) => kebabCase(part))
      .join('.');
  }

  /** Converts a string from camelCase to snake_case */
  static toSnakeCase(value: string, screaming?: boolean): string {
    const preparedValue = value
      .split('.')
      .map((part) => snakeCase(part))
      .join('.');

    return screaming ? preparedValue.toUpperCase() : preparedValue.toLowerCase();
  }

  static stripTags(value: string): string {
    return value.replace(/<\/?[^>]+(>|$)/g, '');
  }

  static replaceAll(value: string, search: RegExp | string, replace: string) {
    return value.split(search).join(replace);
  }

  static isUuid(value: string): boolean {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
      value
    );
  }
}
