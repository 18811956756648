import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MfDataManagerImportPasswordsInterface } from '@app/data-manager/components/import/uploader/uploader.types';
import { MfDataManagerImportInterface } from '@app/data-manager/services/import-data/data.interface';
import { MfDataManagerImportDataService } from '@app/data-manager/services/import-data/data.service';
import {
  MfDataManagerImportStepEnum,
  MfDataManagerImportUploadStateInterface,
} from '@app/data-manager/services/import-uploader/import-uploader.types';
import { MfHttpErrorResponseCode } from '@shared/data/error.enum';
import { MfHttpErrorResponse } from '@shared/data/error.interface';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MfDataManagerImportUploaderService {
  private importStep$ = new BehaviorSubject<MfDataManagerImportStepEnum>(
    MfDataManagerImportStepEnum.NO_FILE_SELECTED
  );
  private selectedFile$ = new BehaviorSubject<File | null>(null);
  private uploadState$ = new BehaviorSubject<MfDataManagerImportUploadStateInterface>(
    this.createEmptyUploadState()
  );
  private uploadedImport$ = new BehaviorSubject<MfDataManagerImportInterface | null>(null);

  private lastPasswordData?: Partial<MfDataManagerImportPasswordsInterface>;

  private cancelUpload$ = new Subject<void>();

  constructor(private dataService: MfDataManagerImportDataService) {
    this.importStep$
      .pipe(
        filter((step) => step === MfDataManagerImportStepEnum.FINISHED),
        debounceTime(5000)
      )
      .subscribe({
        next: () => {
          this.importStep$.next(MfDataManagerImportStepEnum.NO_FILE_SELECTED);
          this.reset();
        },
      });
  }

  get importStep(): Observable<MfDataManagerImportStepEnum> {
    return this.importStep$.asObservable();
  }

  get selectedFile(): Observable<File | null> {
    return this.selectedFile$.asObservable();
  }

  get uploadState(): Observable<MfDataManagerImportUploadStateInterface | null> {
    return this.uploadState$.asObservable();
  }

  get uploadedImport(): Observable<MfDataManagerImportInterface | null> {
    return this.uploadedImport$.asObservable();
  }

  get cancelUpload(): Observable<void> {
    return this.cancelUpload$.asObservable();
  }

  public selectFile(file: File): void {
    this.selectedFile$.next(file);
    this.importStep$.next(MfDataManagerImportStepEnum.FILE_SELECTED);

    this.clearLastPasswordData();
  }

  public removeFile(): void {
    this.importStep$.next(MfDataManagerImportStepEnum.NO_FILE_SELECTED);
    this.selectedFile$.next(null);
  }

  public submitFile(): void {
    const file: File | null = this.selectedFile$.getValue();

    if (!file) {
      return;
    }

    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.importStep$.next(MfDataManagerImportStepEnum.UPLOADING);
    this.updateUploadState({ filename: file.name, totalBytes: file.size });

    this.dataService
      .uploadFile(formData)
      .pipe(takeUntil(this.cancelUpload$))
      .subscribe({
        next: (event: HttpEvent<MfDataManagerImportInterface>) => {
          switch (event.type) {
            case HttpEventType.Sent:
              this.updateUploadState({
                startTimeMs: Date.now(),
              });
              break;
            case HttpEventType.UploadProgress:
              const loadedBytes = event.loaded;
              const totalBytes = event.total || loadedBytes;

              const updatedValues = {
                loadedBytes,
                totalBytes,
                progress: Math.round(100 * (loadedBytes / totalBytes)),
              };

              this.updateUploadState({
                ...updatedValues,
                uploadTimeEstimate: this.getUploadTimeEstimate({
                  ...this.uploadState$.getValue(),
                  ...updatedValues,
                }),
              });
              break;
            case HttpEventType.Response:
              this.completeFileUpload(event.body!);
              break;
          }
        },
        error: (error: MfHttpErrorResponse) => {
          this.updateUploadState({
            responseStatus: error.status,
          });
          this.importStep$.next(MfDataManagerImportStepEnum.UPLOAD_ERROR);
        },
      });
  }

  public getUploadTimeEstimate(
    upload: Pick<MfDataManagerImportUploadStateInterface, 'startTimeMs' | 'progress'>,
    currentTimestamp: number = Date.now()
  ): number {
    const spendTime: number = currentTimestamp - upload.startTimeMs;
    const leftoverProgressPercentage: number = (100 - upload.progress) / upload.progress;

    return spendTime * leftoverProgressPercentage;
  }

  private completeFileUpload(data: MfDataManagerImportInterface): void {
    this.uploadedImport$.next(data);
    this.importStep$.next(MfDataManagerImportStepEnum.NEEDS_PASSWORDS);
    this.selectedFile$.next(null);
    this.resetUploadState();
  }

  public submitPasswords(passwords: MfDataManagerImportPasswordsInterface): void {
    this.importStep$.next(MfDataManagerImportStepEnum.SUBMITTING_PASSWORDS);

    const passwordsWithPreviousData = {
      ...this.lastPasswordData,
      ...passwords,
    };

    this.lastPasswordData = passwordsWithPreviousData;

    this.dataService
      .patchPasswords(this.uploadedImport$.getValue()!.id, passwordsWithPreviousData)
      .pipe(takeUntil(this.cancelUpload$))
      .subscribe({
        next: (data: MfDataManagerImportInterface) => this.completeSubmitPassword(data),
        error: (error: MfHttpErrorResponse) => this.handleSubmitPasswordError(error),
      });
  }

  private completeSubmitPassword(data: MfDataManagerImportInterface): void {
    this.uploadedImport$.next(data);
    this.importStep$.next(MfDataManagerImportStepEnum.FINISHED);

    this.dataService.updateUploadItems().subscribe();
  }

  private handleSubmitPasswordError(error: MfHttpErrorResponse): void {
    switch (error?.error?.code) {
      case MfHttpErrorResponseCode.DATA_COULD_NOT_BE_UNCOMPRESSED_PASSWORD_WRONG:
        this.importStep$.next(MfDataManagerImportStepEnum.ENCRYPTION_PASSWORD_WRONG);
        break;

      case MfHttpErrorResponseCode.DATA_COULD_NOT_BE_STORED_PASSWORD_WRONG:
        this.importStep$.next(MfDataManagerImportStepEnum.PSEUDONYMIZATION_PASSWORD_WRONG);
        break;

      default:
        this.importStep$.next(MfDataManagerImportStepEnum.UNKNOWN_ERROR);
        break;
    }
  }

  public cancelImport(): void {
    this.importStep$.next(MfDataManagerImportStepEnum.NO_FILE_SELECTED);

    this.cancelUpload$.next();
    this.reset();
  }

  private clearLastPasswordData(): void {
    this.lastPasswordData = undefined;
  }

  private reset(): void {
    this.selectedFile$.next(null);
    this.uploadedImport$.next(null);
    this.resetUploadState();
    this.clearLastPasswordData();
  }

  private resetUploadState(): void {
    this.uploadState$.next(this.createEmptyUploadState());
  }

  private updateUploadState(newData: Partial<MfDataManagerImportUploadStateInterface>): void {
    this.uploadState$.next({
      ...this.uploadState$.value,
      ...newData,
    });
  }

  private createEmptyUploadState(): MfDataManagerImportUploadStateInterface {
    return {
      loadedBytes: 0,
      totalBytes: 0,
      progress: 0,
      responseStatus: 0,
      startTimeMs: 0,
      uploadTimeEstimate: 0,
      filename: '',
    };
  }
}
