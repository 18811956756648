import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MfUserCustomizationType } from '@app/shared/user-customization/user-customization.service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root',
})
export class MfUserCustomizationDataService {
  constructor(private http: HttpClient) {}

  getData(): Observable<MfUserCustomizationType> {
    return this.http.get<MfUserCustomizationType>('v1/user-configuration-all');
  }

  saveAll(data: MfUserCustomizationType): Observable<MfUserCustomizationType> {
    return this.http.post<MfUserCustomizationType>('v1/user-configuration-all', data);
  }
}
