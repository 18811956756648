import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { MfFormLengthHandlerComponent } from '@app/form/field/shared/length-handler/length-handler.component';
import { MaterialModule } from '@app/material/material.module';
import { StringUtil } from '@app/shared/util/string.util';
import { HashMap, TranslocoModule, TranslocoService } from '@jsverse/transloco';

export interface ErrorType {
  key: string;
  val: HashMap;
}

@Component({
  selector: 'mf-form-util-error-handler',
  templateUrl: './error-handler.component.html',
  styleUrls: ['./error-handler.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule, TranslocoModule, MfFormLengthHandlerComponent],
})
export class MfFormErrorHandlerComponent {
  @Input({ required: true }) field!: AbstractControl;
  @Input({ required: true }) translationPrefix!: string;
  @Input() patternName?: string;
  @Input() translationPrefixScope: string = '';
  @Input() concealError?: boolean;
  @Input() maxLengthHint?: number;

  constructor(private transloco: TranslocoService) {}

  get shouldShowError(): boolean {
    if (this.concealError) {
      return false;
    }

    return !!(this.field?.invalid && (this.field.dirty || this.field.touched));
  }

  get firstError() {
    return this.filterErrors(this.field?.errors)[0];
  }

  getErrorShouldBeTranslated(item: ErrorType): boolean {
    return item?.key !== 'customErrorMessage';
  }

  getErrorLabel(item: ErrorType): string {
    if (!this.getErrorShouldBeTranslated(item)) {
      return item?.val?.['message'];
    }

    return this.transloco.translate(
      StringUtil.toSnakeCase(
        this.translationPrefix + (item?.key === 'pattern' ? this.patternName : item?.key),
        true
      ),
      item?.val,
      this.translationPrefixScope || undefined
    );
  }

  filterErrors(errors: ValidationErrors | null): ErrorType[] {
    const result: ErrorType[] = [];

    if (errors) {
      Object.keys(errors).forEach((key: string) => {
        const matches = key.match(/Value$/g);
        if (matches) {
          return;
        }

        result.push({
          key,
          val: errors[key],
        });
      });
    }

    return result;
  }
}
