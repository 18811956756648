export class MathUtil {
  static getClosestValueInArray(array: number[], goal: number): number {
    const sortedArray: number[] = array.sort((a: number, b: number) => a - b);

    return sortedArray.reduce((prev: number, curr: number) =>
      Math.abs(curr - goal) < Math.abs(prev - goal) ? curr : prev
    );
  }

  static divisionWithRemainder(dividend: number, divisor: number): [number, number] {
    if (!divisor) {
      throw new Error("Can't divide by 0!");
    }

    const remainder: number = dividend % divisor;
    const quotient: number = Math.floor(dividend / divisor);

    return [quotient, remainder];
  }

  static clamp(value: number, min: number, max: number) {
    return Math.max(min, Math.min(max, value));
  }
}
