<ng-container *transloco="let t">
  <ng-container *ngIf="control">
    <div
      class="custom-form-field"
      [class.invalid]="validationControl.invalid"
      [class.touched]="validationControl.touched"
    >
      <div class="mb-3 truncate">
        <div *ngIf="displayedFileName" class="inline-flex items-center justify-center space-x-2">
          <span>{{ displayedFileName }}</span>
          <button (click)="removeFile()" color="primary" mat-icon-button type="button">
            <mat-icon svgIcon="close" />
          </button>
        </div>
        <span *ngIf="!displayedFileName">-</span>
      </div>

      <input
        [accept]="mimeTypesAsString"
        (change)="handleSelectFile($event)"
        #fileInputRef
        hidden
        type="file"
      />

      <div class="mf-custom-button">
        <button
          class="btn-secondary"
          [color]="'primary'"
          [disabled]="control.disabled"
          (click)="triggerSelectFile(fileInputRef)"
          mat-stroked-button
          type="button"
        >
          <span class="button-label">{{ t('SHARED.FORMS.LABELS.CHOOSE_FILE') }}</span>
        </button>
      </div>

      <div *ngIf="maxAspectRatio && !(error || shouldShowRequiredError)" class="mt-2">
        <mat-hint class="flex items-center">
          <ng-container *ngIf="!isRequired && showOptionalHint">
            {{ t('SHARED.FORMS.OPTIONAL') }}:
          </ng-container>
          {{
            t('SHARED.FORMS.UPLOAD.RATIO_HINT', {
              width: maxAspectRatio[0],
              height: maxAspectRatio[1],
              widthExample: exampleSizes[0],
              heightExample: exampleSizes[1],
            })
          }}
        </mat-hint>
      </div>

      <div *ngIf="maxHeight && maxWidth && !(error || shouldShowRequiredError)" class="mt-2">
        <mat-hint class="flex items-center">
          <ng-container *ngIf="!isRequired && showOptionalHint && !maxAspectRatio">
            {{ t('SHARED.FORMS.OPTIONAL') }}:
          </ng-container>
          {{
            t('SHARED.FORMS.UPLOAD.FILE_HINT', {
              formats: formattedFormats,
              width: maxWidth,
              height: maxHeight,
            })
          }}
        </mat-hint>
      </div>

      <div *ngIf="error || shouldShowRequiredError" class="mt-2">
        <mat-error *ngIf="shouldShowRequiredError; else imgErrorTemplate" class="error-custom">
          <mat-icon class="mr-1" svgIcon="error" />
          <!-- Assume that all errors will be required errors as other error cases don't make sense atm -->
          <span>{{ t(translationPrefix + 'IMAGE_INPUT.REQUIRED') }}</span>
        </mat-error>
        <ng-template #imgErrorTemplate>
          <mat-error class="error-custom">
            <mat-icon class="mr-1" svgIcon="error" />
            <span>{{ error }}</span>
          </mat-error>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>
