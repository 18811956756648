<div class="flex">
  <mat-form-field
    *ngIf="control"
    class="flex-1 basis-full"
    [hideRequiredMarker]="hideAsterisk"
    [ngClass]="[name, noBottomOffset ? 'no-bottom-offset' : '']"
  >
    <mat-label>{{ label }}</mat-label>

    <div class="text-prefix-container">
      <input
        [autocomplete]="disableAutocomplete ? 'off' : 'on'"
        [errorStateMatcher]="errorMatcher"
        [formControl]="urlFormControl"
        [name]="name"
        [placeholder]="placeholder"
        [required]="isRequired"
        matInput
        type="url"
      />
      <span class="text-prefix">{{ protocol }}</span>
    </div>

    <mat-spinner *ngIf="control.pending" [diameter]="24" matSuffix />

    <mat-hint>
      <mf-form-util-hint-handler
        [field]="control"
        [hideInfoIcon]="hideInfoIcon"
        [hintLabel]="hintLabel"
        [isRequired]="isRequired"
        [maxLengthHint]="maxLengthHint"
        [patternName]="patternName"
        [showOptionalHint]="showOptionalHint"
        [showValidationSuccess]="showValidationSuccess"
      />
    </mat-hint>

    <mat-error [hidden]="hideErrorMessage">
      <mf-form-util-error-handler
        [field]="control"
        [patternName]="patternName"
        [translationPrefix]="translationPrefix"
        [translationPrefixScope]="translationPrefixScope"
      />
    </mat-error>
  </mat-form-field>

  <a
    *ngIf="control.valid && !!control.value"
    class="link my-2 ml-3"
    [href]="control.value"
    target="_blank"
  >
    <mat-icon svgIcon="external_link" />
  </a>
</div>
