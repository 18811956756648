import { inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';

/** @deprecated prefer usage of MfAbstractDialogInjected */
export abstract class MfAbstractDialog<DataType, ReturnType> {
  abstract data: DataType;

  abstract close(value: ReturnType): void;
}

export abstract class MfAbstractDialogInjected<DataType, ReturnType> {
  data: DataType = inject(MAT_LEGACY_DIALOG_DATA);

  protected dialogRef = inject(MatLegacyDialogRef<typeof this>);

  close(value: ReturnType | undefined = undefined): void {
    this.dialogRef.close(value);
  }
}
