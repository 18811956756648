<ng-container *transloco="let t">
  <div class="mf-logo-wrapper hidden items-center justify-center xl:flex">
    <a [routerLink]="redirectUrl()">
      <img class="h-6" [attr.alt]="t(logoTitle)" [src]="logo" />
    </a>
  </div>

  <mf-layout-sidenav-content />

  <mf-layout-sidenav-user-actions [entityName]="entityName" [userName]="userName" />
</ng-container>
