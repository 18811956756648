import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MfHtmlInputPlaceholder } from '@app/form/field/html-input/html-input.types';
import {
  PHONE_VALIDATION_PATTERN,
  URL_SECURE_VALIDATION_PATTERN,
} from '@app/form/field/shared/validators/validator-pattern';
import { ArrayUtil, MfArrayUtilPartitionResult } from '@app/shared/util/array.util';
import { StringUtil } from '@app/shared/util/string.util';

export namespace CustomValidators {
  const isEmptyInputValue = (value: any): boolean =>
    value === null ||
    value === undefined ||
    value === '' ||
    (Array.isArray(value) && value.length === 0);

  export const compareFields =
    (fieldToCompare: string): ValidatorFn =>
    (c: AbstractControl): ValidationErrors | null => {
      const confirmPass: any = c.value || {};
      const pass: any = c.root.value[fieldToCompare];

      return pass === confirmPass ? null : { noMatch: true };
    };

  export const maxLengthOfTextInHtml =
    (length: number): ValidatorFn =>
    (c: AbstractControl): ValidationErrors | null => {
      const value: string = c.value || '';

      return StringUtil.lengthOfTextInHtml(value) > length
        ? { maxTextInHtmlLength: { requiredLength: length } }
        : null;
    };

  export const maxParagraphTagsInHtml =
    (count: number): ValidatorFn =>
    (c: AbstractControl): ValidationErrors | null => {
      const value: any = c.value ?? null;

      // Don't validate empty values to allow optional controls
      if (isEmptyInputValue(value)) {
        return null;
      }

      return StringUtil.countOccurrences(value, '<p') > count
        ? { tooManyParagraphs: { max: count } }
        : null;
    };

  export const noScriptTagValidator =
    (): ValidatorFn =>
    (c: AbstractControl): ValidationErrors | null => {
      const value: string = c.value || '';

      return value.search('<script') > -1 || value.search('</script>') > -1
        ? { scriptTag: true }
        : null;
    };

  export const onlyPositiveNumbers =
    (includeZero: boolean = false): ValidatorFn =>
    (c: AbstractControl): ValidationErrors | null => {
      let value: any = c.value ?? null;

      // Don't validate empty values to allow optional controls
      if (isEmptyInputValue(value)) {
        return null;
      }

      if (typeof value === 'string') {
        value = +value;
      }

      if (!Number.isNaN(value) && (value > 0 || (includeZero && value === 0))) {
        return null;
      } else {
        return { notPositive: true };
      }
    };

  export const onlyNaturalNumbers =
    (): ValidatorFn =>
    (c: AbstractControl): ValidationErrors | null => {
      let value: any = c.value;

      // Don't validate empty values to allow optional controls
      if (isEmptyInputValue(value)) {
        return null;
      }

      if (typeof value === 'string') {
        value = +value;
      }

      // NOTE: Number.isInteger only checks whether the number isn't a float, not if the number fits within an integer
      return Number.isNaN(value) || !Number.isInteger(value) ? { notNaturalNumber: true } : null;
    };

  /** Specify two sets of validators to be used on non-array and array values */
  export const valueArrayJunction =
    (nonArrayValidators: ValidatorFn[], arrayValidators: ValidatorFn[]): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      let validators: ValidatorFn[];
      if (Array.isArray(control.value)) {
        validators = arrayValidators;
      } else {
        validators = nonArrayValidators;
      }

      for (const validator of validators) {
        const validationResult: ValidationErrors | null = validator(control);

        if (validationResult) {
          return validationResult;
        }
      }

      return null;
    };

  /** Run a validator on each value of an array. Will not return error if current value is not an array */
  export const applyOnArrayEntries =
    (validator: ValidatorFn): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const value: any = control.value ?? null;

      if (Array.isArray(value)) {
        for (const valueItem of value) {
          const validationResult: ValidationErrors | null = validator(
            new UntypedFormControl(valueItem)
          );

          if (validationResult) {
            return validationResult;
          }
        }
      }

      return null;
    };

  export const allPlaceholdersUsed =
    (
      placeholders: MfHtmlInputPlaceholder[],
      delimiters: [string, string] = ['{{', '}}']
    ): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      let value: any = control.value ?? null;

      if (!value) {
        return null;
      }

      value = String(value);

      const [included, excluded]: MfArrayUtilPartitionResult<MfHtmlInputPlaceholder> =
        ArrayUtil.partition(placeholders, (placeholder: MfHtmlInputPlaceholder) =>
          value.includes(`${delimiters[0]}${placeholder.id}${delimiters[1]}`)
        );

      if (!excluded?.length) {
        return null;
      }

      const missing: string[] = excluded.map((item: MfHtmlInputPlaceholder) => item.label);

      return excluded.length
        ? {
            placeholdersMissing: {
              included,
              missing,
              missingAsString: missing.join(', '),
            },
          }
        : null;
    };

  export const requiredWhenSiblingMatches =
    (siblingName: string, expectedValue: any): ValidatorFn =>
    (control: AbstractControl): ValidationErrors | null => {
      const siblingControl = control.parent?.get(siblingName);
      if (!siblingControl) {
        return null;
      }

      const otherControlValue: any = siblingControl.value;

      if (expectedValue !== otherControlValue) {
        return null;
      }

      const { value } = control;

      return value ? null : { required: true };
    };

  export const secureUrl = () => Validators.pattern(URL_SECURE_VALIDATION_PATTERN);

  export const phone = () => (control: AbstractControl) => {
    const value: any = control.value ?? null;
    if (!value) {
      return null;
    }

    const valueAsString = String(value);

    return !valueAsString.match(PHONE_VALIDATION_PATTERN) ? { phone: true } : null;
  };

  export const startsWith: (phrase: string) => ValidatorFn = (phrase) => (control) => {
    const value: any = control.value ?? null;
    if (!value) {
      return null;
    }

    const valueAsString = String(value);

    return valueAsString.startsWith(phrase) ? null : { startsWith: { phrase } };
  };
}
