import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Attribute, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HTML_INPUT_TOOLBAR_CONFIGS } from '@app/form/field/html-input/html-input.const';
import {
  MfHtmlInputFeatures,
  MfHtmlInputPlaceholder,
  MfHtmlInputPlaceholderConstant,
  MfHtmlInputPlaceholderGroup,
  MfHtmlInputToolbarFeatureConfigTypes,
  MfHtmlInputToolbarFeaturesConfig,
} from '@app/form/field/html-input/html-input.types';
import { MfFormHtmlInputPlaceholderSelectComponent } from '@app/form/field/html-input/placeholder-select/placeholder-select.component';
import { MfFormAbstractFieldComponent } from '@app/form/field/shared/abstract-field.component';
import { MfFormErrorHandlerComponent } from '@app/form/field/shared/error-handler/error-handler.component';
import { MfFormService } from '@app/form/field/shared/form.service';
import { MfFormHintHandlerComponent } from '@app/form/field/shared/hint-handler/hint-handler.component';
import { MaterialModule } from '@app/material/material.module';
import { MfDialogService } from '@app/shared/dialog/service/dialog.service';
import { fadeToggle } from '@app/shared/util/animations/fade-toggle';
import { StringUtil } from '@app/shared/util/string.util';
import { DEFAULT_FONT_SIZE_OPTIONS } from '@shared/config/font-size.config';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import * as QuillNamespace from 'quill';
import { Quill as QuillEditor } from 'quill';
import getPlaceholderModule from 'quill-placeholder-module';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const Quill: any = QuillNamespace;

Quill.register(
  'modules/placeholder',
  getPlaceholderModule(Quill, {
    className: 'ql-placeholder-content',
  })
);

const whitelistedFontSizes: number[] = [...DEFAULT_FONT_SIZE_OPTIONS, 6];

/* TODO: This currently updates the whitelist for all currently visible editors. Ideally we don't want this to happen, as in the
    current implementation non-visible font sizes are valid if they are just copy-pasted in from another editor (for example 6px,
    7px and 11px). It would be more ideal if we could directly change the whilelist a single editor, but as for as i know there
    is no way to do that.
*/
const Size: any = Quill.import('attributors/style/size');
Size.whitelist = whitelistedFontSizes.map((size: number) => `${size}px`);
Quill.register(Size, true);

const Link: any = Quill.import('formats/link');

class CustomLink extends Link {
  static sanitize(url: string): string {
    let value: string = super.sanitize(url);

    if (value && !value.startsWith('https://')) {
      if (value.startsWith('http://')) {
        value = value.replace('http://', 'https://');
      } else {
        return `https://${value}`;
      }
    }

    return value;
  }
}

Quill.register(CustomLink, true);

@Component({
  selector: 'mf-form-html-input',
  templateUrl: './html-input.component.html',
  styleUrls: ['./html-input.component.scss'],
  animations: [fadeToggle],
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    QuillEditorComponent,
    MfFormHintHandlerComponent,
    MfFormErrorHandlerComponent,
    FormsModule,
  ],
})
export class MfFormHtmlInputComponent
  extends MfFormAbstractFieldComponent
  implements OnInit, OnDestroy
{
  @Input() label?: string;
  @Input() hintLabel?: string;
  @Input() patternName?: string;
  @Input() placeholder: string;
  @Input() warningLabel?: string;
  @Input() toolbarFeaturesConfig!: MfHtmlInputToolbarFeaturesConfig;
  @Input() placeholderItems?: MfHtmlInputPlaceholderGroup[];
  @Input() toolbarFeaturesType: MfHtmlInputToolbarFeatureConfigTypes = 'default';

  editorRef?: QuillEditor;

  @Input()
  get lengthWarning() {
    return this._lengthWarning;
  }

  set lengthWarning(value: NumberInput) {
    this._lengthWarning = coerceNumberProperty(value, undefined);
  }

  private _lengthWarning?: number | undefined = undefined;

  @Input()
  get maxLengthHint(): number | undefined {
    return this._maxLengthHint;
  }

  set maxLengthHint(value: NumberInput) {
    this._maxLengthHint = coerceNumberProperty(value, undefined);
  }

  private _maxLengthHint?: number = undefined;

  @Input()
  get showAsterisk(): boolean {
    return this._showAsterisk;
  }

  set showAsterisk(value: BooleanInput) {
    this._showAsterisk = coerceBooleanProperty(value);
  }

  private _showAsterisk: boolean = false;

  get hideAsterisk(): boolean {
    return !this._showAsterisk;
  }

  @Input()
  get showOptionalHint(): boolean {
    return this._showOptionalHint;
  }

  set showOptionalHint(value: BooleanInput) {
    this._showOptionalHint = coerceBooleanProperty(value);
  }

  private _showOptionalHint: boolean = false;

  @Input()
  get hideErrorMessage(): boolean {
    return this._hideErrorMessage;
  }

  set hideErrorMessage(value: BooleanInput) {
    this._hideErrorMessage = coerceBooleanProperty(value);
  }

  private _hideErrorMessage: boolean = false;

  @Input()
  get hideInfoIcon(): boolean {
    return this._hideInfoIcon;
  }

  set hideInfoIcon(value: BooleanInput) {
    this._hideInfoIcon = coerceBooleanProperty(value);
  }

  private _hideInfoIcon: boolean = false;
  public isRequired: boolean = false;

  public quillModules!: QuillModules;
  public quillFormats: string[] = [];
  public quillContent: string = '';
  public toolbarConfig: MfHtmlInputToolbarFeaturesConfig = [];
  public fontSizes: (boolean | string)[] = [];

  private valueTextLength: number = 0;
  private unsubscribe$ = new Subject<void>();

  private readonly placeholderDelimiters: [string, string] = ['{{', '}}'];
  readonly MfHtmlInputFeatures = MfHtmlInputFeatures;

  constructor(
    /* fontSizes attribute only supports values defined in the `whitelistedFontSizes`-Array. On-the-fly whitelisting of sizes
     * is not possible to my knowledge, as the whilelist applies to all currently visible editors and therefore will be most
     * likely overriden by the default config. */
    @Attribute('fontSizes') private readonly externalFontSizesOptions: string,
    @Attribute('translationPrefix') public readonly translationPrefix: string,
    @Attribute('translationPrefixScope') public readonly translationPrefixScope: string,
    private formService: MfFormService,
    private dialog: MfDialogService
  ) {
    super();

    this.translationPrefix = translationPrefix || 'SHARED.FORMS.ERROR.';
    this.placeholder = '';

    this.parseAndSetFontSizes(this.externalFontSizesOptions);
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.isRequired = this.formService.initRequiredStatus(this.hideAsterisk, this.control);

    this.setToolbarConfig();
    this.setQuillModules();
    this.initValueUpdateListener();

    this.initQuillContent();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  get inputLength(): number {
    return this.valueTextLength || 0;
  }

  get hasWarning(): boolean {
    return !!(
      this.maxLengthHint &&
      this.inputLength &&
      this._lengthWarning &&
      this.inputLength > this._lengthWarning
    );
  }

  get hasError(): boolean {
    return this.control.touched && this.control.invalid;
  }

  openPlaceholderSelect() {
    if (!this.placeholderItems) {
      return;
    }

    let selection = this.editorRef?.getSelection();

    this.dialog
      .open(MfFormHtmlInputPlaceholderSelectComponent, this.placeholderItems)
      .afterClosed()
      .subscribe((result) => {
        if (!result) {
          return;
        }

        for (const placeholder of result) {
          switch (placeholder) {
            case MfHtmlInputPlaceholderConstant.LINE_BREAK:
              this.insertString('\n', selection);
              break;

            case MfHtmlInputPlaceholderConstant.SPACE:
              this.insertString(' ', selection);
              break;

            default:
              this.insertPlaceholder(placeholder, selection);
              break;
          }

          // Update selection variable after an insert happened
          selection = this.editorRef?.getSelection();
        }
      });
  }

  insertPlaceholder(
    placeholder: MfHtmlInputPlaceholder,
    selection = this.editorRef?.getSelection()
  ) {
    if (!this.editorRef) {
      return;
    }

    if (selection) {
      this.editorRef.deleteText(selection.index, selection.length);
    }

    const insertAt = selection?.index || 0;
    this.editorRef.insertEmbed(insertAt, 'placeholder', placeholder, Quill.sources.USER);
    this.editorRef.setSelection(insertAt + 1, 0);
  }

  insertString(text: string, selection = this.editorRef?.getSelection()) {
    if (!this.editorRef) {
      return;
    }

    if (selection) {
      this.editorRef.deleteText(selection.index, selection.length);
    }

    const insertAt = selection?.index || 0;
    this.editorRef.insertText(insertAt, text, Quill.sources.USER);
    this.editorRef.setSelection(insertAt + text.length, 0);
  }

  public hasToolbarFeatures(features: MfHtmlInputFeatures[]): boolean {
    return this.toolbarConfig.some((item) => features.includes(item));
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public parseAndSetFontSizes(externalOptions?: string): number[] {
    let fontSizes: number[] = DEFAULT_FONT_SIZE_OPTIONS;
    if (externalOptions) {
      fontSizes = externalOptions
        .split(',')
        .map((value: string) => parseInt(value, 10))
        .filter((value: number) => !Number.isNaN(value));

      fontSizes.sort((a: number, b: number) => a - b);
    }

    const sizes: string[] = fontSizes.map((size: number) => `${size}px`);

    this.fontSizes = [false, ...sizes];

    return fontSizes;
  }

  private setQuillModules(): void {
    this.quillModules = {
      placeholder: {
        delimiters: this.placeholderDelimiters,
        placeholders: this.placeholderItems,
      },
      keyboard: {
        bindings: {
          tab: {
            key: 9,
            handler: () => true,
          },
        },
      },
    };
  }

  private setToolbarConfig(): void {
    if (this.toolbarFeaturesType === 'custom') {
      if (!this.toolbarFeaturesConfig || !Array.isArray(this.toolbarFeaturesConfig)) {
        throw new Error(
          'MfFormHtmlInputComponent: toolbarFeaturesType "custom" is used but no toolbarFeaturesConfig is provided!'
        );
      }

      this.toolbarConfig = this.toolbarFeaturesConfig;
    } else {
      const config = HTML_INPUT_TOOLBAR_CONFIGS[this.toolbarFeaturesType];

      if (!config) {
        throw new Error(
          `MfFormHtmlInputComponent: toolbarFeaturesType +
          "${this.toolbarFeaturesType}" not recognized! Use value "custom" to pass a custom toolbar configuration.`
        );
      }

      this.toolbarConfig = config;
    }

    this.setQuillFormats();
  }

  private setQuillFormats(): void {
    const formats: string[] = [];

    if (this.toolbarConfig.includes(MfHtmlInputFeatures.BOLD)) {
      formats.push('bold');
    }

    if (this.toolbarConfig.includes(MfHtmlInputFeatures.ITALIC)) {
      formats.push('italic');
    }

    if (this.toolbarConfig.includes(MfHtmlInputFeatures.UNDERLINE)) {
      formats.push('underline');
    }

    if (this.toolbarConfig.includes(MfHtmlInputFeatures.COLOR)) {
      formats.push('color');
    }

    if (this.toolbarConfig.includes(MfHtmlInputFeatures.SIZE)) {
      formats.push('size');
    }

    if (
      this.toolbarConfig.includes(MfHtmlInputFeatures.LIST_BULLET) ||
      this.toolbarConfig.includes(MfHtmlInputFeatures.LIST_ORDERED)
    ) {
      formats.push('list');
    }

    if (
      this.toolbarConfig.includes(MfHtmlInputFeatures.TEXT_LEFT) ||
      this.toolbarConfig.includes(MfHtmlInputFeatures.TEXT_CENTER) ||
      this.toolbarConfig.includes(MfHtmlInputFeatures.TEXT_RIGHT) ||
      this.toolbarConfig.includes(MfHtmlInputFeatures.TEXT_JUSTIFY)
    ) {
      formats.push('align');
    }

    if (this.toolbarConfig.includes(MfHtmlInputFeatures.LINKS)) {
      formats.push('link');
    }

    if (this.placeholderItems) {
      formats.push('placeholder');
    }

    this.quillFormats = formats;
  }

  private initValueUpdateListener(): void {
    this.control.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (value: string) => {
        this.valueTextLength = value ? StringUtil.lengthOfTextInHtml(value) : 0;
        this.initQuillContent();
      },
    });
  }

  public initQuillContent(): void {
    let content = this.control.value;
    content = this.replaceQuillPlaceholders(content, 'label');

    this.quillContent = content;
  }

  public onChangeQuillContent(quillContent: string): void {
    this.quillContent = quillContent;

    let content = this.quillContent;
    content = this.quillContent ? this.replaceQuillPlaceholders(content, 'id') : '';

    this.control.setValue(content);
    this.control.markAsDirty();
  }

  public handleBlur(): void {
    this.control.markAsTouched();
  }

  public replaceQuillPlaceholders(content: string, displayedFieldType: 'id' | 'label'): string {
    const domParser: DOMParser = new DOMParser();
    const { body }: Document = domParser.parseFromString(content, 'text/html');

    // By default, quill placeholders module places the value of a placeholder within the delimiters. But we want the id to be there.
    // Therefore, we replace the id with the value when preparing quill values und replace the value with the id when preparing the
    // value of the control.
    body.querySelectorAll('span.ql-placeholder-content').forEach((node: Element) => {
      const value: string = node.getAttribute('data-' + displayedFieldType) || '';
      const targetElement = node.querySelector('span[contenteditable]');
      if (targetElement) {
        targetElement.textContent =
          this.placeholderDelimiters[0] + value + this.placeholderDelimiters[1];
      }
    });

    return body.innerHTML;
  }
}
