<ng-container *transloco="let t">
  <ng-container *ngIf="!hasWarning; else warningTemplate">
    <div
      class="flex items-center justify-between space-x-1"
      [ngClass]="{
        'has-success': hintLabel && showValidationSuccess && field?.valid && field?.touched,
      }"
    >
      <div
        *ngIf="hintLabel || (!isRequired && showOptionalHint)"
        class="flex items-center space-x-1"
      >
        <mat-icon
          *ngIf="field?.valid && field?.touched && !showOptionalHint && showValidationSuccess"
          svgIcon="success"
        />
        <mat-icon
          *ngIf="(!field?.touched || !showValidationSuccess) && !showOptionalHint && !hideInfoIcon"
          svgIcon="info"
        />
        <span>
          <ng-container *ngIf="!isRequired && showOptionalHint">
            {{ t('SHARED.FORMS.OPTIONAL') }}{{ hintLabel ? ':' : '' }}
          </ng-container>
          <span [innerHTML]="hintLabel"></span>
        </span>
      </div>
      <mf-form-util-length-handler
        *ngIf="maxLengthHint"
        class="max-length-hint"
        [field]="field"
        [length]="length"
        [maxLength]="maxLengthHint"
      />
    </div>
  </ng-container>

  <ng-template #warningTemplate>
    <div class="warning flex items-center space-x-1">
      <mat-icon svgIcon="warning" />
      <span>
        {{ warningLabel }}
      </span>
      <mf-form-util-length-handler
        *ngIf="warningLabel"
        class="max-length-hint"
        [field]="field"
        [length]="length"
        [maxLength]="maxLengthHint"
      />
    </div>
  </ng-template>
</ng-container>
