import {
  MfHtmlInputFeatures,
  MfHtmlInputToolbarFeatureConfigTypes,
  MfHtmlInputToolbarFeaturesConfig,
} from '@app/form/field/html-input/html-input.types';

export const HTML_INPUT_TOOLBAR_CONFIGS: {
  [k in MfHtmlInputToolbarFeatureConfigTypes]?: MfHtmlInputToolbarFeaturesConfig;
} = {
  none: [],
  basic: [
    MfHtmlInputFeatures.BOLD,
    MfHtmlInputFeatures.ITALIC,
    MfHtmlInputFeatures.UNDERLINE,
    MfHtmlInputFeatures.LIST_ORDERED,
    MfHtmlInputFeatures.LIST_BULLET,
    MfHtmlInputFeatures.CLEAN,
  ],
  default: [
    MfHtmlInputFeatures.BOLD,
    MfHtmlInputFeatures.ITALIC,
    MfHtmlInputFeatures.UNDERLINE,
    MfHtmlInputFeatures.COLOR,
    MfHtmlInputFeatures.SIZE,
    MfHtmlInputFeatures.LIST_ORDERED,
    MfHtmlInputFeatures.LIST_BULLET,
    MfHtmlInputFeatures.CLEAN,
  ],
  defaultWithAlignment: [
    MfHtmlInputFeatures.BOLD,
    MfHtmlInputFeatures.ITALIC,
    MfHtmlInputFeatures.UNDERLINE,
    MfHtmlInputFeatures.COLOR,
    MfHtmlInputFeatures.SIZE,
    MfHtmlInputFeatures.LIST_ORDERED,
    MfHtmlInputFeatures.LIST_BULLET,
    MfHtmlInputFeatures.CLEAN,
    MfHtmlInputFeatures.TEXT_CENTER,
    MfHtmlInputFeatures.TEXT_LEFT,
    MfHtmlInputFeatures.TEXT_RIGHT,
    MfHtmlInputFeatures.TEXT_JUSTIFY,
  ],
  defaultWithLink: [
    MfHtmlInputFeatures.BOLD,
    MfHtmlInputFeatures.ITALIC,
    MfHtmlInputFeatures.UNDERLINE,
    MfHtmlInputFeatures.COLOR,
    MfHtmlInputFeatures.SIZE,
    MfHtmlInputFeatures.LIST_ORDERED,
    MfHtmlInputFeatures.LIST_BULLET,
    MfHtmlInputFeatures.LINKS,
    MfHtmlInputFeatures.CLEAN,
  ],
  letterHeadline: [
    MfHtmlInputFeatures.BOLD,
    MfHtmlInputFeatures.ITALIC,
    MfHtmlInputFeatures.UNDERLINE,
    MfHtmlInputFeatures.SIZE,
    MfHtmlInputFeatures.CLEAN,
    MfHtmlInputFeatures.TEXT_CENTER,
    MfHtmlInputFeatures.TEXT_LEFT,
    MfHtmlInputFeatures.TEXT_RIGHT,
  ],
  letterContent: [
    MfHtmlInputFeatures.BOLD,
    MfHtmlInputFeatures.ITALIC,
    MfHtmlInputFeatures.UNDERLINE,
    MfHtmlInputFeatures.COLOR,
    MfHtmlInputFeatures.SIZE,
    MfHtmlInputFeatures.LIST_ORDERED,
    MfHtmlInputFeatures.LIST_BULLET,
    MfHtmlInputFeatures.CLEAN,
    MfHtmlInputFeatures.TEXT_CENTER,
    MfHtmlInputFeatures.TEXT_LEFT,
    MfHtmlInputFeatures.TEXT_RIGHT,
    MfHtmlInputFeatures.TEXT_JUSTIFY,
  ],
  consultantSignature: [
    MfHtmlInputFeatures.BOLD,
    MfHtmlInputFeatures.ITALIC,
    MfHtmlInputFeatures.UNDERLINE,
    MfHtmlInputFeatures.COLOR,
    MfHtmlInputFeatures.SIZE,
    MfHtmlInputFeatures.CLEAN,
  ],
};
