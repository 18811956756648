import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  booleanAttribute,
} from '@angular/core';
import { MfIcon } from '@app/material/icons/icons.const';
import { MaterialModule } from '@app/material/material.module';

@Component({
  selector: 'mf-hint-box',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, MaterialModule],
  templateUrl: './hint-box.component.html',
  styleUrls: ['./hint-box.component.scss'],
})
export class MfHintBoxComponent {
  @Input() type: 'info' | 'warning' | 'error' | 'success' = 'info';
  @Input() text?: string;
  @Input({ transform: booleanAttribute }) fitContent: boolean = false;
  @Input({ transform: booleanAttribute }) small: boolean = false;
  @Input({ transform: booleanAttribute }) noBorder: boolean = false;

  @HostBinding('class') get classes(): string {
    const classes: string[] = [this.type];

    if (this.fitContent) {
      classes.push('fit-content');
    }

    if (this.small) {
      classes.push('small');
    }

    if (this.noBorder) {
      classes.push('no-border');
    }

    return classes.join(' ');
  }

  get icon(): MfIcon {
    switch (this.type) {
      case 'info':
        return 'info';
      case 'warning':
        return 'warning';
      case 'error':
        return 'error';
      case 'success':
        return 'check';
    }
  }
}
