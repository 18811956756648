import { Component } from '@angular/core';

@Component({
  selector: 'mf-card',
  standalone: true,
  template: '<ng-content />',
  styles: [
    `
      :host {
        @apply block rounded border border-monochrome-200 p-4;
      }
    `,
  ],
})
export class MfCardComponent {}
