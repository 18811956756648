export interface MfHtmlInputPlaceholderGroup {
  label: string;
  items: MfHtmlInputPlaceholderGroupItem[];
}

export interface MfHtmlInputPlaceholderGroupItem {
  label: string;
  placeholders: (
    | MfHtmlInputPlaceholder
    | (typeof MfHtmlInputPlaceholderConstant)[keyof typeof MfHtmlInputPlaceholderConstant]
  )[];
}

export interface MfHtmlInputPlaceholder {
  id: string;
  label: string;
}

export const MfHtmlInputPlaceholderConstant = {
  SPACE: 'space',
  LINE_BREAK: 'line-break',
} as const;

export type MfHtmlInputToolbarFeaturesConfig = MfHtmlInputFeatures[];

export type MfHtmlInputToolbarFeatureConfigTypes =
  | 'none'
  | 'basic'
  | 'default'
  | 'defaultWithLink'
  | 'defaultWithAlignment'
  | 'letterHeadline'
  | 'letterContent'
  | 'consultantSignature'
  | 'custom';

export enum MfHtmlInputFeatures {
  BOLD = 'bold',
  ITALIC = 'italic',
  UNDERLINE = 'underline',
  COLOR = 'color',
  SIZE = 'size',
  LIST_ORDERED = 'listOrdered',
  LIST_BULLET = 'listBullet',
  LINKS = 'link',
  TEXT_LEFT = 'text-left',
  TEXT_CENTER = 'text-center',
  TEXT_JUSTIFY = 'text-justify',
  TEXT_RIGHT = 'text-right',
  CLEAN = 'clean',
}
