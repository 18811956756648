<mat-form-field
  *ngIf="control"
  [class.no-bottom-offset]="noBottomOffset"
  [class.no-label]="noLabel"
  [class.simple-suffix]="suffix"
  [hideRequiredMarker]="hideAsterisk"
  [ngClass]="[name]"
>
  <mat-label>{{ label }}</mat-label>

  <input
    [autocomplete]="autocomplete"
    [formControl]="$any(control)"
    [max]="max"
    [min]="min"
    [name]="name"
    [ngClass]="{ 'hide-number-spinner': hideNumberSpinner }"
    [placeholder]="placeholder || ''"
    [required]="isRequired"
    [type]="type"
    #passwordInput
    matInput
  />
  <mat-spinner *ngIf="control.pending" [diameter]="24" matSuffix />

  <span *ngIf="suffix" class="pb-2" matSuffix>{{ suffix }}</span>

  <button
    *ngIf="passwordHasToggleButton && !control.pending"
    (click)="toggleShowPassword()"
    mat-icon-button
    matSuffix
    type="button"
  >
    <mat-icon [svgIcon]="type === 'password' ? 'eye_closed' : 'eye_open'" id="input-eye-icon" />
  </button>

  <mat-hint>
    <mf-form-util-hint-handler
      *ngIf="!multipleHintsTemplate; else multipleHints"
      [field]="control"
      [hideInfoIcon]="hideInfoIcon"
      [hintLabel]="hintLabel"
      [isRequired]="isRequired"
      [maxLengthHint]="maxLengthHint!"
      [patternName]="patternName"
      [showOptionalHint]="showOptionalHint"
      [showValidationSuccess]="showValidationSuccess"
    />

    <ng-template #multipleHints>
      <mf-form-util-multi-hint-handler [field]="control" [type]="multipleHintsTemplate!" />
    </ng-template>
  </mat-hint>

  <mat-error *ngIf="!hideErrorMessage">
    <mf-form-util-error-handler
      [field]="control"
      [maxLengthHint]="maxLengthHint!"
      [patternName]="patternName"
      [translationPrefix]="translationPrefix"
      [translationPrefixScope]="translationPrefixScope"
    />
  </mat-error>
</mat-form-field>
