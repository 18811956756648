import { Component, OnDestroy } from '@angular/core';
import { MonoTypeOperatorFunction, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({ template: '' })
export abstract class MfUnsubscribeComponent implements OnDestroy {
  protected readonly unsubscribe$: Subject<void> = new Subject<void>();

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public takeUntilUnsubscribe<T>(): MonoTypeOperatorFunction<T> {
    return takeUntil<T>(this.unsubscribe$);
  }
}
