import { Injectable } from '@angular/core';
import {
  MfUserEvent,
  MfUserEventMap,
  MfUserEventType,
} from '@app/shared/authentication/user/user-event.model';
import { MfUserModel } from '@app/shared/authentication/user/user.model';
import { Freshdesk } from '@app/shared/freshdesk/freshdesk.service';
import { SentryConfig } from '@app/shared/sentry/sentry-config';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MfUserEventService {
  private notify = new Subject<MfUserEvent>();

  public triggerLogin(user: MfUserModel): void {
    Freshdesk.showWidget();

    this.notify.next({
      type: MfUserEventType.LOGIN,
      data: user,
    });
  }

  public triggerUpdate(user: MfUserModel): void {
    this.notify.next({
      type: MfUserEventType.UPDATED,
      data: user,
    });
  }

  public triggerLogout(): void {
    SentryConfig.clearUserRelatedData();
    Freshdesk.hideWidget();

    this.notify.next({
      type: MfUserEventType.LOGOUT,
    });
  }

  public onEvent(): Observable<MfUserEvent> {
    return this.notify.asObservable();
  }

  public onLogin(): Observable<MfUserEventMap[MfUserEventType.LOGIN]> {
    return this.onEvent().pipe(
      filter((userEvent) => userEvent.type === MfUserEventType.LOGIN)
    ) as Observable<MfUserEventMap[MfUserEventType.LOGIN]>;
  }

  public onUpdate(): Observable<MfUserEventMap[MfUserEventType.UPDATED]> {
    return this.onEvent().pipe(
      filter((userEvent) => userEvent.type === MfUserEventType.UPDATED)
    ) as Observable<MfUserEventMap[MfUserEventType.UPDATED]>;
  }

  public onLogout(): Observable<MfUserEventMap[MfUserEventType.LOGOUT]> {
    return this.onEvent().pipe(
      filter((userEvent) => userEvent.type === MfUserEventType.LOGOUT)
    ) as Observable<MfUserEventMap[MfUserEventType.LOGOUT]>;
  }
}
