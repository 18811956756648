import { HashMap } from '@jsverse/transloco';

export enum NotificationEnum {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export class Notification {
  constructor(
    public message: string,
    public type: NotificationEnum,
    public title?: string,
    public duration?: number,
    public params?: HashMap
  ) {
    this.params = this.params ? this.params : {};
    this.duration = this.duration ? this.duration : 6000;
  }

  static success(
    message: string,
    title?: string,
    duration?: number,
    params?: HashMap
  ): Notification {
    return new this(message, NotificationEnum.SUCCESS, title, duration, params);
  }

  static error(message: string, title?: string, duration?: number, params?: HashMap): Notification {
    return new this(message, NotificationEnum.ERROR, title, duration, params);
  }

  static info(message: string, title?: string, duration?: number, params?: HashMap): Notification {
    return new this(message, NotificationEnum.INFO, title, duration, params);
  }
}
