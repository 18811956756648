<ng-container *transloco="let t">
  <ng-container *ngFor="let item of items; index as i; first as isFirst; last as isLast">
    <mat-icon *ngIf="!isFirst" svgIcon="chevron_right" />

    <a *ngIf="item.link && !isLast; else nonClickableItem" class="crumb" [routerLink]="item.link">
      {{ t(item.title) }}
    </a>

    <ng-template #nonClickableItem>
      <span class="crumb">
        {{ t(item.title) }}
      </span>
    </ng-template>
  </ng-container>
</ng-container>
