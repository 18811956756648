import { CommonModule } from '@angular/common';
import { Attribute, Component, Input, OnInit, booleanAttribute } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MfFormAbstractFieldComponent } from '@app/form/field/shared/abstract-field.component';
import { MfFormErrorHandlerComponent } from '@app/form/field/shared/error-handler/error-handler.component';
import { MfFormService } from '@app/form/field/shared/form.service';
import { MfFormHintHandlerComponent } from '@app/form/field/shared/hint-handler/hint-handler.component';
import { MfFormRadioAndCheckboxModel } from '@app/form/shared/radio/radio-and-checkbox.model';
import { MaterialModule } from '@app/material/material.module';
import { MfInfoHoverComponent } from '@app/shared/components/info-hover/info-hover.component';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'mf-form-checkbox',
  templateUrl: './checkbox.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    MfInfoHoverComponent,
    ReactiveFormsModule,
    MfFormHintHandlerComponent,
    MfFormErrorHandlerComponent,
    TranslocoModule,
  ],
})
export class MfFormCheckboxComponent extends MfFormAbstractFieldComponent implements OnInit {
  @Input() items: MfFormRadioAndCheckboxModel[];
  @Input() label?: string;
  @Input() labelHint?: string;
  @Input() labelHintTitle?: string;
  @Input() hintLabel?: string;
  @Input() patternName?: string;
  @Input() checkAllLabel?: string;
  @Input({ transform: booleanAttribute }) showCheckAll: boolean = false;
  @Input({ transform: booleanAttribute }) multilineLabel: boolean = false;
  @Input({ transform: booleanAttribute }) showAsterisk: boolean = false;
  @Input({ transform: booleanAttribute }) showOptionalHint: boolean = false;
  @Input({ transform: booleanAttribute }) showValidationSuccess: boolean = false;
  @Input({ transform: booleanAttribute }) hideErrorMessage: boolean = false;
  @Input({ transform: booleanAttribute }) hideHintErrorArea: boolean = false;
  @Input({ transform: booleanAttribute }) hideInfoIcon: boolean = false;

  public isRequired: boolean = false;

  constructor(
    @Attribute('type') public readonly type: 'single' | 'multiple',
    @Attribute('translationPrefix') public readonly translationPrefix: string,
    @Attribute('translationPrefixScope') public readonly translationPrefixScope: string,
    private formService: MfFormService
  ) {
    super();
    this.type = type || 'multiple';
    this.translationPrefix = translationPrefix || 'SHARED.FORMS.ERROR.CHECKBOX.';
    this.items = [];
    this.hideErrorMessage = false;
  }

  get hideAsterisk() {
    return !this.showAsterisk;
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.isRequired = this.formService.initRequiredStatus(this.hideAsterisk, this.control);
  }

  get selectedAll(): boolean {
    return this.items.length === this.control.value?.length;
  }

  toggleAll(): void {
    if (this.selectedAll) {
      this.control.setValue([]);
    } else {
      this.control.setValue(this.items.map((item) => item.value));
    }
  }
}
