import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MfDataManagerExportDataItemContentInterface,
  MfDataManagerExportDataItemInterface,
} from '@app/data-manager/services/export-data/data.interface';
import { DateUtil } from '@shared/util/date/date.util';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MfDataManagerExportDataService {
  protected exportItems: BehaviorSubject<MfDataManagerExportDataItemInterface[]> =
    new BehaviorSubject<MfDataManagerExportDataItemInterface[]>([]);
  public exportItems$: Observable<MfDataManagerExportDataItemInterface[]> =
    this.exportItems.asObservable();

  constructor(private http: HttpClient) {}

  // istanbul ignore next
  public updateExportItems(): void {
    this.getExportList().pipe(take(1)).subscribe();
  }

  // istanbul ignore next
  public getContent(): Observable<MfDataManagerExportDataItemContentInterface> {
    return this.http.get<MfDataManagerExportDataItemContentInterface>('v1/data/exports/content');
  }

  // istanbul ignore next
  public getExportList(): Observable<MfDataManagerExportDataItemInterface[]> {
    return this.http
      .get<MfDataManagerExportDataItemInterface<string>[]>('v1/data/exports')
      .pipe(map((items) => items.map((item) => this.parseExport(item))))
      .pipe(
        tap({
          next: (items) => {
            this.exportItems.next(items);

            return items;
          },
          error: (error: any) => {
            this.exportItems.next([]);

            return error;
          },
        })
      );
  }

  public parseExport(
    exportItem: MfDataManagerExportDataItemInterface<string>
  ): MfDataManagerExportDataItemInterface {
    return {
      ...exportItem,
      createdAt: DateUtil.safeParseDate(exportItem.createdAt),
      updatedAt: DateUtil.safeParseDate(exportItem.updatedAt),
    };
  }

  public exportFile(
    pseudonymizationPassword: string
  ): Observable<MfDataManagerExportDataItemInterface<string>> {
    return this.http.post<MfDataManagerExportDataItemInterface<string>>('v1/data/exports', {
      password: pseudonymizationPassword,
    });
  }

  public downloadExportFile(): Observable<HttpResponse<Blob>> {
    return this.http.get('v1/data/exports/download', {
      observe: 'response',
      responseType: 'blob',
    });
  }
}
