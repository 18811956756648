import { provideHttpClient, withInterceptors } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS,
  MatLegacyDialogModule,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBarModule } from '@angular/material/legacy-snack-bar';
import { provideAnimations } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, provideRouter, withComponentInputBinding } from '@angular/router';
import { MaterialModule } from '@app/material/material.module';
import { provideTransloco } from '@jsverse/transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';
import { MfAuthenticationService } from '@shared/authentication/authentication.service';
import { MfUserService } from '@shared/authentication/user/user.service';
import { MfNotificationService } from '@shared/notification/notification.service';
import { MfCustomRouteReuseStrategy } from '@shared/routing/reuse-strategy';
import { SentryConfig } from '@shared/sentry/sentry-config';
import { TranslocoLoaderService } from '@shared/transloco/transloco-loader.service';
import { MfGlobalErrorHandlerService } from '@shared/util/error/global-error-handler.service';
import { MfHttpInterceptor } from '@shared/util/http/http-interceptor';
import routes from './app.routes';

const initializeAppFactory =
  (userService: MfUserService, authService: MfAuthenticationService) => () => {
    if (!userService.isLoggedIn()) {
      authService.clearSessionData();
    }
  };

const materialProviders = [
  importProvidersFrom(MatLegacySnackBarModule),
  importProvidersFrom(MatLegacyDialogModule),
  importProvidersFrom(MaterialModule),
  {
    provide: MAT_DIALOG_DEFAULT_OPTIONS,
    useValue: {
      autoFocus: true,
      hasBackdrop: true,
    },
  },
];

export const appConfig: ApplicationConfig = {
  providers: [
    MfNotificationService,
    provideHttpClient(withInterceptors([MfHttpInterceptor.interceptorFn])),
    provideAnimations(),
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'EUR',
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [MfUserService, MfAuthenticationService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: MfCustomRouteReuseStrategy },
    {
      provide: ErrorHandler,
      useClass: MfGlobalErrorHandlerService,
    },
    provideTransloco({
      config: {
        reRenderOnLangChange: false,
        availableLangs: ['de'],
        defaultLang: 'de',
        fallbackLang: 'de',
        prodMode: !isDevMode(),
        flatten: {
          aot: !isDevMode(),
        },
      },
      loader: TranslocoLoaderService,
    }),
    provideTranslocoMessageformat(),
    ...SentryConfig.getProviders(),
    provideRouter(routes, withComponentInputBinding()),
    materialProviders,
  ],
};
