<div class="mf-sidenav" [class.open]="sidenavIsOpen">
  <mf-layout-sidenav />
</div>

<div
  class="mf-content relative flex flex-col overscroll-y-none"
  [class.sidenav-open]="sidenavIsOpen"
  #content
  cdk-scrollable
>
  <div
    *ngIf="headerGradient"
    class="absolute top-0 h-44 w-full opacity-75"
    [ngClass]="headerGradient"
  ></div>

  <mf-layout-header
    class="flex-shrink-0 basis-auto"
    [isSticky]="isSticky"
    [noLine]="!!headerGradient"
    (toggleSidenav)="toggleSidenav()"
  />

  <main class="app-content flex-grow">
    <router-outlet />
  </main>

  <mf-data-manager-widget />
  <mf-layout-footer />

  <div class="sidenav-open-overlay" (click)="toggleSidenav()"></div>
</div>
