import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { MfFormLengthHandlerComponent } from '@app/form/field/shared/length-handler/length-handler.component';
import { MaterialModule } from '@app/material/material.module';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'mf-form-util-hint-handler',
  templateUrl: './hint-handler.component.html',
  styleUrls: ['./hint-handler.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule, MfFormLengthHandlerComponent, TranslocoModule],
})
export class MfFormHintHandlerComponent {
  @Input() field?: AbstractControl;
  @Input() patternName?: string;
  @Input() isRequired?: boolean;
  @Input() showOptionalHint?: boolean;
  @Input() maxLengthHint?: number;
  @Input() hintLabel?: string;
  @Input() showValidationSuccess: boolean = false;
  @Input() hasWarning: boolean = false;
  @Input() warningLabel?: string;
  @Input() hideInfoIcon: boolean = false;
  @Input() length?: number;
}
