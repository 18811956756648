import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatLegacyButton as MatButton } from '@angular/material/legacy-button';
import { RouterLink } from '@angular/router';
import { MaterialModule } from '@app/material/material.module';
import { MfUserService } from '@app/shared/authentication/user/user.service';
import { MfNavigationService } from '@app/shared/navigation/navigation.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { MfBreadcrumbsComponent } from '@shared/breadcrumbs/breadcrumbs.component';

@Component({
  selector: 'mf-layout-header-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss'],
  standalone: true,
  imports: [TranslocoDirective, NgIf, MaterialModule, MfBreadcrumbsComponent, RouterLink],
})
export class MfLayoutHeaderContentComponent {
  @Input() isSticky: boolean = false;
  @Output() toggleSidenav = new EventEmitter<any>();
  @ViewChild('menuButton') menuButton?: MatButton;

  constructor(
    public userService: MfUserService,
    private navigationService: MfNavigationService
  ) {}

  public clicked(): void {
    this.menuButton?._elementRef?.nativeElement?.blur();
    this.toggleSidenav.emit();
  }

  public redirectUrl(): string[] {
    const dashboard: string[] = [`/${this.userService.getUserDefaultRoute()}`];

    return this.userService.isLoggedIn() ? dashboard : ['/'];
  }

  get logoTitle(): string {
    return this.navigationService.logo.imageAlt;
  }

  get logo(): string {
    return this.navigationService.logo.imageUrl;
  }
}
