export const toQueryParamsString = <V = string | null | undefined>(
  parameters: Record<string, V>,
  startWithQuestionmark: boolean = true
): string => {
  const paramsString = Object.entries(parameters)
    .filter(([_key, value]) => value !== null && value !== undefined)
    .map(([key, value]) => {
      if (value instanceof Date) {
        return key + '=' + value.toISOString();
      }

      if (Array.isArray(value)) {
        return value.map((item) => toQueryParamsString({ [key + '[]']: item }, false)).join('&');
      }

      return key + '=' + encodeURIComponent(value + '');
    })
    .join('&');

  return paramsString ? (startWithQuestionmark ? '?' : '') + paramsString : '';
};
