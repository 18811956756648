import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { MfDataManagerCardComponent } from '@app/data-manager/components/card/card.component';
import { MfDataManagerExportDownloaderComponent } from '@app/data-manager/components/export/downloader/downloader.component';
import { MfDataManagerExportDownloaderService } from '@app/data-manager/components/export/downloader/downloader.service';
import { MfDataManagerExportDownloaderStatusEnum } from '@app/data-manager/components/export/downloader/status.enum';
import { MfDataManagerImportUploaderPasswordComponent } from '@app/data-manager/components/import/uploader/password/password.component';
import { MfDataManagerUploadTimeEstimatePipe } from '@app/data-manager/pipes/upload-time-estimate/upload-time-estimate.pipe';
import { MfDataManagerImportInterface } from '@app/data-manager/services/import-data/data.interface';
import { MfDataManagerImportUploaderService } from '@app/data-manager/services/import-uploader/import-uploader.service';
import {
  MfDataManagerImportStepEnum,
  MfDataManagerImportUploadStateInterface,
} from '@app/data-manager/services/import-uploader/import-uploader.types';
import { MfDataManagerImportUtil } from '@app/data-manager/util/data-import.util';
import { MaterialModule } from '@app/material/material.module';
import { TranslocoDirective } from '@jsverse/transloco';
import { MfUnsubscribeComponent } from '@shared/abstract/unsubscribe.component';
import { MfUserRolesEnum } from '@shared/authentication/types/user_roles.enum';
import { MfUserEventService } from '@shared/authentication/user/user-event.service';
import { MfUserService } from '@shared/authentication/user/user.service';
import { MfSecondaryButtonComponent } from '@shared/components/buttons/secondary/secondary.component';
import { MfFileSizePipe } from '@shared/pipes/file-size/file-size.pipe';
import { fadeToggle } from '@shared/util/animations/fade-toggle';

@Component({
  selector: 'mf-data-manager-widget',
  templateUrl: './widget.component.html',
  animations: [fadeToggle],
  standalone: true,
  imports: [
    TranslocoDirective,
    CommonModule,
    MfDataManagerExportDownloaderComponent,
    MfDataManagerCardComponent,
    MfFileSizePipe,
    MfDataManagerUploadTimeEstimatePipe,
    MaterialModule,
    RouterLink,
    MfDataManagerImportUploaderPasswordComponent,
    MfSecondaryButtonComponent,
  ],
})
export class MfDataManagerWidgetComponent extends MfUnsubscribeComponent implements OnInit {
  public passwordCollapsed: boolean = false;
  public showImportWidget: boolean = true;
  public isLoggedInAsDataManager: boolean = false;

  public uploadState: MfDataManagerImportUploadStateInterface | null = null;
  public uploadedImport: MfDataManagerImportInterface | null = null;

  constructor(
    private userService: MfUserService,
    private userEventService: MfUserEventService,
    private route: Router,
    public exportDownloaderService: MfDataManagerExportDownloaderService,
    public uploaderService: MfDataManagerImportUploaderService
  ) {
    super();
  }

  get isOnImportPage(): boolean {
    return this.route.url.includes('/datentransfer/datenimport');
  }

  get isOnExportPage(): boolean {
    return this.route.url.includes('/datentransfer/datenexport');
  }

  ngOnInit(): void {
    this.isUserAllowedToSeeWidget();
    this.initUserEventListeners();

    this.uploaderService.importStep.pipe(this.takeUntilUnsubscribe()).subscribe({
      next: (step) => {
        this.showImportWidget = ![
          MfDataManagerImportStepEnum.NO_FILE_SELECTED,
          MfDataManagerImportStepEnum.FILE_SELECTED,
        ].includes(step);
      },
    });

    this.uploaderService.uploadState.pipe(this.takeUntilUnsubscribe()).subscribe({
      next: (state) => {
        this.uploadState = state;
      },
    });

    this.uploaderService.uploadedImport.pipe(this.takeUntilUnsubscribe()).subscribe({
      next: (dataImport) => {
        this.uploadedImport = dataImport;
      },
    });
  }

  get showExportWidget(): boolean {
    return (
      ![
        MfDataManagerExportDownloaderStatusEnum.INITIALIZE,
        MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_ACTION,
        MfDataManagerExportDownloaderStatusEnum.WAIT_FOR_DOWNLOAD_ACTION,
        MfDataManagerExportDownloaderStatusEnum.DOWNLOAD_ERROR,
        MfDataManagerExportDownloaderStatusEnum.EMPTY,
      ].includes(this.exportDownloaderService.status) && !this.isOnExportPage
    );
  }

  private isUserAllowedToSeeWidget(): void {
    this.setIsLoggedInAsDataManager(this.userService.getUser()?.roles);
  }

  private initUserEventListeners(): void {
    this.userEventService
      .onLogin()
      .pipe(this.takeUntilUnsubscribe())
      .subscribe((event) => {
        if (Array.isArray(event?.data?.roles)) {
          this.setIsLoggedInAsDataManager(event?.data?.roles);
        }
      });

    this.userEventService
      .onLogout()
      .pipe(this.takeUntilUnsubscribe())
      .subscribe(() => {
        this.isLoggedInAsDataManager = false;
      });
  }

  private setIsLoggedInAsDataManager(roles?: MfUserRolesEnum[]): void {
    if (!roles || !Array.isArray(roles)) {
      this.isLoggedInAsDataManager = false;

      return;
    }

    this.isLoggedInAsDataManager =
      roles.includes(MfUserRolesEnum.DATA_MANAGER) ||
      roles.includes(MfUserRolesEnum.PARTNER_DATA_MANAGER);
  }

  protected readonly MfDataManagerImportStepEnum = MfDataManagerImportStepEnum;
  protected readonly MfDataManagerImportUtil = MfDataManagerImportUtil;
}
