import { DecimalPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

const BYTE_STEP: number = 1024;

/*
 * Convert bytes into the largest possible unit.
 * Takes a precision argument that defaults to 2.
 * Usage:
 *   bytes | fileSize:precision
 * Example:
 *   {{ 1024 | fileSize}}
 *   formats to: 1 KB
 */
@Pipe({
  name: 'fileSize',
  standalone: true,
})
export class MfFileSizePipe implements PipeTransform {
  private readonly decimalPipe: DecimalPipe;

  private readonly units: string[] = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

  constructor(@Inject(LOCALE_ID) localeId: any) {
    this.decimalPipe = new DecimalPipe(localeId);
  }

  transform(bytes: number | string = 0, precision: number = 2): string {
    let parsedBytes: number;
    let minPrecision: number = 1;
    let maxPrecision: number = precision;

    if (maxPrecision <= 0) {
      minPrecision = 0;
      maxPrecision = 0;
    }

    if (typeof bytes === 'number') {
      parsedBytes = bytes;
    } else {
      parsedBytes = parseFloat(bytes);
    }

    if (isNaN(parsedBytes) || parsedBytes < 1 || !isFinite(parsedBytes)) {
      return '?';
    }

    const unit: number = Math.floor(Math.log(parsedBytes) / Math.log(BYTE_STEP));
    const displayedBytes: number = parsedBytes / Math.pow(BYTE_STEP, unit);

    if (unit === 0) {
      maxPrecision = 1;
      minPrecision = 0;
    }

    return (
      this.decimalPipe.transform(displayedBytes, `1.${minPrecision}-${maxPrecision}`) +
      ' ' +
      this.units[unit]
    );
  }
}
