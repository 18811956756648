import { Maybe } from '@shared/types/util.types';

export class ResponseUtil {
  static getFilenameFromContentDisposition(contentDisposition: Maybe<string>): string {
    if (!contentDisposition) {
      return '';
    }

    return /filename="?(?<filename>[^"]*)"?/.exec(contentDisposition)?.groups?.['filename'] || '';
  }
}
