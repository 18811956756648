import { Inject, Injectable, Optional } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { ErrorHandlerOptions, SentryErrorHandler } from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root',
})
export class MfGlobalErrorHandlerService extends SentryErrorHandler {
  constructor(@Inject('errorHandlerOptions') @Optional() options?: ErrorHandlerOptions) {
    super(options || {});
  }

  override handleError(error: any): void {
    if (/Loading chunk \d+ failed/.test(error.message)) {
      // TODO: This should check how many times it was called before to prevent infinite loops
      this.reloadWindow();
    } else {
      // Only send exception to sentry if it's not a http-error
      if (error?.name !== 'HttpErrorResponse') {
        Sentry.captureException(error.originalError || error.error || error);
      }

      super.handleError(error);
    }
  }

  // Reload functionality seperated into its own method to be able to easily mock it for unit-test
  reloadWindow(): void {
    window.location.reload();
  }
}
