import { Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'mf-account-page',
  templateUrl: './page.component.html',
  standalone: true,
  imports: [RouterOutlet],
})
export class MfAccountPageComponent {}
