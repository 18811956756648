import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MfFormCheckboxComponent } from '@app/form/field/checkbox/checkbox.component';
import { TranslocoDirective } from '@jsverse/transloco';
import { MfTertiaryButtonComponent } from '@shared/components/buttons/tertiary/tertiary.component';
import { MfAbstractDialogInjected } from '@shared/dialog/service/dialog.interface';

@Component({
  selector: 'mf-alert-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatLegacyProgressSpinnerModule,
    MfFormCheckboxComponent,
    MfTertiaryButtonComponent,
    TranslocoDirective,
  ],
  templateUrl: './alert-dialog.component.html',
})
export class MfAlertDialogComponent extends MfAbstractDialogInjected<
  { title: string; message: string },
  undefined
> {}
