import { CommonModule } from '@angular/common';
import {
  Attribute,
  booleanAttribute,
  Component,
  EventEmitter,
  Input,
  numberAttribute,
  OnInit,
  Output,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MfFormAbstractFieldComponent } from '@app/form/field/shared/abstract-field.component';
import { MfFormErrorHandlerComponent } from '@app/form/field/shared/error-handler/error-handler.component';
import { MfFormService } from '@app/form/field/shared/form.service';
import { MfFormHintHandlerComponent } from '@app/form/field/shared/hint-handler/hint-handler.component';
import { NgSelectFormFieldControlDirective } from '@app/form/shared/ng-select-material/ng-select.directive';
import { MaterialModule } from '@app/material/material.module';
import { MfValueLabelSublabelModel } from '@app/shared/value-label/value-label-sublabel.model';
import {
  MfAbstractValueLabelItemsModel,
  MfValueLabelValueType,
} from '@app/shared/value-label/value-label.type';
import { TranslocoModule } from '@jsverse/transloco';
import { NgSelectModule } from '@ng-select/ng-select';
import { Maybe } from '@shared/types/util.types';
import { MfFormFieldSelectSearchTypeEnum } from './search-type.enum';

interface SearchEvent {
  term: string;
  items: MfAbstractValueLabelItemsModel[];
}

@Component({
  selector: 'mf-form-select',
  templateUrl: './select.component.html',
  styles: [
    `
      :host {
        display: block;
        line-height: 0;
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    NgSelectModule,
    MaterialModule,
    TranslocoModule,
    FormsModule,
    ReactiveFormsModule,
    MfFormHintHandlerComponent,
    MfFormErrorHandlerComponent,
    NgSelectFormFieldControlDirective,
  ],
})
export class MfFormSelectComponent extends MfFormAbstractFieldComponent implements OnInit {
  @Input() label?: string;
  @Input() items: MfAbstractValueLabelItemsModel<MfValueLabelValueType | null>[] | null = [];
  @Input() hintLabel?: string;
  @Input() patternName?: string;
  @Input() placeholder?: string;
  @Input() appendTo?: string;
  @Input() notFoundText?: string;
  @Input() groupByFn: any;

  @Input() addTag: boolean | ((term: string) => any | Promise<any>) = false;
  @Input() addTagText: string = '';
  @Input() dropdownPosition: 'auto' | 'top' | 'bottom' = 'auto';
  @Input() type: 'single' | 'multiple' = 'single';

  @Input()
  get searchType() {
    return this._searchType;
  }
  set searchType(type: Maybe<any>) {
    if (type) {
      this._searchType = type;
    }
  }
  _searchType: MfFormFieldSelectSearchTypeEnum = MfFormFieldSelectSearchTypeEnum.DEFAULT;

  @Input({ transform: booleanAttribute }) showOptionalHint: boolean = false;
  @Input({ transform: booleanAttribute }) noBottomOffset: boolean = false;
  @Input({ transform: booleanAttribute }) noLabel: boolean = false;
  @Input({ transform: booleanAttribute }) hideErrorMessage: boolean = false;
  @Input({ transform: booleanAttribute }) hideInfoIcon: boolean = false;
  @Input({ transform: booleanAttribute }) clearable: boolean = false;
  @Input({ transform: booleanAttribute }) showAddAllOptions: boolean = false;
  @Input({ transform: booleanAttribute }) showLoadingWhileNull: boolean = false;
  @Input({ transform: booleanAttribute }) onlyHasCustomTags: boolean = false;
  @Input({ transform: booleanAttribute }) showAsterisk: boolean = false;
  @Input({ transform: numberAttribute }) maxLengthHint?: number = 0;

  @Output() selectionChange: EventEmitter<MfValueLabelValueType> =
    new EventEmitter<MfValueLabelValueType>();

  public isRequired: boolean = false;
  private latestSearchEvent?: SearchEvent;

  constructor(
    @Attribute('translationPrefix') public readonly translationPrefix: string,
    @Attribute('translationPrefixScope') public readonly translationPrefixScope: string,
    private formService: MfFormService
  ) {
    super();

    this.translationPrefix = translationPrefix || 'SHARED.FORMS.ERROR.';
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.isRequired = this.formService.initRequiredStatus(this.hideAsterisk, this.control);
  }

  get hideAsterisk(): boolean {
    return !this.showAsterisk;
  }

  get isVirtualScrollEnabled(): boolean {
    return !!this.items && this.items.length > 50;
  }

  get bindValue(): string | undefined {
    return this.onlyHasCustomTags ? undefined : 'value';
  }

  get hasSublabels(): boolean {
    return !!this.items?.some(
      (item: MfValueLabelSublabelModel<MfValueLabelValueType | null>) => item.sublabel !== undefined
    );
  }

  get displayAddAllItemsButton(): boolean {
    if (!this.latestSearchEvent) {
      return false;
    }

    const { term, items }: SearchEvent = this.latestSearchEvent;

    return term.length > 0 && items.length > 1;
  }

  getItemDisplayLabel(item: MfAbstractValueLabelItemsModel | string): string {
    if (typeof item === 'string') {
      return item;
    } else {
      return item.label;
    }
  }

  onTyping(changeEvent: SearchEvent): void {
    if (this.showAddAllOptions && this.type === 'multiple') {
      this.latestSearchEvent = changeEvent;
    }
  }

  onClose(): void {
    this.latestSearchEvent = undefined;
  }

  searchFn(): (term: string, item: MfAbstractValueLabelItemsModel) => boolean {
    switch (this.searchType) {
      case MfFormFieldSelectSearchTypeEnum.FROM_BEGINNING:
        return this.searchFnFromBeginning;

      case MfFormFieldSelectSearchTypeEnum.DEFAULT:
      default:
        return this.searchFnDefault;
    }
  }

  private searchFnDefault(term: string, item: MfAbstractValueLabelItemsModel): boolean {
    const normalizedTerm: string = term.toLowerCase().trim();

    if (item?.label?.toLowerCase().includes(normalizedTerm)) {
      return true;
    } else {
      const itemWithSublabel: MfValueLabelSublabelModel = item;

      if (itemWithSublabel?.sublabel?.toLowerCase().includes(normalizedTerm)) {
        return true;
      }
    }

    return false;
  }

  private searchFnFromBeginning(term: string, item: MfAbstractValueLabelItemsModel): boolean {
    const normalizedTerm: string = term.toLowerCase();

    if (item.label?.toLowerCase().startsWith(normalizedTerm)) {
      return true;
    } else {
      const itemWithSublabel: MfValueLabelSublabelModel = item;

      if (itemWithSublabel.sublabel?.toLowerCase().startsWith(normalizedTerm)) {
        return true;
      }
    }

    return false;
  }

  selectionChangedValue(value: MfValueLabelValueType): void {
    this.selectionChange.emit(value);
  }

  addSearchResultItemsToControl(): void {
    // NOTE: Sorting and removal of duplicates is handled by ng-select
    const oldValues: MfValueLabelValueType[] = Array.isArray(this.control.value)
      ? (this.control.value as MfValueLabelValueType[])
      : [];

    const newValues = this.latestSearchEvent?.items.map((result) => result.value) || [];

    this.control.setValue([...newValues, ...oldValues]);

    this.latestSearchEvent = undefined;
  }
}
