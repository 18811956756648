<div class="form-container-sm">
  <mat-form-field class="is-table-search">
    <mat-icon matPrefix svgIcon="search" />
    <input
      class="ml-1"
      [(ngModel)]="searchTerm"
      [placeholder]="placeholder"
      (ngModelChange)="onSearch(searchTerm)"
      matInput
    />
  </mat-form-field>
</div>
