import { MfDataManagerImportInterface } from '@app/data-manager/services/import-data/data.interface';
import { MfDataManagerImportStateUtil } from '@app/data-manager/services/import-state/state.util';
import { environment } from '@env/environment';
import { MfAuthenticationService } from '@shared/authentication/authentication.service';
import { Maybe } from '@shared/types/util.types';
import { MathUtil } from '@shared/util/math.util';

type GetStateParamType = {
  _embedded?: { state?: MfDataManagerImportInterface['_embedded']['state'] };
};

export class MfDataManagerImportUtil {
  static getEncryptedTranslationKey(isEncrypted: Maybe<boolean>): string {
    return isEncrypted ? 'HAS_ENCRYPTION' : 'HAS_NO_ENCRYPTION';
  }

  static generateErrorReportLink(id: number): string {
    return `${
      environment.serverUrl
    }v1/data/uploads/${id}/csv?access_token=${MfAuthenticationService.getAccessToken()}`;
  }

  static getDurationFromStates(file: GetStateParamType): string {
    const firstState: number = MfDataManagerImportStateUtil.getFirstState(file).key / 1000;
    const lastState: number = MfDataManagerImportStateUtil.getLastState(file).key / 1000;

    const differenceInSeconds: number = Math.round(lastState - firstState);

    const [tempMinutes, seconds]: [number, number] = MathUtil.divisionWithRemainder(
      differenceInSeconds,
      60
    );
    const [hours, minutes]: [number, number] = MathUtil.divisionWithRemainder(tempMinutes, 60);

    const resultParts: string[] = [];

    if (hours) {
      resultParts.push(`${hours} h`);
    }

    if (hours || minutes) {
      resultParts.push(`${minutes} min`);
    }

    if (hours || minutes || seconds) {
      resultParts.push(`${seconds} s`);
    }

    return resultParts.join(' ');
  }
}
