import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material/material.module';
import { MfUnsubscribeComponent } from '@app/shared/abstract/unsubscribe.component';
import { Subject, timer } from 'rxjs';
import { debounce, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'mf-table-search',
  templateUrl: './search.component.html',
  standalone: true,
  imports: [CommonModule, MaterialModule, FormsModule],
})
export class MfTableSearchComponent extends MfUnsubscribeComponent implements OnInit {
  @Input() placeholder: string = '';
  @Input() searchTerm: string = '';

  @Output() search: EventEmitter<string> = new EventEmitter<string>();
  @Output() debouncedSearch: EventEmitter<string> = new EventEmitter<string>();

  private debouncer = new Subject<string>();

  ngOnInit(): void {
    this.debouncer
      .asObservable()
      .pipe(
        this.takeUntilUnsubscribe(),
        distinctUntilChanged(),
        debounce(() => timer(500))
      )
      .subscribe((value) => {
        this.debouncedSearch.emit(value);
      });
  }

  onSearch(value: string): void {
    this.search.emit(value);
    this.debouncer.next(value);
  }
}
