import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { MfUserStorage } from '@app/shared/authentication/user/user-storage.service';
import { Freshdesk } from '@app/shared/freshdesk/freshdesk.service';
import { SentryConfig } from '@app/shared/sentry/sentry-config';
import { versionInformation } from '@env/version';

@Component({
  selector: 'mf-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet],
})
export class AppComponent implements OnInit {
  constructor(private userStorage: MfUserStorage) {}

  ngOnInit(): void {
    (window as any).version = versionInformation.version;

    const user = this.userStorage.getUser();

    if (user) {
      SentryConfig.setUser(user);

      Freshdesk.setUser(user);
      Freshdesk.showWidget();
    }
  }
}
