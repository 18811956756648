import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  ActivatedRouteSnapshot,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { MfAuthenticationService } from '@app/shared/authentication/authentication.service';
import { MfUserEventService } from '@app/shared/authentication/user/user-event.service';
import { MfUserService } from '@app/shared/authentication/user/user.service';

@Injectable({ providedIn: 'root' })
export class MfAuthenticationGuard {
  constructor(
    private router: Router,
    private userService: MfUserService,
    private authenticationService: MfAuthenticationService,
    private userEventService: MfUserEventService,
    private matDialog: MatDialog
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const redirectUrl: string = '/' + segments.map((segment: UrlSegment) => segment.path).join('/');

    return this.checkLogin(redirectUrl, route);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state.url, route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(childRoute, state);
  }

  checkLogin(url: string, route: ActivatedRouteSnapshot | Route): boolean {
    const passwordChangeRequiredToken = localStorage.getItem('passwordChangeRequired');

    if (passwordChangeRequiredToken) {
      this.router.navigate([`/benutzerkonto/passwort-neu-vergeben/${passwordChangeRequiredToken}`]);

      return false;
    }

    const neededRoles: string[] | undefined = route.data?.['roles'];
    const userRoles: string[] = this.userService.getRoles();
    const hasNeededRoles: boolean =
      !neededRoles || neededRoles.filter((role: any) => userRoles.includes(role)).length > 0;

    if (this.userService.isLoggedIn() && hasNeededRoles) {
      return true;
    }

    // Store the attempted URL for redirecting
    if (url !== '/logout') {
      MfAuthenticationService.setRedirectUrl(url);
    }

    // Navigate to the login page
    this.authenticationService.logout();
    this.userEventService.triggerLogout();
    this.matDialog.closeAll();

    return false;
  }
}
