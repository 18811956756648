import { Injectable } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';
import { MfDateFormat } from '@app/shared/date/format/date-format';
import { DateUtil } from '@app/shared/util/date/date.util';
import { format, parse } from 'date-fns';

@Injectable()
export class MfCustomDateAdapter extends NativeDateAdapter {
  public override clone(date: Date | number | string): Date {
    return DateUtil.safeParseDate(date)!;
  }

  public override getFirstDayOfWeek(): number {
    return 1;
  }

  public override parse(value: string): Date | null {
    if (value && typeof value === 'string') {
      // ["date with 2-digit year", "date with 4-digit year"] - dates and months with
      // prefixed 0 are handeled automatically
      const parseFormats: string[] = ['d.M.yy', 'd.M.yyyy'];

      for (const parseFormat of parseFormats) {
        const parsedDate: Date = parse(value, parseFormat.trim(), new Date());
        if (this.isValid(parsedDate)) {
          return parsedDate;
        }
      }
    }

    return null;
  }

  public override format(date: Date, dateFormat: any): string {
    // use default implementation when datepicker header date needs to get formatted
    // TODO: Is there any better way to handle this?
    if (dateFormat.year === 'numeric' && dateFormat.month === 'short') {
      return super.format(date, dateFormat);
    } else {
      return format(date, MfDateFormat.MEDIUM_DATE);
    }
  }
}
