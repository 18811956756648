import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class MfFormService {
  public initRequiredStatus(hideAsterisk: boolean, control: AbstractControl): boolean {
    if (hideAsterisk) {
      return false;
    }

    let isRequired: boolean = false;

    if (control?.validator) {
      const validator = control.validator({} as AbstractControl);

      if (validator?.['required']) {
        isRequired = true;
      }
    }

    return isRequired;
  }

  public setErrorToField({ field, error }: any): void {
    field.setErrors({ [error]: true });
    field.markAsTouched();
  }
}
