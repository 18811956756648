// DateFormatUtil: Format patterns for date-fns used in project
// Names are inspired by angular's date-pipe (https://angular.io/api/common/DatePipe)

export class MfDateFormat {
  static SHORT_TIME: string = 'HH:mm';
  static MEDIUM_TIME: string = 'HH:mm:ss';

  static SHORT_DATE: string = 'dd.MM.yy';
  static MEDIUM_DATE: string = 'dd.MM.yyyy';

  static FILENAME_DATE: string = 'dd-MM-yyyy';
  static FILENAME: string = 'dd-MM-yyyy_HH-mm';

  static MEDIUM: string = 'dd.MM.yyyy, HH:mm';
}
