import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Attribute, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MfFormAbstractFieldComponent } from '@app/form/field/shared/abstract-field.component';
import { MfFormErrorHandlerComponent } from '@app/form/field/shared/error-handler/error-handler.component';
import { MfFormService } from '@app/form/field/shared/form.service';
import { MfFormHintHandlerComponent } from '@app/form/field/shared/hint-handler/hint-handler.component';
import { UrlFieldErrorMatcherClass } from '@app/form/field/url/url-field-error-matcher.class';
import { MaterialModule } from '@app/material/material.module';
import { Subject } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'mf-form-url',
  templateUrl: './url.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  standalone: true,
  imports: [
    MaterialModule,
    CommonModule,
    ReactiveFormsModule,
    MfFormHintHandlerComponent,
    MfFormErrorHandlerComponent,
  ],
})
export class MfFormUrlComponent extends MfFormAbstractFieldComponent implements OnInit, OnDestroy {
  @Input() label?: string;
  @Input() hintLabel?: string;
  @Input({ required: true }) patternName!: string;
  @Input() placeholder: string = '';

  @Input()
  get maxLengthHint(): number | undefined {
    return this._maxLengthHint;
  }
  set maxLengthHint(value: NumberInput) {
    this._maxLengthHint = coerceNumberProperty(value, undefined);
  }
  private _maxLengthHint?: number = undefined;

  @Input()
  get showAsterisk(): boolean {
    return this._showAsterisk;
  }
  set showAsterisk(value: BooleanInput) {
    this._showAsterisk = coerceBooleanProperty(value);
  }
  private _showAsterisk: boolean = false;

  get hideAsterisk(): boolean {
    return !this._showAsterisk;
  }

  @Input()
  get showOptionalHint(): boolean {
    return this._showOptionalHint;
  }
  set showOptionalHint(value: BooleanInput) {
    this._showOptionalHint = coerceBooleanProperty(value);
  }
  private _showOptionalHint: boolean = false;

  @Input()
  get disableAutocomplete(): boolean {
    return this._disableAutocomplete;
  }
  set disableAutocomplete(value: BooleanInput) {
    this._disableAutocomplete = coerceBooleanProperty(value);
  }
  private _disableAutocomplete: boolean = false;

  @Input()
  get showValidationSuccess(): boolean {
    return this._showValidationSuccess;
  }
  set showValidationSuccess(value: BooleanInput) {
    this._showValidationSuccess = coerceBooleanProperty(value);
  }
  private _showValidationSuccess: boolean = false;

  @Input()
  get noBottomOffset(): boolean {
    return this._noBottomOffset;
  }
  set noBottomOffset(value: BooleanInput) {
    this._noBottomOffset = coerceBooleanProperty(value);
  }
  private _noBottomOffset: boolean = false;

  @Input()
  get hideErrorMessage(): boolean {
    return this._hideErrorMessage;
  }
  set hideErrorMessage(value: BooleanInput) {
    this._hideErrorMessage = coerceBooleanProperty(value);
  }
  private _hideErrorMessage: boolean = false;

  @Input()
  get hideInfoIcon(): boolean {
    return this._hideInfoIcon;
  }
  set hideInfoIcon(value: BooleanInput) {
    this._hideInfoIcon = coerceBooleanProperty(value);
  }
  private _hideInfoIcon: boolean = false;

  public isRequired: boolean = false;
  public protocol: string = 'https://';
  public errorMatcher!: UrlFieldErrorMatcherClass;
  public urlFormControl: UntypedFormControl = new UntypedFormControl();
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    @Attribute('translationPrefix') public readonly translationPrefix: string,
    @Attribute('translationPrefixScope') public readonly translationPrefixScope: string,
    private formService: MfFormService
  ) {
    super();
    this.translationPrefix = translationPrefix || 'SHARED.FORMS.ERROR.';
    this.placeholder = '';
  }

  override ngOnInit(): void {
    super.ngOnInit();
    this.isRequired = this.formService.initRequiredStatus(this.hideAsterisk, this.control);

    this.errorMatcher = new UrlFieldErrorMatcherClass(this.control);

    this.control.valueChanges
      .pipe(startWith(this.control.value), takeUntil(this.unsubscribe$))
      .subscribe((value: string) => {
        this.updateFormInsideData(value || '');
      });

    this.urlFormControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(
        map((value: string) => {
          const valueWithoutHttpAndHttps: string = (value + '').replace(/^https?:\/\//g, '');
          this.urlFormControl.setValue(valueWithoutHttpAndHttps, {
            onlySelf: true,
            emitEvent: false,
          });

          return valueWithoutHttpAndHttps;
        })
      )
      .subscribe((value: string) => {
        this.updateExternalForm(value);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private updateFormInsideData(value: string): void {
    const valueWithoutHttpAndHttps: string = (value + '').replace(/^https?:\/\//g, '');

    this.urlFormControl.setValue(valueWithoutHttpAndHttps, {
      emitEvent: false,
    });

    setTimeout(() => {
      this.urlFormControl.setErrors(this.control.errors);
    });
  }

  private updateExternalForm(value: any): void {
    const resultValue: string = value ? this.protocol + value : '';
    this.control.setValue(resultValue);
    this.control.markAsDirty();
    this.control.markAsTouched();
  }
}
