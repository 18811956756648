import { Injectable } from '@angular/core';
import { Event } from '@angular/router';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollService {
  private subject: Subject<Event> = new Subject<Event>();
  private scrollContainer?: HTMLElement;

  get scroll$(): Observable<Event> {
    return this.subject.asObservable();
  }

  triggerScroll($event: Event): void {
    this.subject.next($event);
  }

  registerScrollContainer(scrollContainer: HTMLElement): void {
    this.scrollContainer = scrollContainer;
  }

  getScrollContainer(): HTMLElement | undefined {
    return this.scrollContainer;
  }
}
