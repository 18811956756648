export const fileToBase64 = (file: File, keepBase64Header: boolean = false) =>
  new Promise<string>((res) => {
    const fileReader: FileReader = new FileReader();

    fileReader.onload = () => {
      let fileBase64: string;
      if (keepBase64Header) {
        fileBase64 = fileReader.result as string;
      } else {
        fileBase64 = (fileReader.result as string).replace(/^data:.+;base64,/, '');
      }

      res(fileBase64);
    };

    fileReader.readAsDataURL(file);
  });
