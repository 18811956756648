import { MfUserModel } from '@app/shared/authentication/user/user.model';
import { FreshdeskWidget } from '@app/shared/freshdesk/freshdesk.types';
import { MfUserUtil } from '@app/shared/util/user.util';

type WindowWithFreshdesk = Window &
  typeof globalThis & {
    FreshworksWidget: FreshdeskWidget;
  };

const getWindow = (): WindowWithFreshdesk => window as WindowWithFreshdesk;

export class Freshdesk {
  static get Widget(): FreshdeskWidget | undefined {
    const window = getWindow();

    return window.FreshworksWidget;
  }

  static hideWidget(): void {
    this.Widget?.('hide');
  }

  static showWidget(): void {
    this.Widget?.('show');
  }

  static setUser(user: MfUserModel): void {
    this.Widget?.('identify', 'ticketForm', {
      name: MfUserUtil.formatFullName(user),
      email: user.email,
    });

    if (user._embedded?.bank) {
      this.Widget?.('prefill', 'ticketForm', {
        custom_fields: {
          cf_bank_id: String(user._embedded.bank.id),
          cf_bank: user._embedded.bank.name,
        },
      });
    }
  }
}
