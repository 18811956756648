import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { StringUtil } from '@app/shared/util/string.util';

@Component({
  selector: 'mf-form-util-length-handler',
  templateUrl: './length-handler.component.html',
  standalone: true,
  imports: [CommonModule],
})
export class MfFormLengthHandlerComponent {
  @Input() field?: AbstractControl;
  @Input() maxLength?: number;
  @Input() lengthWarning?: number;
  @Input() length?: number;

  getLength(): number {
    return this.length || StringUtil.lengthOfTextInHtml(this.field?.value) || 0;
  }
}
