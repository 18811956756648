/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */
import { MfUserRolesEnum } from '@app/shared/authentication/types/user_roles.enum';
import { MfDataBankInterface, MfDataPartnerInterface } from '@app/shared/data/data.interface';

export class MfUserModel {
  constructor(
    public id?: number,
    public gender?: string,
    public firstname?: string,
    public lastname?: string,
    public email?: string,
    public newEmail?: string | null,
    public password?: string,
    public roles?: MfUserRolesEnum[],
    public isBankUser?: boolean,
    public isPartnerUser?: boolean,
    public _embedded?: {
      bank?: MfDataBankInterface;
      partner?: MfDataPartnerInterface;
      responsibleCampaigns?: number;
    }
  ) {}
}
