export enum MfDataManagerExportDownloaderStatusEnum {
  INITIALIZE = 'initialize',
  WAIT_FOR_ACTION = 'waitForAction',
  WAIT_FOR_DOWNLOAD_ACTION = 'waitForDownloadAction',
  EMPTY = 'empty',
  DECRYPT = 'decrypt',
  GENERATE = 'generate',
  COMPRESS = 'compress',
  ERROR = 'error',
  DOWNLOAD_ERROR = 'downloadError',
}
