<ng-container *transloco="let t; scope: 'dataTransfer'">
  <div *ngIf="isLoggedInAsDataManager" class="widget-container flex flex-col space-y-4">
    <div *ngIf="showExportWidget" class="export-widget-container" [@fade-toggle]="showExportWidget">
      <mf-data-manager-export-downloader isWidget />
    </div>

    <div *ngIf="showImportWidget" class="import-widget-container" [@fade-toggle]="showImportWidget">
      <ng-container [ngSwitch]="uploaderService.importStep | async">
        <ng-template [ngSwitchCase]="MfDataManagerImportStepEnum.UPLOADING">
          <mf-data-manager-card icon="file_upload" isWidget>
            <p class="font-bold">
              {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.PROGRESS_TITLE') }}
            </p>

            <div>
              <p class="mb-2">
                <ng-template [ngIf]="!uploadState?.progress">
                  <span>{{ t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.PREPARE_UPLOAD') }}</span>
                </ng-template>

                <ng-template
                  [ngIf]="uploadState && uploadState.progress > 0 && uploadState.progress < 100"
                >
                  <span>{{ uploadState.loadedBytes | fileSize }}&thinsp;</span>
                  <span>{{ t('SHARED.GENERAL.OF') | lowercase }}&thinsp;</span>
                  <span>{{ uploadState.totalBytes | fileSize }}</span>
                  <span>&nbsp;</span>
                  <span>({{ uploadState.progress }}%)</span>
                  <br />
                  <span>{{ uploadState.uploadTimeEstimate | uploadTimeEstimate }}</span>
                </ng-template>

                <ng-template [ngIf]="uploadState?.progress === 100">
                  <span>{{ t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.FOLLOW_UP_UPLOAD') }}</span>
                </ng-template>
              </p>

              <mat-progress-bar
                class="upload-progress-bar used-in-widget"
                [value]="uploadState?.progress"
                mode="determinate"
              />
            </div>
          </mf-data-manager-card>
        </ng-template>

        <ng-template [ngSwitchCase]="MfDataManagerImportStepEnum.UPLOAD_ERROR">
          <mf-data-manager-card icon="error" iconClass="has-error" isWidget>
            <p class="mb-1 font-bold">
              <ng-container [ngSwitch]="uploadState?.responseStatus">
                <mat-error *ngSwitchCase="403">
                  {{
                    t(
                      'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.NETWORK_PROBLEM.TITLE_WIDGET'
                    )
                  }}
                </mat-error>
                <mat-error *ngSwitchDefault>
                  {{
                    t(
                      'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_CONTENT.TITLE_WIDGET'
                    )
                  }}
                </mat-error>
              </ng-container>
            </p>

            <p>
              <ng-container [ngSwitch]="uploadState?.responseStatus">
                <mat-error *ngSwitchCase="403">
                  {{
                    t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.NETWORK_PROBLEM.MESSAGE')
                  }}
                </mat-error>
                <mat-error *ngSwitchDefault>
                  {{
                    t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_CONTENT.MESSAGE')
                  }}
                </mat-error>
              </ng-container>
            </p>

            <div class="mt-2">
              <div class="flex space-x-4">
                <span *ngIf="!isOnImportPage" class="link" routerLink="/datentransfer/datenimport">
                  {{ t('dataTransfer.IMPORT.WIDGET.ACTIONS.GO_TO_UPLOAD_PAGE') }}
                </span>
                <span class="link" (click)="showImportWidget = false">
                  {{ t('SHARED.GENERAL.DATE_TIME.LATER') | titlecase }}
                </span>
              </div>
            </div>
          </mf-data-manager-card>
        </ng-template>

        <ng-template [ngSwitchCase]="MfDataManagerImportStepEnum.NEEDS_PASSWORDS">
          <mf-data-manager-card isWidget>
            <p class="mb-1 px-3 font-bold">
              <span>{{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.TITLE') }}</span>
            </p>

            <p *ngIf="isOnImportPage; else notOnImportPage" class="px-3">
              <span>
                {{
                  t(
                    'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.ENTER_PASSWORD.' +
                      MfDataManagerImportUtil.getEncryptedTranslationKey(uploadedImport?.encrypted)
                  )
                }}
              </span>
            </p>

            <ng-template #notOnImportPage>
              <p class="px-3">
                <ng-template [ngIf]="!passwordCollapsed">
                  <span>
                    {{
                      t(
                        'dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.' +
                          MfDataManagerImportUtil.getEncryptedTranslationKey(
                            uploadedImport?.encrypted
                          ) +
                          '.INITIAL'
                      )
                    }}
                  </span>
                  <span class="link" (click)="passwordCollapsed = !passwordCollapsed">
                    {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_2') }}
                  </span>
                  <span>
                    {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_3') }}
                  </span>
                  <a class="link" routerLink="/datentransfer/datenimport">
                    <span>
                      {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_4') }}
                    </span>
                  </a>
                  <span>
                    {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_5') }}
                  </span>
                </ng-template>

                <ng-template [ngIf]="passwordCollapsed">
                  <span>
                    {{
                      t(
                        'dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.' +
                          MfDataManagerImportUtil.getEncryptedTranslationKey(
                            uploadedImport?.encrypted
                          ) +
                          '.COLLAPSED'
                      )
                    }}
                  </span>
                  <a class="link" routerLink="/datentransfer/datenimport">
                    <span>
                      {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_4') }}
                    </span>
                  </a>
                  <span>
                    {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_5') }}
                  </span>
                </ng-template>
              </p>
            </ng-template>

            <div
              *ngIf="passwordCollapsed && !isOnImportPage"
              class="has-divider mt-3 px-3 pt-4"
              [@fade-toggle]="passwordCollapsed && !isOnImportPage"
            >
              <mf-data-manager-import-uploader-password
                [primaryActionLabel]="t('dataTransfer.IMPORT.UPLOAD_CARD.ACTIONS.CONTINUE_IMPORT')"
                [showDecryptPassword]="uploadedImport?.encrypted"
                (cancelPatch)="uploaderService.cancelImport()"
                (submitPatch)="uploaderService.submitPasswords($event)"
                isUsedInWidget
                showPseudonymizationPassword
              />
            </div>
          </mf-data-manager-card>
        </ng-template>

        <ng-template [ngSwitchCase]="MfDataManagerImportStepEnum.SUBMITTING_PASSWORDS">
          <mf-data-manager-card iconAnimated isWidget>
            <p>
              {{
                t(
                  'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.CHECKING_PASSWORD.' +
                    MfDataManagerImportUtil.getEncryptedTranslationKey(uploadedImport?.encrypted)
                )
              }}
            </p>
          </mf-data-manager-card>
        </ng-template>

        <ng-template [ngSwitchCase]="MfDataManagerImportStepEnum.ENCRYPTION_PASSWORD_WRONG">
          <mf-data-manager-card icon="error" iconClass="has-error" isWidget>
            <p class="mb-1 font-bold">
              <mat-error>
                {{
                  t(
                    'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_ENCRYPTION_PASSWORD.WIDGET.TITLE'
                  )
                }}
              </mat-error>
            </p>

            <p *ngIf="isOnImportPage; else notOnImportPage">
              <span>
                {{
                  t(
                    'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_ENCRYPTION_PASSWORD.MESSAGE'
                  )
                }}
              </span>
            </p>

            <ng-template #notOnImportPage>
              <p class="mb-4">
                <mat-error>
                  <span>
                    {{
                      t(
                        'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_ENCRYPTION_PASSWORD.WIDGET.MESSAGE_1'
                      )
                    }}
                  </span>
                  <a class="link" routerLink="/datentransfer/datenimport">
                    <span>
                      {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_4') }}
                    </span>
                  </a>
                  <span>
                    {{
                      t(
                        'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_ENCRYPTION_PASSWORD.WIDGET.MESSAGE_2'
                      )
                    }}
                  </span>
                </mat-error>
              </p>

              <mf-data-manager-import-uploader-password
                [primaryActionLabel]="t('SHARED.GENERAL.CONTINUE')"
                (cancelPatch)="uploaderService.cancelImport()"
                (submitPatch)="uploaderService.submitPasswords($event)"
                isUsedInWidget
                showDecryptPassword
              />
            </ng-template>
          </mf-data-manager-card>
        </ng-template>

        <ng-template [ngSwitchCase]="MfDataManagerImportStepEnum.PSEUDONYMIZATION_PASSWORD_WRONG">
          <mf-data-manager-card icon="error" iconClass="has-error" isWidget>
            <p class="mb-1 font-bold">
              <mat-error>
                {{
                  t(
                    'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_PSEUDONYMIZATION_PASSWORD.WIDGET.TITLE'
                  )
                }}
              </mat-error>
            </p>

            <p *ngIf="isOnImportPage; else notOnImportPage">
              <span>
                {{
                  t(
                    'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_PSEUDONYMIZATION_PASSWORD.MESSAGE'
                  )
                }}
              </span>
            </p>

            <ng-template #notOnImportPage>
              <p class="mb-4">
                <mat-error>
                  <span>
                    {{
                      t(
                        'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_PSEUDONYMIZATION_PASSWORD.WIDGET.MESSAGE_1'
                      )
                    }}
                  </span>
                  <a class="link" routerLink="/datentransfer/datenimport">
                    <span>
                      {{ t('dataTransfer.IMPORT.WIDGET.UPLOAD.INPUT_NEEDED.SHARED.PART_4') }}
                    </span>
                  </a>
                  <span>
                    {{
                      t(
                        'dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.WRONG_PSEUDONYMIZATION_PASSWORD.WIDGET.MESSAGE_2'
                      )
                    }}
                  </span>
                </mat-error>
              </p>

              <mf-data-manager-import-uploader-password
                [primaryActionLabel]="t('SHARED.GENERAL.CONTINUE')"
                (cancelPatch)="uploaderService.cancelImport()"
                (submitPatch)="uploaderService.submitPasswords($event)"
                isUsedInWidget
                showPseudonymizationPassword
              />
            </ng-template>
          </mf-data-manager-card>
        </ng-template>

        <ng-template [ngSwitchCase]="MfDataManagerImportStepEnum.UNKNOWN_ERROR">
          <mf-data-manager-card icon="error" iconClass="has-error" isWidget>
            <p class="mb-1 font-bold">
              <mat-error>
                {{ t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.ERROR.TITLE_WIDGET') }}
              </mat-error>
            </p>

            <p class="mb-4">
              <mat-error>
                {{ t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_FAILED.ERROR.MESSAGE') }}
              </mat-error>
            </p>

            <mf-secondary-button
              [label]="t('dataTransfer.IMPORT.UPLOAD_CARD.ACTIONS.CANCEL_IMPORT')"
              (clicked)="uploaderService.cancelImport()"
              autoStretch
            />
          </mf-data-manager-card>
        </ng-template>

        <ng-template [ngSwitchCase]="MfDataManagerImportStepEnum.FINISHED">
          <mf-data-manager-card class="has-success" icon="success" isWidget>
            <p>
              {{ t('dataTransfer.IMPORT.UPLOAD_CARD.HINTS.UPLOAD_SUCCESS') }}
            </p>
          </mf-data-manager-card>
        </ng-template>
      </ng-container>
    </div>
  </div>
</ng-container>
