import { Injectable } from '@angular/core';
import { DateRange } from '@app/form/field/date-range-input/date-range.class';
import { ConfirmDialogDoNotShowAgainKeys } from '@app/shared/dialog/do-not-show-again.enum';
import { MfTableSelectedFilterMultipleInterface } from '@app/shared/table/filter-multiple/filter-multiple.interface';
import { MfUserCustomizationDataService } from '@app/shared/user-customization/data/data.service';
import { MfTableSelectedFilterDateInterface } from '@shared/table/filter-date/filter-date.interface';
import { BehaviorSubject, of, Subscription, timer } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, debounce, map } from 'rxjs/operators';

export type MfUserCustomizationValueType = Record<string, any> | string;
export type MfUserCustomizationType = Record<string, MfUserCustomizationValueType>;

@Injectable({
  providedIn: 'root',
})
export class MfUserCustomizationService {
  public readonly LOCAL_STORAGE_KEY: string = 'user-customization';
  private internalData: MfUserCustomizationType = {};
  private updatedData$: BehaviorSubject<MfUserCustomizationType>;
  private updatedDataSubscription?: Subscription;

  constructor(private dataService: MfUserCustomizationDataService) {
    this.getFromLocalStorage();
    this.updatedData$ = new BehaviorSubject(this.internalData);
  }

  get data(): MfUserCustomizationType {
    return this.internalData;
  }

  protected initUpdatedData(): void {
    if (!this.updatedDataSubscription) {
      this.updatedDataSubscription = this.updatedData$
        .asObservable()
        .pipe(debounce(() => timer(500)))
        .subscribe({
          next: (data) => {
            this.dataService.saveAll(data).subscribe();
          },
        });
    }
  }

  loadData(): Observable<void> {
    return this.dataService
      .getData()
      .pipe(
        catchError((err: any) => {
          // Catch error to not cause any problems downstream
          console.warn('Error while loading user customization', err);

          return of({});
        })
      )
      .pipe(
        map((value) => {
          this.setCompleteData(value || {}, true);
          this.updateLocalStorage();
        })
      );
  }

  get(key: string): MfUserCustomizationValueType {
    return this.internalData[key];
  }

  getTablePagination(tableKey: string): number | null {
    const data: MfUserCustomizationValueType = this.internalData['table-pagination'];

    // Table-data should never be a string
    if (data && typeof data !== 'string') {
      const tableData: number = parseInt(data[tableKey], 10);

      if (tableData && !isNaN(tableData)) {
        return tableData;
      }
    }

    return null;
  }

  setTablePagination(tableKey: string, value: number): Observable<any> {
    if (
      !this.internalData['table-pagination'] ||
      typeof this.internalData['table-pagination'] === 'string'
    ) {
      this.internalData['table-pagination'] = {};
    }

    this.internalData['table-pagination'][tableKey] = value;

    return this.updateStorage();
  }

  getGlobalFilter(tableKey: string): string | null {
    const data: MfUserCustomizationValueType = this.internalData['table-filters'];

    if (typeof data !== 'string' && data?.[tableKey]?.['global-filter']) {
      return data[tableKey]['global-filter'];
    }

    return null;
  }

  setMultipleFilters(
    tableKey: string,
    value: string | MfTableSelectedFilterMultipleInterface<any>
  ): Observable<any> {
    this.initTableFilter(tableKey);

    if (typeof this.internalData['table-filters'] !== 'string') {
      const tableStoredData: any = this.internalData['table-filters'][tableKey];

      if (typeof value === 'string') {
        tableStoredData['global-filter'] = value;
      } else if (value.selectedItems) {
        if (tableStoredData['multiple-filters']?.length) {
          let sameFilterName: boolean = false;
          tableStoredData['multiple-filters'].forEach(
            (filter: MfTableSelectedFilterMultipleInterface) => {
              if (filter.filterName === value.filterName) {
                filter.selectedItems = value.selectedItems;
                sameFilterName = true;
              }
            }
          );

          if (!sameFilterName) {
            tableStoredData['multiple-filters'] = [...tableStoredData['multiple-filters'], value];
          }
        } else {
          tableStoredData['multiple-filters'] = [value];
        }
      }
    }

    return this.updateStorage();
  }

  getMultipleFilters(tableKey: string): any | undefined {
    if (
      typeof this.internalData['table-filters'] !== 'string' &&
      this.internalData['table-filters']?.[tableKey]?.['multiple-filters']
    ) {
      return this.internalData['table-filters'][tableKey]['multiple-filters'];
    }
  }

  clearMultipleFilters(tableKey: string): void {
    if (
      typeof this.internalData['table-filters'] !== 'string' &&
      this.internalData['table-filters']?.[tableKey]?.['multiple-filters']
    ) {
      this.internalData['table-filters'][tableKey]['multiple-filters'] = [];
      this.updateStorage();
    }
  }

  setDateFilters(
    tableKey: string,
    value: string | MfTableSelectedFilterDateInterface<any>
  ): Observable<any> {
    this.initTableFilter(tableKey);

    if (typeof this.internalData['table-filters'] !== 'string') {
      const tableStoredData: any = this.internalData['table-filters'][tableKey];

      if (typeof value === 'string') {
        tableStoredData['global-filter'] = value;
      } else {
        if (tableStoredData['date-filters']?.length) {
          let sameFilterName: boolean = false;
          tableStoredData['date-filters'].forEach((filter: MfTableSelectedFilterDateInterface) => {
            if (filter.filterName === value.filterName) {
              filter.selected = value.selected;
              sameFilterName = true;
            }
          });

          if (!sameFilterName) {
            tableStoredData['date-filters'] = [...tableStoredData['date-filters'], value];
          }
        } else {
          tableStoredData['date-filters'] = [value];
        }
      }
    }

    return this.updateStorage();
  }

  getDateFilters(tableKey: string): any | undefined {
    if (
      typeof this.internalData['table-filters'] !== 'string' &&
      this.internalData['table-filters']?.[tableKey]?.['date-filters']
    ) {
      return this.internalData['table-filters'][tableKey]['date-filters'].map(
        (filter: MfTableSelectedFilterDateInterface) => {
          if (filter.selected) {
            if (typeof filter.selected === 'object') {
              filter.selected = DateRange.fromObject(filter.selected) ?? undefined;
            }
          }

          return filter;
        }
      );
    }
  }

  clearDateFilters(tableKey: string): void {
    if (
      typeof this.internalData['table-filters'] !== 'string' &&
      this.internalData['table-filters']?.[tableKey]?.['date-filters']
    ) {
      this.internalData['table-filters'][tableKey]['date-filters'] = [];
      this.updateStorage();
    }
  }

  private initTableFilter(tableKey: string): void {
    if (
      !this.internalData['table-filters'] ||
      typeof this.internalData['table-filters'] === 'string'
    ) {
      this.internalData['table-filters'] = {};
    }

    if (!this.internalData['table-filters'][tableKey]) {
      this.internalData['table-filters'][tableKey] = {};
    }
  }

  set(key: string, value: MfUserCustomizationValueType, suppressUpdate?: boolean): Observable<any> {
    // Only update when value actually changed
    if (this.internalData[key] !== value) {
      this.internalData[key] = value;

      if (!suppressUpdate) {
        return this.updateStorage();
      }
    }

    return of({});
  }

  setCompleteDataFromString(value: string | null): void {
    let parsedValue: MfUserCustomizationType = {};

    if (value) {
      try {
        parsedValue = JSON.parse(value) || {};
      } catch {}
    }

    this.internalData = parsedValue;

    // this will only be called from a request or from initial load, therefore no backend update is needed
    this.updateLocalStorage();
  }

  setCompleteData(value: MfUserCustomizationType, suppressUpdate?: boolean): Observable<any> {
    this.internalData = value || {};

    if (!suppressUpdate) {
      return this.updateStorage();
    } else {
      return of({});
    }
  }

  clear(): void {
    this.setCompleteDataFromString('');
  }

  private updateStorage(): Observable<any> {
    this.updateLocalStorage();

    this.triggerUpdate();

    return of({});
  }

  protected triggerUpdate(): void {
    this.initUpdatedData();

    this.updatedData$.next(this.internalData);
  }

  getFromLocalStorage(): void {
    this.setCompleteDataFromString(localStorage.getItem(this.LOCAL_STORAGE_KEY));
  }

  private updateLocalStorage(): void {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(this.internalData));
  }

  private getDoNotShowAgainGroup(): Partial<Record<ConfirmDialogDoNotShowAgainKeys, boolean>> {
    const group = this.internalData['do-not-show-again'];

    if (!group || typeof group === 'string') {
      return {};
    }

    return group;
  }

  shouldNotShowAgain(key: ConfirmDialogDoNotShowAgainKeys) {
    return !!this.getDoNotShowAgainGroup()[key];
  }

  enableDoNotShowAgain(key: ConfirmDialogDoNotShowAgainKeys) {
    const config = this.getDoNotShowAgainGroup();
    config[key] = true;

    this.internalData['do-not-show-again'] = config;

    return this.updateStorage();
  }
}
