import {
  animate,
  AnimationTriggerMetadata,
  keyframes,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const fadeToggle: AnimationTriggerMetadata = trigger('fade-toggle', [
  transition(':enter', [
    style({ opacity: 0, height: '0', overflow: 'hidden' }),
    animate(
      '.25s ease-in',
      keyframes([
        style({ opacity: 0, height: '0', offset: 0 }),
        style({ opacity: 0, height: '*', offset: 0.25 }),
        style({ opacity: 0.25, height: '*', offset: 0.75 }),
        style({ opacity: 1, height: '*', offset: 1 }),
      ])
    ),
  ]),
  transition(':leave', [
    style({ opacity: 1, height: '*', overflow: 'hidden' }),
    animate(
      '.25s ease-in',
      keyframes([
        style({ opacity: 1, height: '*', offset: 0 }),
        style({ opacity: 0, height: '*', offset: 0.5 }),
        style({ opacity: 0, height: '0', offset: 1 }),
      ])
    ),
  ]),
]);
