export const MfBreakpoints = {
  SM: 'screen and (min-width: 600px) and (max-width: 854.9px)',
  MD: 'screen and (min-width: 855px) and (max-width: 1154.9px)',
  LG: 'screen and (min-width: 1155px) and (max-width: 1434.9px)',
  XL: 'screen and (min-width: 1435px)',
  LT_SM: 'screen and (min-width: 600px) and (max-width: 854.9px)',
  LT_MD: 'screen and (max-width: 854.9px)',
  LT_LG: 'screen and (max-width: 1154.9px)',
  LT_XL: 'screen and (max-width: 1434.9px)',
  GT_XS: 'screen and (min-width: 600px)',
  GT_SM: 'screen and (min-width: 855px)',
  GT_MD: 'screen and (min-width: 1155px)',
  GT_LG: 'screen and (min-width: 1435px)',
};
