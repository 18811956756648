import { MfValueLabelValueType } from './value-label.type';

export class MfValueLabelModel<Value = MfValueLabelValueType, Meta = any> {
  constructor(
    public value: Value,
    public label: string,
    public disabled?: boolean,
    public meta?: Meta
  ) {}

  static simple(value: string): MfValueLabelModel {
    return new MfValueLabelModel(value, value);
  }

  static simpleList(values: string[]): MfValueLabelModel[] {
    return values.map((value) => new MfValueLabelModel(value, value));
  }
}
