import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, booleanAttribute } from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MfDataManagerImportPasswordsInterface } from '@app/data-manager/components/import/uploader/uploader.types';
import { MfFormInputComponent } from '@app/form/field/input/input.component';
import { MfFormFocusFirstInvalidFieldDirective } from '@app/form/field/shared/focus-first-invalid-field/focus-first-invalid-field.directive';
import { MfFormErrorScrollUtilService } from '@app/form/shared/error-scroll/error-scroll-util.service';
import { TranslocoDirective } from '@jsverse/transloco';
import { MfPrimaryButtonComponent } from '@shared/components/buttons/primary/primary.component';
import { MfSecondaryButtonComponent } from '@shared/components/buttons/secondary/secondary.component';

@Component({
  selector: 'mf-data-manager-import-uploader-password',
  templateUrl: './password.component.html',
  standalone: true,
  imports: [
    TranslocoDirective,
    ReactiveFormsModule,
    MfFormFocusFirstInvalidFieldDirective,
    CommonModule,
    MfFormInputComponent,
    MfPrimaryButtonComponent,
    MfSecondaryButtonComponent,
  ],
})
export class MfDataManagerImportUploaderPasswordComponent implements OnInit {
  @Input() primaryActionLabel?: string;
  @Input({ transform: booleanAttribute }) showDecryptPassword: boolean = false;
  @Input({ transform: booleanAttribute }) showPseudonymizationPassword: boolean = false;
  @Input({ transform: booleanAttribute }) isUsedInWidget: boolean = false;

  @Output() cancelPatch: EventEmitter<void> = new EventEmitter();
  @Output() submitPatch: EventEmitter<MfDataManagerImportPasswordsInterface> =
    new EventEmitter<MfDataManagerImportPasswordsInterface>();

  public passwordForm!: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private mfFormErrorScrollUtilService: MfFormErrorScrollUtilService
  ) {}

  ngOnInit(): void {
    this.createForm();
    if (this.showDecryptPassword) {
      this.addPasswordField('zipPassword');
    }
    if (this.showPseudonymizationPassword) {
      this.addPasswordField('password');
    }
  }

  private createForm(): void {
    this.passwordForm = this.fb.group({});
  }

  private addPasswordField(fieldName: string): void {
    this.passwordForm.addControl(fieldName, this.fb.control('', [Validators.required]));
  }

  public onFormSubmit(): void {
    this.passwordForm.markAllAsTouched();

    if (this.passwordForm.invalid) {
      this.mfFormErrorScrollUtilService.scrollToError();

      return;
    }

    this.submitPatch.emit(this.passwordForm.value);
  }
}
