import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';
import { MfHintBoxComponent } from '@app/shared/components/hint-box/hint-box.component';
import Player from '@vimeo/player';

@Component({
  selector: 'mf-vimeo-video',
  templateUrl: './vimeo-video.component.html',
  standalone: true,
  imports: [CommonModule, MfHintBoxComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MfVimeoVideoComponent implements AfterViewInit {
  @ViewChild('vimeoPlayer') vimeoPlayer!: ElementRef;

  @Input() url: string = '';
  @Input() options: Player.Options = {};

  @Input()
  get autoplay(): boolean {
    return this._autoplay;
  }

  set autoplay(value: BooleanInput) {
    this._autoplay = coerceBooleanProperty(value);
  }

  public loadingTimeoutFinished: boolean = false;
  public videoLoaded: boolean = false;
  private _autoplay: boolean = false;

  private player: Player | null = null;

  constructor(private cd: ChangeDetectorRef) {}

  async ngAfterViewInit(): Promise<void> {
    this.videoLoaded = false;

    setTimeout(() => {
      this.loadingTimeoutFinished = true;
      this.cd.detectChanges();
    }, 1500);

    this.player = new Player(this.vimeoPlayer.nativeElement, {
      url: this.url,
      ...this.options,
    });

    this.player.on('loaded', async () => {
      this.videoLoaded = true;
      this.cd.detectChanges();

      if (this.player && this.autoplay) {
        await this.play();
      }
    });
  }

  async play() {
    try {
      await this.player?.play();
    } catch (e) {}
  }
}
