<ng-container *transloco="let t; scope: 'organization'">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" mfFocusFirstInvalidField novalidate>
    <h2 class="mat-headline" mat-dialog-title>{{ data.title }}</h2>
    <div class="overflow-visible" mat-dialog-content>
      <div class="form-container-lg signature-field mb-4">
        <h4 class="mat-subheading-1 signature mb-4">
          {{ t('organization.CONSULTANT.UPSERT.FORM.SIGNATURE_FILE') }}
        </h4>

        <mf-form-image-input
          [control]="form.controls.signature"
          [filenameControl]="form.controls.signatureFilename"
          [maxAspectRatio]="[5, 2]"
          [showOptionalHint]="data.optional"
          maxSize="0.5"
          valueType="string"
        />
      </div>

      <div class="form-container-lg">
        <mf-form-html-input
          [formGroup]="form"
          [label]="t('organization.CONSULTANT.UPSERT.FORM.SIGNATURE_ADDITION')"
          [showOptionalHint]="data.optional"
          name="signatureAddition"
          toolbarFeaturesType="consultantSignature"
        />
      </div>
    </div>
    <div class="styled" mat-dialog-actions>
      <mf-tertiary-button class="mr-2" [label]="t('SHARED.ACTION.CANCEL')" (clicked)="close()" />
      <mf-primary-button [label]="t('SHARED.ACTION.SAVE_CHANGES')" hasInitialFocus type="submit" />
    </div>
  </form>
</ng-container>
