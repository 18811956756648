<div
  *transloco="let t; scope: data.translationScope; prefix: data.translationScope"
  class="notification-content flex items-center gap-1"
  [ngClass]="{
    success: data.notification.type === NotificationEnum.SUCCESS,
    info: data.notification.type === NotificationEnum.INFO,
    error: data.notification.type === NotificationEnum.ERROR,
  }"
  (click)="dismiss()"
>
  <mat-icon class="flex-shrink-0" [svgIcon]="data.notification.type" />
  <div>
    <div *ngIf="data.notification.title" class="title">
      {{ t(data.notification.title, data.notification.params) }}
    </div>
    <div class="message">{{ t(data.notification.message, data.notification.params) }}</div>
  </div>
</div>
