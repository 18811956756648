export interface MfDataManagerImportUploadStateInterface {
  filename: string;
  loadedBytes: number;
  totalBytes: number;
  progress: number;
  responseStatus: number;
  startTimeMs: number;
  uploadTimeEstimate: number;
}

export enum MfDataManagerImportStepEnum {
  NO_FILE_SELECTED = 'NO_FILE_SELECTED',
  FILE_SELECTED = 'FILE_SELECTED',
  UPLOADING = 'UPLOADING',
  UPLOAD_ERROR = 'UPLOAD_ERROR',
  NEEDS_PASSWORDS = 'NEEDS_PASSWORDS',
  SUBMITTING_PASSWORDS = 'SUBMITTING_PASSWORDS',
  ENCRYPTION_PASSWORD_WRONG = 'ENCRYPTION_PASSWORD_WRONG',
  PSEUDONYMIZATION_PASSWORD_WRONG = 'PSEUDONYMIZATION_PASSWORD_WRONG',
  FINISHED = 'FINISHED',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}
