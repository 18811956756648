import { Injectable } from '@angular/core';
import { MfBreadcrumbItem } from '@app/shared/breadcrumbs/breadcrumbs-item.model';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MfBreadcrumbsService {
  private subject: BehaviorSubject<MfBreadcrumbItem[]> = new BehaviorSubject<MfBreadcrumbItem[]>(
    []
  );

  get update$(): Observable<MfBreadcrumbItem[]> {
    return this.subject.asObservable();
  }

  updateBreadcrumbs(items: MfBreadcrumbItem[]): void {
    this.subject.next(items);
  }
}
