export enum MfDataManagerImportStateEnum {
  UPLOADED = 'uploaded',
  QUEUED = 'queued',
  DECRYPTED = 'decrypted',
  UNCOMPRESSED = 'uncompressed',
  STORED = 'stored',
  FAILED = 'failed',
  SUCCESSFUL = 'successful',
  ABORTED = 'aborted',
}
