import { BreakpointObserver } from '@angular/cdk/layout';
import { CdkScrollable } from '@angular/cdk/overlay';
import { NgClass, NgIf } from '@angular/common';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { MfDataManagerWidgetComponent } from '@app/data-manager/components/widget/widget.component';
import { MfLayoutFooterComponent } from '@app/layout/footer/footer.component';
import { MfLayoutHeaderComponent } from '@app/layout/header/header.component';
import { MfLayoutSidenavComponent } from '@app/layout/sidenav/sidenav.component';
import { MfUnsubscribeComponent } from '@app/shared/abstract/unsubscribe.component';
import { MfNavigationService } from '@app/shared/navigation/navigation.service';
import { isMfAppArea } from '@app/shared/navigation/navigation.types';
import { ScrollService } from '@app/shared/scroll/scroll.service';
import { MfBreakpoints } from '@app/shared/util/custom-breakpoints';
import { fromEvent } from 'rxjs';
import { debounceTime, filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'mf-layout-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  imports: [
    RouterOutlet,
    CdkScrollable,
    NgClass,
    NgIf,
    MfDataManagerWidgetComponent,
    MfLayoutSidenavComponent,
    MfLayoutHeaderComponent,
    MfLayoutFooterComponent,
  ],
  standalone: true,
})
export class MfLayoutPageComponent extends MfUnsubscribeComponent implements OnInit, AfterViewInit {
  public isSticky: boolean = false;
  public sidenavIsOpen: boolean = false;

  @ViewChild('content') content?: ElementRef<HTMLDivElement>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private scrollService: ScrollService,
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: MfNavigationService
  ) {
    super();
  }

  get headerGradient(): string {
    return this.route.snapshot.data?.['headerGradient'] ?? '';
  }

  ngOnInit(): void {
    this.enableBreakpointListener();
    this.initRouteChangeListener();

    this.route.data
      .pipe(
        this.takeUntilUnsubscribe(),
        startWith(this.route.snapshot.data),
        map((data) => {
          const appArea = data['area'];

          return isMfAppArea(appArea) ? appArea : undefined;
        })
      )
      .subscribe((appArea) => {
        this.navigationService.setAppArea(appArea);
      });
  }

  ngAfterViewInit(): void {
    if (this.content?.nativeElement) {
      this.scrollService.registerScrollContainer(this.content.nativeElement);

      fromEvent(this.content.nativeElement, 'scroll')
        .pipe(this.takeUntilUnsubscribe(), debounceTime(10))
        .subscribe((event: any) => {
          this.onScroll(event);
        });
    }
  }

  private initRouteChangeListener(): void {
    this.router.events
      .pipe(
        this.takeUntilUnsubscribe(),
        filter((event) => event instanceof NavigationEnd),
        debounceTime(100)
      )
      .subscribe({
        next: () => {
          this.sidenavIsOpen = false;
        },
      });
  }

  private enableBreakpointListener(): void {
    this.breakpointObserver
      .observe([MfBreakpoints.XL])
      .pipe(this.takeUntilUnsubscribe(), debounceTime(10))
      .subscribe((bs) => {
        const shouldBeOpen = !bs.matches;

        if (this.sidenavIsOpen && !shouldBeOpen) {
          this.sidenavIsOpen = false;
        }
      });
  }

  toggleSidenav(): void {
    this.sidenavIsOpen = !this.sidenavIsOpen;
  }

  onScroll(event: any): void {
    this.scrollService.triggerScroll(event);

    const distanceToTop = event.target.scrollTop;
    this.isSticky = distanceToTop >= 250;
  }
}
