import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MfUserService } from '@app/shared/authentication/user/user.service';

@Component({
  selector: 'mf-dashboard-redirect',
  template: '',
  standalone: true,
})
export class MfDashboardRedirectComponent implements OnInit {
  constructor(
    private userService: MfUserService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.redirectUrl();
  }

  redirectUrl(): void {
    const dashboard: string[] = [`/${this.userService.getUserDefaultRoute()}`];
    const login: string[] = ['/benutzerkonto/einloggen'];

    this.router.navigate(this.userService.isLoggedIn() ? dashboard : login);
  }
}
