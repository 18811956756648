<h1 class="mat-headline mb-4" mat-dialog-title>{{ data.title }}</h1>
<div mat-dialog-content>
  <p *ngIf="data.message" class="mb-4" [innerHTML]="message"></p>
  <ul *ngIf="list" class="dialog-list">
    <li *ngFor="let item of list">{{ item }}</li>
  </ul>
  <div *ngIf="isLoadingList" class="py-1">
    <mat-progress-spinner color="primary" diameter="18" mode="indeterminate" />
  </div>
  <ng-template [ngIf]="data.doNotShowAgainKey">
    <ng-container *transloco="let t">
      <mf-form-checkbox
        [control]="doNotShowAgainControl"
        [label]="t('DO_NOT_SHOW_AGAIN')"
        type="single"
      />
    </ng-container>
  </ng-template>
</div>
<div class="styled" mat-dialog-actions>
  <mf-tertiary-button
    *ngIf="data.action.secondary"
    [disabled]="isSubmitting"
    [label]="data.action.secondary.label"
    (clicked)="clickedSecondaryAction()"
  />

  <ng-template [ngIf]="data.action.primary">
    <mf-tertiary-button
      *ngIf="!data.options?.usePrimaryButton"
      [hasInitialFocus]="!data.noInitialFocus"
      [isLoading]="isSubmitting"
      [label]="data.action.primary.label"
      (clicked)="clickedPrimaryAction()"
    />

    <mf-primary-button
      *ngIf="data.options?.usePrimaryButton"
      [hasInitialFocus]="!data.noInitialFocus"
      [isLoading]="isSubmitting"
      [label]="data.action.primary.label"
      (clicked)="clickedPrimaryAction()"
    />
  </ng-template>
</div>
