import { CommonModule } from '@angular/common';
import { Component, Input, booleanAttribute } from '@angular/core';
import { MaterialModule } from '@app/material/material.module';
import { MfCardComponent } from '@shared/components/card/card.component';
import { Maybe } from '@shared/types/util.types';

@Component({
  selector: 'mf-data-manager-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule, MfCardComponent],
})
export class MfDataManagerCardComponent {
  @Input() iconClass?: string;
  @Input() icon: Maybe<string>;

  @Input({ transform: booleanAttribute }) isDeactivated: boolean = false;
  @Input({ transform: booleanAttribute }) isWidget: boolean = false;
  @Input({ transform: booleanAttribute }) iconAnimated: boolean = false;
}
