import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy } from '@angular/router';

/* This can be updated to also cache routes based on data attribute: https://stackoverflow.com/a/57425744
 *
 * Usage: Simply add the following data-block to the route definition:
 * data: {
 *   neverReuseRoute: true
 * }
 * Routes decorated with this attribute will never be reused, even when the url stays the same
 */
@Injectable({ providedIn: 'root' })
export class MfCustomRouteReuseStrategy extends BaseRouteReuseStrategy {
  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    if (future.data['neverReuseRoute']) {
      return false;
    }

    return super.shouldReuseRoute(future, curr);
  }
}
