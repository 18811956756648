import { Injectable } from '@angular/core';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { MfNotificationContentComponent } from '@app/shared/notification/content/content.component';
import { Notification } from '@app/shared/notification/notification.model';

@Injectable({
  providedIn: 'root',
})
export class MfNotificationService {
  constructor(private matSnackBar: MatSnackBar) {}

  send(
    notification: Notification,
    translationScope?: string
  ): MatSnackBarRef<MfNotificationContentComponent> {
    return this.matSnackBar.openFromComponent(MfNotificationContentComponent, {
      data: { notification, translationScope },
      panelClass: 'notification',
      duration: notification.duration,
    });
  }

  success(message: string, translationScope?: string) {
    return this.send(Notification.success(message), translationScope);
  }

  error(message: string, translationScope?: string) {
    return this.send(Notification.error(message), translationScope);
  }
}
