import { NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { MaterialModule } from '@app/material/material.module';
import { MfUnsubscribeComponent } from '@app/shared/abstract/unsubscribe.component';
import { MfBreadcrumbItem } from '@app/shared/breadcrumbs/breadcrumbs-item.model';
import { MfBreadcrumbsService } from '@app/shared/breadcrumbs/breadcrumbs.service';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'mf-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoModule, MaterialModule, RouterLink, NgForOf, NgIf],
  standalone: true,
})
export class MfBreadcrumbsComponent extends MfUnsubscribeComponent implements OnInit {
  public items: MfBreadcrumbItem[] = [];

  constructor(
    private breadcrumbsService: MfBreadcrumbsService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.breadcrumbsService.update$
      .pipe(this.takeUntilUnsubscribe())
      .subscribe((items: MfBreadcrumbItem[]) => {
        this.items = items;

        this.cd.detectChanges();
      });
  }
}
