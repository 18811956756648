<ng-container *transloco="let t">
  <div *ngIf="labels" class="flex flex-col">
    <div
      *ngFor="let label of labels"
      class="validator mb-1 flex"
      [ngClass]="field?.dirty ? (getValidatorError(label) ? 'invalid' : 'valid') : null"
    >
      <mat-icon *ngIf="field?.dirty" [svgIcon]="getValidatorError(label) ? 'close' : 'success'" />
      <span>{{ t(label) }}</span>
    </div>
  </div>
</ng-container>
