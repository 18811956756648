import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({ template: '' })
export abstract class MfFormAbstractFieldComponent implements OnInit {
  @Input() formGroup?: UntypedFormGroup;
  @Input() control!: AbstractControl;
  @Input() name: string;

  public id: string;

  protected constructor() {
    this.id = crypto.randomUUID();
    this.name = '';
  }

  ngOnInit(): void {
    if (!this.control) {
      this.control = this.formGroup?.get(this.name)!;
    }

    if (!this.control) {
      throw Error(
        `Control (id: ${this.id}, name: ${this.name || 'undefined'}) could not resolve a control`
      );
    }
  }
}
