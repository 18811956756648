import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { TranslocoService } from '@jsverse/transloco';
import { take } from 'rxjs/operators';

@Injectable()
export class MfPaginatorIntl extends MatPaginatorIntl {
  public override itemsPerPageLabel: string = '';
  public override nextPageLabel: string = '';
  public override previousPageLabel: string = '';
  public override firstPageLabel: string = '';
  public override lastPageLabel: string = '';
  public of: string = '';

  constructor(private transloco: TranslocoService) {
    super();

    this.transloco
      .selectTranslateObject('SHARED.PAGINATION')
      .pipe(take(1))
      .subscribe((resp: Record<string, string>) => {
        this.itemsPerPageLabel = resp['ITEMS_PER_PAGE_LABEL'];
        this.nextPageLabel = resp['NEXT_PAGE_LABEL'];
        this.previousPageLabel = resp['PREVIOUS_PAGE_LABEL'];
        this.firstPageLabel = resp['FIRST_PAGE_LABEL'];
        this.lastPageLabel = resp['LAST_PAGE_LABEL'];
        this.of = resp['OF'];

        this.changes.next();
      });
  }

  public override getRangeLabel = (page: number, pageSize: number, length: number): string => {
    if (!length || !pageSize) {
      return `0 ${this.of} ${length}`;
    }

    const sanitizedLength = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < sanitizedLength
        ? Math.min(startIndex + pageSize, sanitizedLength)
        : startIndex + pageSize;

    return `${startIndex + 1} - ${endIndex} ${this.of} ${sanitizedLength}`;
  };
}
