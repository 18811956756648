import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  ActivateUserRequestParams,
  ActivateUserResponse,
  ConfirmEmailRequestParams,
  ConfirmEmailResponse,
} from '@app/shared/authentication/types/activate-user.types';
import { LoginRequestParams, TokenResponse } from '@app/shared/authentication/types/login.types';
import { MfUserService } from '@app/shared/authentication/user/user.service';
import { MfBankCustomizationService } from '@app/shared/bank-customization/bank-customization.service';
import { MfDataUserInterface } from '@app/shared/data/data.interface';
import { MfHttpErrorResponse } from '@app/shared/data/error.interface';
import { MfUserCustomizationService } from '@app/shared/user-customization/user-customization.service';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MfAuthenticationService {
  static readonly REDIRECT_URL_KEY: string = 'login_redirect_url';
  static readonly ACCESS_TOKEN_KEY: string = 'token';

  public static getAccessToken(): string | null {
    return localStorage.getItem(this.ACCESS_TOKEN_KEY);
  }

  public static setAccessToken(value: string): string {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, value);

    return value;
  }

  public static clearAccessToken(): void {
    localStorage.removeItem(this.ACCESS_TOKEN_KEY);
  }

  public static setRedirectUrl(url: string): void {
    localStorage.setItem(this.REDIRECT_URL_KEY, url);
  }

  public static clearRedirectUrl(): void {
    localStorage.removeItem(this.REDIRECT_URL_KEY);
  }

  constructor(
    private http: HttpClient,
    private router: Router,
    private userService: MfUserService,
    private bankCustomizationService: MfBankCustomizationService,
    private userCustomizationService: MfUserCustomizationService
  ) {}

  public getRedirectUrl(): string {
    const storageUrl = localStorage.getItem(MfAuthenticationService.REDIRECT_URL_KEY);

    return storageUrl && this.userService.isAllowedToAccessRoute(storageUrl)
      ? storageUrl
      : `/${this.userService.getUserDefaultRoute()}`;
  }

  public login(parameters: LoginRequestParams): Observable<TokenResponse> {
    return this.http.post<TokenResponse>('v1/login_check', parameters).pipe(
      map((data) => {
        MfAuthenticationService.setAccessToken(data.token);

        return data;
      }),
      catchError((errorResponse: MfHttpErrorResponse) => {
        if (errorResponse.error) {
          if (
            errorResponse.status === 400 &&
            errorResponse.error?.message === 'JWT Token not found'
          ) {
            this.logout();
          }
        }

        return throwError(errorResponse);
      })
    );
  }

  public checkConfirmationToken(confirmationToken: string): Observable<MfDataUserInterface> {
    return this.http.post<MfDataUserInterface>('v1/security/check-confirmation-token', {
      confirmationToken,
    });
  }

  public activate(parameters: ActivateUserRequestParams): Observable<ActivateUserResponse> {
    return this.http.post<ActivateUserResponse>('v1/users/activate', parameters);
  }

  public confirmEmail(parameters: ConfirmEmailRequestParams): Observable<ConfirmEmailResponse> {
    return this.http.post<ConfirmEmailResponse>('v1/users/confirm-email', parameters);
  }

  public clearSessionData(): void {
    MfAuthenticationService.clearAccessToken();
    this.userService.clear();
    this.bankCustomizationService.clear();
    this.userCustomizationService.clear();
  }

  public logout(skipRedirect: boolean = false): void {
    this.clearSessionData();

    if (!skipRedirect) {
      this.router.navigate(['/benutzerkonto/einloggen']);
    }
  }
}
