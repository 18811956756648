import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router } from '@angular/router';
import { MfUserService } from '@app/shared/authentication/user/user.service';
import { MfBankCustomizationService } from '@app/shared/bank-customization/bank-customization.service';
import { BankFeatures } from '@app/shared/bank-customization/bank-customization.type';
import { ArrayUtil } from '@app/shared/util/array.util';

@Injectable({
  providedIn: 'root',
})
export class MfBankCustomizationFeatureGuard {
  constructor(
    private bankCustomizationService: MfBankCustomizationService,
    private router: Router,
    private userService: MfUserService
  ) {}

  canLoad(route: Route): boolean {
    return this.checkFeatures(route);
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this.checkFeatures(route);
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot): boolean {
    return this.canActivate(childRoute);
  }

  // checkFeatures(route: ActivatedRouteSnapshot | Route): boolean {
  //   const requiredFeature: string = route.data?.features?.bank || '';
  //   const enabledFeatures: string[] = this.bankCustomizationService.getEnabledFeatures();
  //
  //   const isAllowedToAccess: boolean =
  //     !requiredFeature || enabledFeatures.includes(requiredFeature);
  //
  //   if (isAllowedToAccess) {
  //     return true;
  //   } else {
  //     const defaultRoute: string = this.userService.getUserDefaultRoute();
  //
  //     // Try to redirect to default route as user would otherwise be stuck on an empty page
  //     if (defaultRoute) {
  //       this.router.navigate([defaultRoute]);
  //     }
  //     // TODO: Maybe we should add a general "your bank doesn't have this feature" page to have a fallback
  //
  //     return false;
  //   }
  // }

  checkFeatures(route: ActivatedRouteSnapshot | Route): boolean {
    const requiredFeatures: BankFeatures[] = ArrayUtil.toArray(route.data?.['features']?.bank);
    if (!requiredFeatures.length) {
      return true;
    }

    const enabledFeatures: string[] = this.bankCustomizationService.getEnabledFeatures();

    if (enabledFeatures.length) {
      for (const enabledFeature of enabledFeatures) {
        for (const requiredFeature of requiredFeatures) {
          if (enabledFeature === requiredFeature) {
            return true;
          }
        }
      }
    }

    const defaultRoute: string = this.userService.getUserDefaultRoute();

    // Try to redirect to default route as user would otherwise be stuck on an empty page
    if (defaultRoute) {
      this.router.navigate([defaultRoute]);
    }
    // TODO: Maybe we should add a general "your bank doesn't have this feature" page to have a fallback

    return false;
  }
}
