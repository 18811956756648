<button
  class="info-hover-icon"
  (click)="handleClick($event)"
  (mouseenter)="handleMouseEnter()"
  (mouseleave)="handleMouseLeave()"
  #overlayToggle
  type="button"
>
  <mat-icon svgIcon="info" />
</button>

<ng-template #content>
  <div class="info-hover-card">
    <div class="header-wrapper">
      <mat-icon svgIcon="info" />

      <div [class.has-title]="title && text" [innerHTML]="title || text"></div>
    </div>
    <ng-content />
    <div *ngIf="text && title" class="content" [innerHTML]="text"></div>
  </div>
</ng-template>
