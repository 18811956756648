import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { EnvironmentTypeEnum } from '@env/environment-type.enum';

@Injectable({
  providedIn: 'any',
})
export class MfDevOnlyRouteGuard {
  canLoad(): boolean {
    return [
      EnvironmentTypeEnum.LOCAL,
      EnvironmentTypeEnum.QS,
      EnvironmentTypeEnum.STAGING,
    ].includes(environment.type);
  }
}
