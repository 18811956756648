<ng-container *transloco="let t">
  <mat-toolbar>
    <div class="footer-links flex items-center justify-between pt-0">
      <a class="footer-logo" href="https://indiwe.de/" rel="noopener" target="_blank">
        <img alt="{{ t('SHARED.INDIWE') }}" src="/assets/images/logos/logo_indiwe-gmbh_white.svg" />
      </a>

      <a href="https://indiwe.de/impressum" rel="noopener" target="_blank">
        <span>{{ t('FOOTER.SITE_NOTICE') }}</span>
      </a>

      <a href="https://indiwe.de/datenschutzhinweis" rel="noopener" target="_blank">
        <span>{{ t('FOOTER.PRIVACY') }}</span>
      </a>

      <a href="https://www.linkedin.com/groups/12565788" target="_blank">
        {{ t('FOOTER.LINKEDIN') }}
      </a>
    </div>
  </mat-toolbar>
</ng-container>
