import { MfUserModel } from '@app/shared/authentication/user/user.model';

export enum MfUserEventType {
  LOGIN = 'login',
  UPDATED = 'update',
  LOGOUT = 'logout',
}

export type MfUserEvent = MfUserEventMap[MfUserEventType];

export interface MfUserEventMap {
  [MfUserEventType.LOGIN]: { type: MfUserEventType.LOGIN; data: MfUserModel };
  [MfUserEventType.UPDATED]: { type: MfUserEventType.UPDATED; data: MfUserModel };
  [MfUserEventType.LOGOUT]: { type: MfUserEventType.LOGOUT };
}
