import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { MultipleHintsTemplatesEnum } from '@app/form/field/shared/multi-hint-handler/templates/multiple-hints-templates.enum';
import { MultipleHintsTemplatesPasswordLabelsEnum } from '@app/form/field/shared/multi-hint-handler/templates/password/password-template.class';
import { MaterialModule } from '@app/material/material.module';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'mf-form-util-multi-hint-handler',
  templateUrl: './multi-hint-handler.component.html',
  styleUrls: ['./multi-hint-handler.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule, TranslocoModule],
})
export class MfMultiHintHandlerComponent {
  @Input() field?: AbstractControl;
  @Input()
  get type() {
    return this._type;
  }
  set type(type: MultipleHintsTemplatesEnum | undefined) {
    this._type = type;

    switch (type) {
      case MultipleHintsTemplatesEnum.PASSWORD:
      case MultipleHintsTemplatesEnum.PSEUDONYMIZATION_PASSWORD:
        let entries = Object.entries(MultipleHintsTemplatesPasswordLabelsEnum);

        switch (type) {
          case MultipleHintsTemplatesEnum.PASSWORD:
            entries = entries.filter(([key]) => key !== 'HINT_NOT_EQUAL_OLD_PASSWORD');
            break;

          case MultipleHintsTemplatesEnum.PSEUDONYMIZATION_PASSWORD:
            entries = entries.filter(([key]) => key !== 'HINT_LAST_10');
            break;
        }

        this.labels = entries.map(([_key, value]) => value);
        break;

      default:
        this.labels = undefined;
        break;
    }
  }
  private _type?: MultipleHintsTemplatesEnum;

  public labels?: string[];

  getValidatorError(validator: string): ValidationErrors | null {
    switch (this.type) {
      case MultipleHintsTemplatesEnum.PASSWORD:
      case MultipleHintsTemplatesEnum.PSEUDONYMIZATION_PASSWORD:
        return this.getPasswordValidatorError(validator);

      default:
        return null;
    }
  }

  private getPasswordValidatorError(validator: string): ValidationErrors | null {
    const errors = this.field?.errors;

    if (errors) {
      switch (validator) {
        case MultipleHintsTemplatesPasswordLabelsEnum.HINT_MIN_10:
          return errors['lengthUnder10'];
        case MultipleHintsTemplatesPasswordLabelsEnum.HINT_MIN_UPPER:
          return errors['noUpperCase'];
        case MultipleHintsTemplatesPasswordLabelsEnum.HINT_MIN_LOWER:
          return errors['noLowerCase'];
        case MultipleHintsTemplatesPasswordLabelsEnum.HINT_MIN_NUMBER:
          return errors['noNumber'];
        case MultipleHintsTemplatesPasswordLabelsEnum.HINT_MIN_SPECIAL:
          return errors['noSpecialChars'];
        case MultipleHintsTemplatesPasswordLabelsEnum.HINT_LAST_10:
          return errors['equalToLastTen'];
        case MultipleHintsTemplatesPasswordLabelsEnum.HINT_NOT_EQUAL_OLD_PASSWORD:
          return errors['notEqualOldPassword'];
      }
    }

    return null;
  }
}
