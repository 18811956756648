import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { Maybe } from '@shared/types/util.types';
import { MathUtil } from '@shared/util/math.util';

@Pipe({
  name: 'uploadTimeEstimate',
  standalone: true,
})
export class MfDataManagerUploadTimeEstimatePipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(milliseconds: Maybe<number>): string {
    if (milliseconds && isFinite(milliseconds)) {
      const totalSeconds: number = Math.round(milliseconds / 1000);
      // eslint-disable-next-line prefer-const
      let [minutes, seconds]: [number, number] = MathUtil.divisionWithRemainder(totalSeconds, 60);

      if (minutes) {
        // Round minutes by leftover seconds
        if (seconds >= 30) {
          ++minutes;
        }

        return this.translocoService.translate(
          'IMPORT.UPLOAD_CARD.UPLOAD_TIME_ESTIMATE.MINUTES_LEFT',
          {
            minutes,
          },
          'dataTransfer'
        );
      } else {
        return this.translocoService.translate(
          'IMPORT.UPLOAD_CARD.UPLOAD_TIME_ESTIMATE.SECONDS_LEFT',
          {
            seconds,
          },
          'dataTransfer'
        );
      }
    }

    return this.translocoService.translate(
      'IMPORT.UPLOAD_CARD.UPLOAD_TIME_ESTIMATE.CALCULATING',
      {},
      'dataTransfer'
    );
  }
}
