import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { MfVimeoVideoComponent } from '@app/shared/components/vimeo-video/vimeo-video.component';
import { MfVimeoVideoDialogInterface } from '@app/shared/dialog/vimeo-video-dialog/vimeo-video-dialog.interface';

@Component({
  selector: 'mf-vimeo-video-dialog',
  templateUrl: './vimeo-video-dialog.component.html',
  standalone: true,
  imports: [CommonModule, MfVimeoVideoComponent],
})
export class MfVimeoVideoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MfVimeoVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MfVimeoVideoDialogInterface
  ) {}

  get options() {
    return { width: this.width };
  }

  get width() {
    return (
      (this.dialogRef as any)?._containerInstance?._elementRef?.nativeElement?.clientWidth ?? 600
    );
  }
}
