import { CommonModule } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  booleanAttribute,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormGroup } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { MfDataManagerCardComponent } from '@app/data-manager/components/card/card.component';
import { MfDataManagerExportDownloaderService } from '@app/data-manager/components/export/downloader/downloader.service';
import { MfDataManagerExportDownloaderStatusEnum } from '@app/data-manager/components/export/downloader/status.enum';
import { MfFormInputComponent } from '@app/form/field/input/input.component';
import { MfFormFocusFirstInvalidFieldDirective } from '@app/form/field/shared/focus-first-invalid-field/focus-first-invalid-field.directive';
import { MaterialModule } from '@app/material/material.module';
import { TranslocoModule } from '@jsverse/transloco';
import { MfUnsubscribeComponent } from '@shared/abstract/unsubscribe.component';
import { MfPrimaryButtonComponent } from '@shared/components/buttons/primary/primary.component';
import { MfSecondaryButtonComponent } from '@shared/components/buttons/secondary/secondary.component';
import { MfLoaderComponent } from '@shared/components/loading/loader/loader.component';

@Component({
  selector: 'mf-data-manager-export-downloader',
  templateUrl: './downloader.component.html',
  standalone: true,
  imports: [
    TranslocoModule,
    CommonModule,
    MfDataManagerCardComponent,
    ReactiveFormsModule,
    MfFormFocusFirstInvalidFieldDirective,
    MaterialModule,
    RouterLink,
    MfLoaderComponent,
    MfPrimaryButtonComponent,
    MfFormInputComponent,
    MfSecondaryButtonComponent,
  ],
})
export class MfDataManagerExportDownloaderComponent
  extends MfUnsubscribeComponent
  implements OnInit, OnDestroy
{
  @Input({ transform: booleanAttribute }) isWidget: boolean = false;

  constructor(
    private exportDownloaderService: MfDataManagerExportDownloaderService,
    private cd: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.exportDownloaderService.onInit();

    this.exportDownloaderService.statusAsObservable
      .pipe(this.takeUntilUnsubscribe())
      .subscribe(() => {
        // Explicitly detect changes as status may get changed outside ngZone which will not cause the components view to update
        this.cd.detectChanges();
      });
  }

  override ngOnDestroy(): void {
    this.exportDownloaderService.onDestroy();
  }

  get form(): UntypedFormGroup {
    return this.exportDownloaderService.form;
  }

  get icon() {
    return this.exportDownloaderService.icon;
  }

  get iconAnimated() {
    return this.exportDownloaderService.iconAnimated;
  }

  get iconClass() {
    return this.exportDownloaderService.iconClass;
  }

  get status(): MfDataManagerExportDownloaderStatusEnum {
    return this.exportDownloaderService.status;
  }

  get lastRefresh() {
    return this.exportDownloaderService.lastRefresh;
  }

  get minutesToGenerate(): number {
    const minutes: number = Math.ceil(this.exportDownloaderService.exportLines / 1e5);

    return minutes > 1 ? minutes : 1;
  }

  cancel(): void {
    this.exportDownloaderService.cancel();
  }

  onSubmit(): void {
    this.exportDownloaderService.onSubmit();
  }

  regenerate(): void {
    this.exportDownloaderService.regenerate();
  }

  downloadFile(): void {
    this.exportDownloaderService.downloadFile();
  }

  protected readonly MfDataManagerExportDownloaderStatusEnum =
    MfDataManagerExportDownloaderStatusEnum;
}
