import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { Attribute, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl } from '@angular/forms';
import { MfFormAbstractFieldComponent } from '@app/form/field/shared/abstract-field.component';
import { MfFormService } from '@app/form/field/shared/form.service';
import { MaterialModule } from '@app/material/material.module';
import { MfFileSizePipe } from '@app/shared/pipes/file-size/file-size.pipe';
import { SentryConfig } from '@app/shared/sentry/sentry-config';
import { fileToBase64 } from '@app/shared/util/file/file-to-base64.util';
import { getImageDimensions } from '@app/shared/util/file/image-size.util';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { take } from 'rxjs/operators';

@Component({
  selector: 'mf-form-image-input',
  templateUrl: './image-input.component.html',
  styleUrls: ['./image-input.component.scss'],
  providers: [MfFileSizePipe],
  standalone: true,
  imports: [CommonModule, TranslocoModule, MaterialModule],
})
export class MfFormImageInputComponent
  extends MfFormAbstractFieldComponent
  implements OnInit, OnChanges
{
  @Input() allowedMimeTypes: string[] = ['image/png'];
  @Input() maxAspectRatio?: [number, number]; // for an aspect ratio of 16:9 pass [16,9]
  @Input() filenameControl?: UntypedFormControl;
  @Input() filename?: string;

  @Input()
  get showOptionalHint(): boolean {
    return this._showOptionalHint;
  }
  set showOptionalHint(value: BooleanInput) {
    this._showOptionalHint = coerceBooleanProperty(value);
  }
  private _showOptionalHint: boolean = false;

  @Input()
  get maxHeight(): number | undefined {
    return this._maxHeight;
  }
  set maxHeight(value: NumberInput) {
    this._maxHeight = coerceNumberProperty(value, undefined);
  }
  private _maxHeight?: number = undefined;

  @Input()
  get maxWidth(): number | undefined {
    return this._maxWidth;
  }
  set maxWidth(value: NumberInput) {
    this._maxWidth = coerceNumberProperty(value, undefined);
  }
  private _maxWidth?: number = undefined;

  @Input() // max filesize in mb
  get maxSize(): number {
    return this._maxSize;
  }
  set maxSize(value: NumberInput) {
    this._maxSize = coerceNumberProperty(value, 0);
  }
  private _maxSize: number = 0;

  @Input()
  get showAsterisk(): boolean {
    return this._showAsterisk;
  }
  set showAsterisk(value: BooleanInput) {
    this._showAsterisk = coerceBooleanProperty(value);
  }
  private _showAsterisk: boolean = false;

  get hideAsterisk(): boolean {
    return !this._showAsterisk;
  }

  @Input()
  get noBottomOffset(): boolean {
    return this._noBottomOffset;
  }
  set noBottomOffset(value: BooleanInput) {
    this._noBottomOffset = coerceBooleanProperty(value);
  }
  private _noBottomOffset: boolean = false;

  // Use filename control instead of value control for validation purposes. This behaviour is kind of required
  // when using a string value as these controls in most cases won't have the required validators
  @Input()
  get validateFromFilename(): boolean {
    return this._validateFromFilename;
  }
  set validateFromFilename(value: BooleanInput) {
    this._validateFromFilename = coerceBooleanProperty(value);
  }
  private _validateFromFilename: boolean = false;

  error: string = '';
  selectedFile: File | null = null;
  maxFileSize!: number;
  isRequired: boolean = false;
  formattedFormats: string = '';

  constructor(
    @Attribute('valueType') public valueType: 'file' | 'string',
    @Attribute('translationPrefix') public readonly translationPrefix: string,
    @Attribute('translationPrefixScope') public readonly translationPrefixScope: string,
    private cdr: ChangeDetectorRef,
    private transloco: TranslocoService,
    private formService: MfFormService,
    private fileSizePipe: MfFileSizePipe
  ) {
    super();
    this.valueType = valueType || 'file';
    this.translationPrefix = translationPrefix || 'SHARED.FORMS.ERROR.';
  }

  override ngOnInit(): void {
    this.maxFileSize = this.maxSize * 1000 * 1000; // convert mb to bytes
    this.isRequired =
      this.formService.initRequiredStatus(this.hideAsterisk, this.control) ||
      (this.filenameControl
        ? this.formService.initRequiredStatus(this.hideAsterisk, this.filenameControl)
        : false);
    this.updateFormattedFormats();
  }

  ngOnChanges(): void {
    this.updateFormattedFormats();
  }

  get displayedFileName(): string {
    if (this.selectedFile) {
      return this.selectedFile.name;
    } else {
      return this.filename || this.filenameControl?.value || '';
    }
  }

  get mimeTypesAsString(): string {
    return this.allowedMimeTypes.join(',');
  }

  get validationControl(): AbstractControl {
    if (this.validateFromFilename && this.filenameControl) {
      return this.filenameControl;
    }

    return this.control;
  }

  get shouldShowRequiredError(): boolean {
    return this.validationControl.touched && this.validationControl.invalid;
  }

  get exampleSizes(): [number, number] {
    if (!this.maxAspectRatio) {
      return [0, 0];
    }

    const factor: number = Math.max(...this.maxAspectRatio) > 100 ? 10 : 100;

    return [this.maxAspectRatio[0] * factor, this.maxAspectRatio[1] * factor];
  }

  triggerSelectFile(input: HTMLInputElement): void {
    if (input) {
      input.value = '';
      input.click();
    }
  }

  async handleSelectFile(target: Event): Promise<void> {
    const files = (target?.target as HTMLInputElement)?.files;
    const file = files?.item(0);

    if (!file) {
      return;
    }

    await this.checkAndSetFile(file);
  }

  async checkAndSetFile(file: File): Promise<void> {
    this.error = '';

    if (!this.allowedMimeTypes.includes(file.type)) {
      this.error = this.transloco.translate(this.translationPrefix + 'IMAGE_FORMAT_WRONG_PNG');

      return;
    }

    if (this.maxFileSize && file.size > this.maxFileSize) {
      this.error = this.transloco.translate(this.translationPrefix + 'IMAGE_FILE_SIZE_TOO_LARGE', {
        size: this.fileSizePipe.transform(this.maxFileSize),
      });

      return;
    }

    try {
      const { width, height }: { width: number; height: number } =
        await this.getImageDimensions(file);

      if (this.maxWidth && this.maxHeight) {
        if (width <= this.maxWidth && height <= this.maxHeight) {
          this.updateFile(file);
        } else {
          this.error = this.transloco.translate('SHARED.FORMS.ERROR.IMAGE_SIZE_TO_LARGE', {
            width: this.maxWidth,
            height: this.maxHeight,
          });
        }
      } else if (this.maxAspectRatio) {
        const maxAspectRatioDecimal: number = this.maxAspectRatio[0] / this.maxAspectRatio[1];
        if (width / height <= maxAspectRatioDecimal) {
          this.updateFile(file);
        } else {
          this.error = this.transloco.translate('SHARED.FORMS.ERROR.IMAGE_ASPECT_RATIO_TOO_LARGE', {
            aspectRatio: `${this.maxAspectRatio[0]}:${this.maxAspectRatio[1]}`,
          });
        }
      }
    } catch {
      this.error = this.transloco.translate('SHARED.FORMS.ERROR.FILE_ERROR');
    }
  }

  getImageDimensions(file: File): Promise<{ width: number; height: number }> {
    return getImageDimensions(file);
  }

  removeFile(): void {
    this.selectedFile = null;

    if (this.filenameControl) {
      this.filenameControl.setValue('');
      this.filenameControl.markAsDirty();
    }

    this.updateControlValue(null);
  }

  private updateFormattedFormats(): void {
    this.transloco
      .selectTranslate([
        'SHARED.FORMS.UPLOAD.FORMAT_TYPE',
        'SHARED.FORMS.UPLOAD.FORMAT_TYPE_SEPERATOR',
      ])
      .pipe(take(1))
      .subscribe(() => {
        this.formattedFormats = this.allowedMimeTypes
          .map((mimeType: string) =>
            this.transloco.translate('SHARED.FORMS.UPLOAD.FORMAT_TYPE', {
              type: mimeType.split('/')?.[1].toUpperCase(),
            })
          )
          .join(' ' + this.transloco.translate('SHARED.FORMS.UPLOAD.FORMAT_TYPE_SEPERATOR') + ' ');
      });
  }

  private async updateFile(file: File): Promise<void> {
    this.selectedFile = file;

    if (this.valueType === 'file') {
      this.updateControlValue(file);
      this.updateFilenameControl(file.name);
    } else {
      try {
        const base64: string = await this.fileToBase64(file);

        this.updateControlValue(base64);
        this.updateFilenameControl(file.name);
      } catch (error: any) {
        console.warn('Failed to convert file to base64!', { error, file });
        SentryConfig.captureMessage(
          'MfFormImageInputComponent.updateFile(): Failed to convert file to base64!'
        );

        this.error = this.transloco.translate('SHARED.FORMS.ERROR.FILE_ERROR');
      }
    }

    this.cdr.detectChanges();
  }

  fileToBase64(file: File): Promise<string> {
    return fileToBase64(file);
  }

  private updateFilenameControl(value: string): void {
    if (this.filenameControl) {
      this.filenameControl.setValue(value);
      this.filenameControl.markAsDirty();
    }
  }

  private updateControlValue(value: string | File | null): void {
    this.control.setValue(value);
    this.control.markAsDirty();
  }
}
