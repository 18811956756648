import {
  BooleanInput,
  coerceBooleanProperty,
  coerceNumberProperty,
  NumberInput,
} from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Attribute,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MfFormAbstractFieldComponent } from '@app/form/field/shared/abstract-field.component';
import { MfFormErrorHandlerComponent } from '@app/form/field/shared/error-handler/error-handler.component';
import { MfFormService } from '@app/form/field/shared/form.service';
import { MfFormHintHandlerComponent } from '@app/form/field/shared/hint-handler/hint-handler.component';
import { MfMultiHintHandlerComponent } from '@app/form/field/shared/multi-hint-handler/multi-hint-handler.component';
import { MultipleHintsTemplatesEnum } from '@app/form/field/shared/multi-hint-handler/templates/multiple-hints-templates.enum';
import { MfFormInputType } from '@app/form/field/shared/util/form-submit-state';
import { MaterialModule } from '@app/material/material.module';
import { TranslocoModule } from '@jsverse/transloco';

@Component({
  selector: 'mf-form-input',
  templateUrl: './input.component.html',
  styles: [
    `
      :host {
        display: block;
        line-height: 0;
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoModule,
    MaterialModule,
    MfFormHintHandlerComponent,
    MfFormErrorHandlerComponent,
    MfMultiHintHandlerComponent,
  ],
})
export class MfFormInputComponent
  extends MfFormAbstractFieldComponent
  implements OnInit, AfterViewInit
{
  @ViewChild('passwordInput') passwordInput?: ElementRef;

  @Input() textPrefix?: string;
  @Input() label?: string;
  @Input() hintLabel?: string;
  @Input() placeholder?: string;
  @Input() multipleHintsTemplate?: MultipleHintsTemplatesEnum;
  @Input() suffix?: string;

  @Input()
  get showAsterisk(): boolean {
    return this._showAsterisk;
  }
  set showAsterisk(value: BooleanInput) {
    this._showAsterisk = coerceBooleanProperty(value);
  }
  private _showAsterisk: boolean = false;

  get hideAsterisk(): boolean {
    return !this._showAsterisk;
  }

  @Input()
  get showOptionalHint(): boolean {
    return this._showOptionalHint;
  }
  set showOptionalHint(value: BooleanInput) {
    this._showOptionalHint = coerceBooleanProperty(value);
  }
  private _showOptionalHint: boolean = false;

  @Input()
  get passwordHasToggleButton(): boolean {
    return this._passwordHasToggleButton;
  }
  set passwordHasToggleButton(value: BooleanInput) {
    this._passwordHasToggleButton = coerceBooleanProperty(value);
  }
  private _passwordHasToggleButton: boolean = false;

  @Input()
  get showValidationSuccess(): boolean {
    return this._showValidationSuccess;
  }
  set showValidationSuccess(value: BooleanInput) {
    this._showValidationSuccess = coerceBooleanProperty(value);
  }
  private _showValidationSuccess: boolean = false;

  @Input()
  get noBottomOffset(): boolean {
    return this._noBottomOffset;
  }
  set noBottomOffset(value: BooleanInput) {
    this._noBottomOffset = coerceBooleanProperty(value);
  }
  private _noBottomOffset: boolean = false;

  @Input()
  get noLabel(): boolean {
    return this._noLabel;
  }
  set noLabel(value: BooleanInput) {
    this._noLabel = coerceBooleanProperty(value);
  }
  private _noLabel: boolean = false;

  @Input()
  get hideErrorMessage(): boolean {
    return this._hideErrorMessage;
  }
  set hideErrorMessage(value: BooleanInput) {
    this._hideErrorMessage = coerceBooleanProperty(value);
  }
  private _hideErrorMessage: boolean = false;

  @Input()
  get hideNumberSpinner(): boolean {
    return this._hideNumberSpinner;
  }
  set hideNumberSpinner(value: BooleanInput) {
    this._hideNumberSpinner = coerceBooleanProperty(value);
  }
  private _hideNumberSpinner: boolean = false;

  @Input()
  get hideInfoIcon(): boolean {
    return this._hideInfoIcon;
  }
  set hideInfoIcon(value: BooleanInput) {
    this._hideInfoIcon = coerceBooleanProperty(value);
  }
  private _hideInfoIcon: boolean = false;

  @Input()
  get autoFocus(): boolean {
    return this._autoFocus;
  }
  set autoFocus(value: BooleanInput) {
    this._autoFocus = coerceBooleanProperty(value);
  }
  private _autoFocus: boolean = false;

  @Input()
  get maxLengthHint(): number | undefined {
    return this._maxLengthHint;
  }
  set maxLengthHint(value: NumberInput) {
    this._maxLengthHint = coerceNumberProperty(value, undefined);
  }
  private _maxLengthHint?: number = undefined;

  @Input()
  get min() {
    return this._min;
  }
  set min(value: NumberInput) {
    this._min = coerceNumberProperty(value, undefined);
  }
  private _min?: number = undefined;

  @Input()
  get max() {
    return this._max;
  }
  set max(value: NumberInput) {
    this._max = coerceNumberProperty(value, undefined);
  }
  private _max?: number = undefined;

  public isRequired: boolean = false;

  constructor(
    @Attribute('translationPrefix') public readonly translationPrefix: string,
    @Attribute('translationPrefixScope') public readonly translationPrefixScope: string,
    @Attribute('autocomplete') public readonly autocomplete: string,
    @Attribute('patternName') public readonly patternName: string,
    @Attribute('type') public type: MfFormInputType,
    private formService: MfFormService
  ) {
    super();
    this.translationPrefix = translationPrefix || 'SHARED.FORMS.ERROR.';
    this.type = type || 'text';
    this.placeholder = '';
  }

  override ngOnInit(): void {
    super.ngOnInit();

    this.isRequired = this.formService.initRequiredStatus(this.hideAsterisk, this.control);
  }

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      setTimeout(() => {
        this.passwordInput?.nativeElement.focus();
      });
    }
  }

  public toggleShowPassword(): void {
    this.type = this.passwordHasToggleButton && this.type === 'password' ? 'text' : 'password';
  }
}
