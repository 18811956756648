<ng-container *transloco="let t">
  <div *ngIf="entityName && userName" class="mf-sidenav-user-actions py-2">
    <a
      class="item with-icon"
      routerLink="/hilfe-informationen"
      routerLinkActive="text-primary-500 font-bold"
    >
      <mat-icon svgIcon="help" />
      <span>{{ t('SIDENAV.NAVIGATION.SUPPORT_AND_INFO') }}</span>
    </a>

    <button class="item with-icon w-full" [matMenuTriggerFor]="userActionsMenu" type="button">
      <mat-icon svgIcon="user_outline" />

      <div class="flex min-w-0 flex-1 flex-col justify-start text-left leading-snug">
        <span class="truncate font-bold">{{ userName }}</span>
        <small class="truncate text-monochrome-800">{{ entityName }}</small>
      </div>

      <mat-icon svgIcon="arrow_drop_down" />
    </button>
    <mat-menu
      class="mf-sidenav-user-menu"
      #userActionsMenu="matMenu"
      xPosition="before"
      yPosition="above"
    >
      <button class="text-sm" (click)="logout()" mat-menu-item type="button">
        {{ t('SIDENAV.USER_ACTIONS.LOGOUT') }}
      </button>
    </mat-menu>
  </div>
</ng-container>
