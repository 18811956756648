<ng-container *transloco="let t; prefix: 'SHARED.FORMS.HTML_INPUT.PLACEHOLDER.SELECT'">
  <div mat-dialog-title>
    <h2 class="mat-headline">{{ t('TITLE') }}</h2>
  </div>

  <div mat-dialog-content>
    <div class="sticky top-0 bg-white pb-4">
      <mf-table-search [placeholder]="t('SEARCH')" (search)="handleSearchChange($event)" />
    </div>

    <div class="flex flex-col space-y-4">
      <ng-template [ngIf]="filteredPlaceholders.length" [ngIfElse]="noSearchResults">
        <div *ngFor="let group of filteredPlaceholders">
          <div class="font-bold text-primary-500" [innerHTML]="group.label"></div>
          <div class="flex flex-col">
            <button
              *ngFor="let item of group.items"
              class="w-full py-1 text-left text-monochrome-900 hover:text-primary-500"
              [innerHTML]="item.label"
              (click)="close(item.placeholders)"
            ></button>
          </div>
        </div>
      </ng-template>
      <ng-template #noSearchResults>
        <div class="flex items-center space-x-2 px-4 py-6">
          <mat-icon svgIcon="help" />
          <p>{{ t('NO_SEARCH_RESULTS') }}</p>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="styled" mat-dialog-actions>
    <mf-tertiary-button [label]="'SHARED.ACTION.CANCEL' | transloco" (clicked)="close()" />
  </div>
</ng-container>
