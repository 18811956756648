export enum MfSelectionTypeEnum {
  TYPE_INCLUSION = 'type.inclusion',
  TYPE_EXCLUSION = 'type.exclusion',
  TYPE_OCCASION = 'type.occasion',
}

// Note: The following types do not exist in the backend and are just created to
// enable re-usage of selection components for MfMarketplaceOverarchingSelection's
export enum MfOverarchingSelectionTypeEnum {
  TYPE_OVERARCHING_INCLUSION = 'type.overarching_inclusion',
}

// Define as type and const in order to enable type and value usages
export type MfAllSelectionTypes = MfSelectionTypeEnum | MfOverarchingSelectionTypeEnum;
export const MfAllSelectionTypes = { ...MfSelectionTypeEnum, ...MfOverarchingSelectionTypeEnum };
