import { MfUserRolesEnum } from '@app/shared/authentication/types/user_roles.enum';
import { BankFeatures } from '@app/shared/bank-customization/bank-customization.type';
import { MfSidenavTopLevelItemInterface } from '@app/shared/navigation/navigation.types';
import { MARKETINGFABRIK_ROLES } from '@app/shared/upsert-user/upsert-user.const';

export const MARKETPLACE_NAVIGATION: MfSidenavTopLevelItemInterface[] = [
  {
    title: 'SIDENAV.NAVIGATION.MARKETPLACE.BUYING_AREA.TITLE',
    children: [
      {
        title: 'SIDENAV.NAVIGATION.MARKETPLACE.BUYING_AREA.OVERVIEW',
        link: '/marktplatz',
        visibleForRoles: [MfUserRolesEnum.MARKETPLACE_BUYER],
        requiredBankFeatures: [BankFeatures.MARKETPLACE_BUYER],
      },
      {
        title: 'SIDENAV.NAVIGATION.MARKETPLACE.BUYING_AREA.SUBSCRIPTIONS',
        link: '/marktplatz/abonnements',
        visibleForRoles: [MfUserRolesEnum.MARKETPLACE_BUYER],
        requiredBankFeatures: [BankFeatures.MARKETPLACE_BUYER],
      },
      {
        title: 'SIDENAV.NAVIGATION.MARKETPLACE.BUYING_AREA.ORDERS',
        link: '/marktplatz/bestellungen',
        visibleForRoles: [MfUserRolesEnum.MARKETPLACE_BUYER],
        requiredBankFeatures: [BankFeatures.MARKETPLACE_BUYER],
      },
    ],
  },
  {
    title: 'SIDENAV.NAVIGATION.MARKETPLACE.SELLING_AREA.TITLE',
    children: [
      {
        title: 'SIDENAV.NAVIGATION.MARKETPLACE.SELLING_AREA.PRODUCTS.TITLE',
        link: '/marktplatz/kampagnenangebot',
        visibleForRoles: [MfUserRolesEnum.MARKETPLACE_SELLER],
        requiredBankFeatures: [BankFeatures.MARKETPLACE_SELLER],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.MARKETPLACE.SELLING_AREA.PRODUCTS.NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.MARKETPLACE.SELLING_AREA.PRODUCTS.EDIT',
            link: '/bearbeiten',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.MARKETPLACE.SELLING_AREA.LANDINGPAGES.TITLE',
        link: '/marktplatz/kampagnenseiten',
        visibleForRoles: [MfUserRolesEnum.MARKETPLACE_SELLER],
        requiredBankFeatures: [BankFeatures.MARKETPLACE_LANDINGPAGES],
        children: [
          {
            title: 'SIDENAV.NAVIGATION.MARKETPLACE.SELLING_AREA.LANDINGPAGES.NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.MARKETPLACE.SELLING_AREA.LANDINGPAGES.EDIT',
            link: '/bearbeiten',
          },
        ],
      },
      {
        title: 'SIDENAV.NAVIGATION.MARKETPLACE.SELLING_AREA.PROVIDER_SETTINGS',
        link: '/marktplatz/anbieter/einstellungen',
        visibleForRoles: [MfUserRolesEnum.MARKETPLACE_SELLER],
        requiredBankFeatures: [BankFeatures.MARKETPLACE_SUBSCRIPTION_PROVIDER],
      },
    ],
  },
  {
    title: 'SIDENAV.NAVIGATION.MARKETPLACE.TARGET_GROUP.TITLE',
    visibleForRoles: [MfUserRolesEnum.MARKETPLACE_SELLER],
    requiredBankFeatures: [BankFeatures.MARKETPLACE_SELLER],
    children: [
      {
        title: 'SIDENAV.NAVIGATION.MARKETPLACE.TARGET_GROUP.SELECTIONS.TITLE',
        link: '/marktplatz/selektionen',
        children: [
          {
            title: 'SIDENAV.NAVIGATION.MARKETPLACE.TARGET_GROUP.SELECTIONS.NEW',
            link: '/neu',
          },
          {
            title: 'SIDENAV.NAVIGATION.MARKETPLACE.TARGET_GROUP.SELECTIONS.EDIT',
            link: '/bearbeiten',
          },
        ],
      },
    ],
  },
  {
    title: '',
    visibleForRoles: MARKETINGFABRIK_ROLES,
    children: [
      {
        title: 'SIDENAV.NAVIGATION.MARKETPLACE.BACK_TO_MAFA',
        link: '/',
        doNotUseAsDefaultRoute: true,
      },
    ],
  },
];
