import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MfIconsMap } from './icons.const';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private loaded: boolean = false;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  public init(): void {
    if (this.loaded) {
      return;
    }

    Object.entries(MfIconsMap).forEach(([name, src]) => {
      this.addIcon(name, src);
    });
    this.loaded = true;
  }

  private addIcon(name: string, srcName: string): void {
    this.iconRegistry.addSvgIcon(
      name,
      this.sanitizer.bypassSecurityTrustResourceUrl(`assets/images/icons/${srcName}`)
    );
  }
}
