import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA,
  MatLegacyDialogModule,
  MatLegacyDialogRef,
} from '@angular/material/legacy-dialog';
import { MfFormSelectComponent } from '@app/form/field/select/select.component';
import { MfFormFocusFirstInvalidFieldDirective } from '@app/form/field/shared/focus-first-invalid-field/focus-first-invalid-field.directive';
import { MaterialModule } from '@app/material/material.module';
import { TranslocoModule } from '@jsverse/transloco';
import { MfUnsubscribeComponent } from '@shared/abstract/unsubscribe.component';
import { MfPrimaryButtonComponent } from '@shared/components/buttons/primary/primary.component';
import { MfTertiaryButtonComponent } from '@shared/components/buttons/tertiary/tertiary.component';
import { MfInlineEditComboboxDialogData } from '@shared/dialog/inline-edit-combobox-dialog/inline-edit-combobox-dialog.interface';
import { MfValueLabelModel } from '@shared/value-label/value-label.model';
import { Observable, finalize, of } from 'rxjs';

@Component({
  selector: 'mf-inline-edit-combobox-dialog',
  templateUrl: './inline-edit-combobox-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    MaterialModule,
    MfTertiaryButtonComponent,
    MfPrimaryButtonComponent,
    MfFormSelectComponent,
    MfFormFocusFirstInvalidFieldDirective,
    MatLegacyDialogModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MfInlineEditComboboxDialogComponent extends MfUnsubscribeComponent implements OnInit {
  public readonly form = this.createForm();
  public items: MfValueLabelModel[] = [];
  public loading: boolean = false;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: MfInlineEditComboboxDialogData,
    public dialogRef: MatLegacyDialogRef<MfInlineEditComboboxDialogComponent>,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit() {
    this.loadItems();
  }

  private loadItems(): void {
    this.loading = true;

    let items$: Observable<MfValueLabelModel[]>;

    if (this.data.items instanceof Observable) {
      items$ = this.data.items;
    } else {
      items$ = of(this.data.items);
    }

    items$
      .pipe(
        this.takeUntilUnsubscribe(),
        finalize(() => (this.loading = false))
      )
      .subscribe((items) => {
        this.items = items;
        this.cdr.detectChanges();
      });
  }

  createForm() {
    return new FormGroup({
      item: new FormControl(this.data.value, {
        nonNullable: true,
        validators: [...(this.data.optional ? [] : [Validators.required])],
      }),
    });
  }

  close() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      return;
    }

    if (!this.form.value.item) {
      this.dialogRef.close(null);
    }

    this.dialogRef.close(this.items.find((item) => item.value === this.form.value.item) || null);
  }
}
