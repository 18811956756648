import { Directive, ElementRef, HostListener, NgZone } from '@angular/core';
import { MfFormErrorScrollUtilService } from '@app/form/shared/error-scroll/error-scroll-util.service';

@Directive({
  selector: 'form[mfFocusFirstInvalidField]',
  standalone: true,
})
export class MfFormFocusFirstInvalidFieldDirective {
  constructor(
    private readonly hostElement: ElementRef<HTMLFormElement>,
    private readonly zone: NgZone,
    private readonly errorScrollUtil: MfFormErrorScrollUtilService
  ) {}

  @HostListener('submit')
  onSubmit(): void {
    this.zone.run(() => {
      let errorElement = this.errorScrollUtil.getFirstElementWithError(
        this.hostElement.nativeElement
      );

      if (!errorElement && this.hostElement.nativeElement.classList.contains('ng-invalid')) {
        errorElement = this.hostElement.nativeElement;
      }

      if (errorElement && errorElement instanceof HTMLElement) {
        errorElement.focus();
      }
    });
  }
}
