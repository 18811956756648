import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  MAT_LEGACY_DIALOG_DATA,
  MatLegacyDialogModule,
  MatLegacyDialogRef,
} from '@angular/material/legacy-dialog';
import { MfFormFocusFirstInvalidFieldDirective } from '@app/form/field/shared/focus-first-invalid-field/focus-first-invalid-field.directive';
import { MfFormUrlComponent } from '@app/form/field/url/url.component';
import { MaterialModule } from '@app/material/material.module';
import { TranslocoModule } from '@jsverse/transloco';
import { MfUnsubscribeComponent } from '@shared/abstract/unsubscribe.component';
import { MfPrimaryButtonComponent } from '@shared/components/buttons/primary/primary.component';
import { MfTertiaryButtonComponent } from '@shared/components/buttons/tertiary/tertiary.component';
import { MfInlineEditImageSourceDialogData } from '@shared/dialog/inline-edit-image-source-dialog/inline-edit-image-source-dialog.interface';

@Component({
  selector: 'mf-inline-edit-image-source-dialog',
  templateUrl: './inline-edit-image-source-dialog.component.html',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    ReactiveFormsModule,
    MaterialModule,
    MfTertiaryButtonComponent,
    MfFormUrlComponent,
    MfPrimaryButtonComponent,
    MfFormFocusFirstInvalidFieldDirective,
    MatLegacyDialogModule,
  ],
})
export class MfInlineEditImageSourceDialogComponent extends MfUnsubscribeComponent {
  public readonly form = this.createForm();
  public imageFound = false;

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: MfInlineEditImageSourceDialogData,
    public dialogRef: MatLegacyDialogRef<MfInlineEditImageSourceDialogComponent>
  ) {
    super();
  }

  createForm() {
    return new FormGroup({
      imageSrc: new FormControl<string>(this.data.imageSrc, {
        nonNullable: true,
        validators: [
          Validators.maxLength(this.data.maxLength),
          ...(this.data.optional ? [] : [Validators.required]),
        ],
      }),
    });
  }

  close() {
    this.dialogRef.close();
  }

  load() {
    this.imageFound = true;
    this.form.controls.imageSrc.setErrors(null);
  }

  error() {
    this.imageFound = false;
  }

  onSubmit() {
    this.form.markAllAsTouched();

    if (!this.imageFound && this.form.controls.imageSrc.value.length) {
      this.form.controls.imageSrc.setErrors({ imageNotFound: true });
    }

    if (this.form.invalid) {
      return;
    }

    this.dialogRef.close(this.form.value.imageSrc || null);
  }
}
