export enum MfUserRolesEnum {
  BANK_ADMIN = 'ROLE_BANK_ADMIN',
  CAMPAIGN_MANAGER = 'ROLE_CAMPAIGN_MANAGER',
  DATA_MANAGER = 'ROLE_DATA_MANAGER',
  REVISION = 'ROLE_REVISION',
  CONTRACT_MANAGER = 'ROLE_CONTRACT_MANAGER',
  BACK_OFFICE = 'ROLE_BACK_OFFICE',
  PARTNER_BANK_EMPLOYEE = 'ROLE_PARTNER_BANK_EMPLOYEE',

  PARTNER_ADMIN = 'ROLE_PARTNER_ADMIN',
  PARTNER_MARKETING_MANAGER = 'ROLE_PARTNER_MARKETING_MANAGER',
  PARTNER_DATA_MANAGER = 'ROLE_PARTNER_DATA_MANAGER',

  ADMIN_BANK_MANAGEMENT = 'ROLE_ADMIN_BANK_MANAGEMENT',
  ADMIN_SUPER_USER_MANAGEMENT = 'ROLE_ADMIN_SUPER_USER_MANAGEMENT',
  ADMIN_USER_MANAGEMENT = 'ROLE_ADMIN_USER_MANAGEMENT',
  ADMIN_EMAIL_TEMPLATES = 'ROLE_ADMIN_EMAIL_TEMPLATES',
  ADMIN_DOCUMENT_MANAGEMENT = 'ROLE_ADMIN_DOCUMENT_MANAGEMENT',
  ADMIN_MAILJET = 'ROLE_ADMIN_MAILJET',
  ADMIN_DEPARTMENT_X = 'ROLE_ADMIN_DEPARTMENT_X',
  ADMIN_MEDIA_LIBRARY = 'ROLE_ADMIN_MEDIA_LIBRARY',
  ADMIN_IDA = 'ROLE_ADMIN_IDA',
  ADMIN_VIDEO_MANAGEMENT = 'ROLE_ADMIN_VIDEO_MANAGEMENT',
  ADMIN_CONTRACT_MANAGEMENT = 'ROLE_ADMIN_CONTRACT_MANAGEMENT',
  ADMIN_BACK_OFFICE = 'ROLE_ADMIN_BACK_OFFICE',

  MARKETPLACE_SELLER = 'ROLE_MARKETPLACE_SELLER',
  MARKETPLACE_BUYER = 'ROLE_MARKETPLACE_BUYER',
}

export const isMfUserRole = (role: any): role is MfUserRolesEnum =>
  Object.values(MfUserRolesEnum).includes(role);
