import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { MfUserService } from '@app/shared/authentication/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class MfOnlyAnonymusGuard {
  constructor(
    private router: Router,
    private userService: MfUserService
  ) {}

  // @ts-ignore route unused
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAnonymus();
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  checkAnonymus(): boolean {
    if (!this.userService.isLoggedInWithRoleCheck()) {
      return true;
    }

    this.router.navigate([this.userService.getUserDefaultRoute()]);

    return false;
  }
}
