<div *ngIf="control" class="pb-4" [ngClass]="[name]" #componentWrapper>
  <mat-label *ngIf="label" class="mat-label mat-caption mb-2 ml-2">{{ label }}</mat-label>

  <div class="mb-1 flex flex-col">
    <quill-editor
      class="flex-1"
      [attr.name]="name"
      [bounds]="componentWrapper"
      [class.error]="hasError"
      [class.warning]="hasWarning"
      [formats]="quillFormats"
      [modules]="quillModules"
      [ngModel]="quillContent"
      [placeholder]="placeholder"
      [required]="isRequired"
      (ngModelChange)="onChangeQuillContent($event)"
      (onBlur)="handleBlur()"
      (onEditorCreated)="editorRef = $event"
      format="html"
      linkPlaceholder="https://marketingfabrik.app"
    >
      <div quill-editor-toolbar>
        <span
          *ngIf="
            hasToolbarFeatures([
              MfHtmlInputFeatures.BOLD,
              MfHtmlInputFeatures.ITALIC,
              MfHtmlInputFeatures.UNDERLINE,
            ])
          "
          class="ql-formats"
        >
          <button
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.BOLD])"
            class="ql-bold"
            matTooltip="Fett"
          ></button>
          <button
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.ITALIC])"
            class="ql-italic"
            matTooltip="Kursiv"
          ></button>
          <button
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.UNDERLINE])"
            class="ql-underline"
            matTooltip="Unterstrichen"
          ></button>
        </span>
        <span *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.COLOR])" class="ql-formats">
          <select
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.COLOR])"
            class="ql-color"
            matTooltip="Schriftfarbe ändern"
          ></select>
        </span>
        <span
          *ngIf="
            hasToolbarFeatures([
              MfHtmlInputFeatures.TEXT_LEFT,
              MfHtmlInputFeatures.TEXT_RIGHT,
              MfHtmlInputFeatures.TEXT_CENTER,
              MfHtmlInputFeatures.TEXT_JUSTIFY,
            ])
          "
          class="ql-formats"
        >
          <button
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.TEXT_LEFT])"
            class="ql-align"
            matTooltip="Linksbündig"
            value=""
          ></button>
          <button
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.TEXT_CENTER])"
            class="ql-align"
            matTooltip="Zentriert"
            value="center"
          ></button>
          <button
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.TEXT_RIGHT])"
            class="ql-align"
            matTooltip="Rechtsbündig"
            value="right"
          ></button>
          <button
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.TEXT_JUSTIFY])"
            class="ql-align"
            matTooltip="Blocksatz"
            value="justify"
          ></button>
        </span>
        <span
          *ngIf="
            hasToolbarFeatures([MfHtmlInputFeatures.LIST_ORDERED, MfHtmlInputFeatures.LIST_BULLET])
          "
          class="ql-formats"
        >
          <button
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.LIST_ORDERED])"
            class="ql-list"
            matTooltip="Nummerierte Liste"
            value="ordered"
          ></button>
          <button
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.LIST_BULLET])"
            class="ql-list"
            matTooltip="Aufzählungsliste"
            value="bullet"
          ></button>
        </span>
        <span *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.SIZE])" class="ql-formats">
          <select *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.SIZE])" class="ql-size">
            <ng-container *ngFor="let size of fontSizes; trackBy: trackByIndex">
              <option *ngIf="size; else emptyOption" [value]="size"></option>
            </ng-container>
            <ng-template #emptyOption>
              <option></option>
            </ng-template>
          </select>
        </span>
        <span *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.LINKS])" class="ql-formats">
          <button
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.LINKS])"
            class="ql-link"
            matTooltip="Link"
          ></button>
        </span>
        <span *ngIf="placeholderItems?.length" class="ql-formats">
          <button
            class="mf-ql-placeholder"
            (click)="openPlaceholderSelect()"
            matTooltip="Variable einfügen"
            type="button"
          >
            <mat-icon aria-hidden="true" svgIcon="add" />
            <span>Variable</span>
          </button>
        </span>
        <span *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.CLEAN])" class="ql-formats">
          <button
            *ngIf="hasToolbarFeatures([MfHtmlInputFeatures.CLEAN])"
            class="ql-clean"
            matTooltip="Formatierung löschen"
          ></button>
        </span>
      </div>
    </quill-editor>
  </div>

  <ng-template #hintTemplate>
    <mat-hint>
      <mf-form-util-hint-handler
        [field]="control"
        [hasWarning]="hasWarning"
        [hideInfoIcon]="hideInfoIcon"
        [hintLabel]="hintLabel"
        [isRequired]="isRequired"
        [length]="inputLength"
        [maxLengthHint]="maxLengthHint!"
        [patternName]="patternName"
        [showOptionalHint]="showOptionalHint"
        [warningLabel]="warningLabel"
      />
    </mat-hint>
  </ng-template>

  <div class="hint-wrapper">
    <ng-container *ngIf="hasError; else hintTemplate">
      <mat-error [hidden]="hideErrorMessage" [@fade-toggle]="hasError">
        <mf-form-util-error-handler
          [field]="control"
          [maxLengthHint]="maxLengthHint!"
          [patternName]="patternName"
          [translationPrefix]="translationPrefix"
          [translationPrefixScope]="translationPrefixScope"
        />
      </mat-error>
    </ng-container>
  </div>
</div>
