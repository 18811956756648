import { MfDataManagerImportInterface } from '@app/data-manager/services/import-data/data.interface';
import { MfDataManagerImportStateEnum } from '@app/data-manager/services/import-state/state.enum';
import { ArrayUtil } from '@shared/util/array.util';

export type MfDataManagerImportStateGetStateParamType = {
  _embedded?: { state?: MfDataManagerImportInterface['_embedded']['state'] };
};

export class MfDataManagerImportStateUtil {
  static getFirstState(file: MfDataManagerImportStateGetStateParamType): {
    key: number;
    value: MfDataManagerImportStateEnum;
  } {
    if (!file?._embedded?.state) {
      return { key: Date.now(), value: MfDataManagerImportStateEnum.UPLOADED };
    }

    return file._embedded.state[0];
  }

  static getLastState(file: MfDataManagerImportStateGetStateParamType): {
    key: number;
    value: MfDataManagerImportStateEnum;
  } {
    if (!file?._embedded?.state?.length) {
      return { key: Date.now(), value: MfDataManagerImportStateEnum.UPLOADED };
    }

    return ArrayUtil.lastItem(file._embedded.state)!;
  }

  static isFinished(state: MfDataManagerImportStateEnum): boolean {
    return [
      MfDataManagerImportStateEnum.SUCCESSFUL,
      MfDataManagerImportStateEnum.FAILED,
      MfDataManagerImportStateEnum.ABORTED,
    ].includes(state);
  }

  static isSuccessful(state: MfDataManagerImportStateEnum): boolean {
    return [MfDataManagerImportStateEnum.SUCCESSFUL].includes(state);
  }

  static isStoredOrSuccessful(state: MfDataManagerImportStateEnum): boolean {
    return [MfDataManagerImportStateEnum.SUCCESSFUL, MfDataManagerImportStateEnum.STORED].includes(
      state
    );
  }

  static isError(state: MfDataManagerImportStateEnum): boolean {
    return [MfDataManagerImportStateEnum.FAILED, MfDataManagerImportStateEnum.ABORTED].includes(
      state
    );
  }

  static isImporting(state: MfDataManagerImportStateEnum): boolean {
    return !this.isFinished(state);
  }

  static isUploadComplete(uploadItem: MfDataManagerImportInterface): boolean {
    return this.getLastState(uploadItem)?.value !== MfDataManagerImportStateEnum.UPLOADED;
  }
}
