import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { versionInformation } from '@env/version';
import { Translation, TranslocoLoader } from '@jsverse/transloco';
import { ArrayUtil } from '@shared/util/array.util';

@Injectable({
  providedIn: 'root',
})
export class TranslocoLoaderService implements TranslocoLoader {
  private http = inject(HttpClient);

  getTranslation(lang: string) {
    const preparedLang = lang.split('/').reverse().filter(ArrayUtil.truthyFilter).join('/');

    return this.http.get<Translation>(
      `/assets/i18n/${preparedLang}.json?version=${versionInformation.version}`
    );
  }
}
