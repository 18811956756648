import { Component } from '@angular/core';
import { MaterialModule } from '@app/material/material.module';
import { TranslocoDirective } from '@jsverse/transloco';

@Component({
  selector: 'mf-layout-footer',
  templateUrl: './footer.component.html',
  imports: [MaterialModule, TranslocoDirective],
  standalone: true,
})
export class MfLayoutFooterComponent {}
