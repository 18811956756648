import { registerLocaleData } from '@angular/common';
import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from '@app/app.component';
import { appConfig } from '@app/app.config';
import { SentryConfig } from '@app/shared/sentry/sentry-config';
import { environment } from '@env/environment';

import localeDE from '@angular/common/locales/de';
import localeEN from '@angular/common/locales/en';

if (environment.production) {
  enableProdMode();
}

SentryConfig.initSentry();

registerLocaleData(localeDE);
registerLocaleData(localeEN);
bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
