<mat-toolbar class="relative z-10 bg-transparent" [class.no-line]="noLine">
  <mf-layout-header-content [isSticky]="isSticky" (toggleSidenav)="toggleSidenav.emit()" />
</mat-toolbar>

<mat-toolbar
  class="sticky-header mat-elevation-z5 fixed z-10 flex items-center justify-between bg-white"
  [class.is-sticky]="isSticky"
  [class.no-line]="noLine"
>
  <mf-layout-header-content [isSticky]="isSticky" (toggleSidenav)="toggleSidenav.emit()" />
</mat-toolbar>
