<ng-container *transloco="let t">
  <ng-template [ngIf]="showNavigation">
    <div *ngFor="let item of sidenavItems; trackBy: identifyTopLevel" class="group">
      <div class="header">
        {{ t(item.title) }}
      </div>

      <ng-container *ngFor="let child of item.children; trackBy: identifySecondLevel">
        <a
          *ngIf="!child.hidden"
          class="item"
          [class.active]="child.active"
          [routerLink]="[child.link]"
        >
          {{ t(child.title) }}
        </a>
      </ng-container>
    </div>

    <a
      *ngIf="showToolboxItem"
      class="item with-icon"
      routerLink="/werkzeugkasten"
      routerLinkActive="active"
    >
      <mat-icon svgIcon="toolbox" />
      <span>{{ t('SIDENAV.NAVIGATION.TESTING') }}</span>
    </a>
  </ng-template>
</ng-container>
