import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MfLayoutHeaderContentComponent } from '@app/layout/header/content/content.component';
import { MaterialModule } from '@app/material/material.module';

@Component({
  selector: 'mf-layout-header',
  templateUrl: './header.component.html',
  standalone: true,
  imports: [MaterialModule, MfLayoutHeaderContentComponent],
})
export class MfLayoutHeaderComponent {
  @Input() isSticky: boolean = false;
  @Input() noLine: boolean = false;

  @Output() toggleSidenav: EventEmitter<any> = new EventEmitter<any>();
}
