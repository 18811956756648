import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  MfHtmlInputPlaceholderGroup,
  MfHtmlInputPlaceholderGroupItem,
} from '@app/form/field/html-input/html-input.types';
import { MaterialModule } from '@app/material/material.module';
import { MfTertiaryButtonComponent } from '@app/shared/components/buttons/tertiary/tertiary.component';
import { MfAbstractDialogInjected } from '@app/shared/dialog/service/dialog.interface';
import { MfTableSearchComponent } from '@app/shared/table/search/search.component';
import { MfFilterUtil } from '@app/shared/util/filter.util';
import { TranslocoModule } from '@jsverse/transloco';

type DialogResult = MfHtmlInputPlaceholderGroupItem['placeholders'];

@Component({
  selector: 'mf-placeholder-select',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    MfTableSearchComponent,
    MfTertiaryButtonComponent,
    TranslocoModule,
  ],
  templateUrl: './placeholder-select.component.html',
})
export class MfFormHtmlInputPlaceholderSelectComponent extends MfAbstractDialogInjected<
  MfHtmlInputPlaceholderGroup[],
  DialogResult
> {
  filteredPlaceholders: MfHtmlInputPlaceholderGroup[];

  constructor() {
    super();

    this.filteredPlaceholders = this.data;
  }

  handleSearchChange(value: string) {
    this.filteredPlaceholders = this.getFilteredPlaceholders(value.trim().toLowerCase());
  }

  private getFilteredPlaceholders(needle: string) {
    if (!needle) {
      return this.data;
    }

    const itemResults: MfHtmlInputPlaceholderGroup[] = [];
    const groupResults: MfHtmlInputPlaceholderGroup[] = [];

    for (const group of this.data) {
      const filteredItems: MfHtmlInputPlaceholderGroupItem[] = [];
      group.items.forEach((item) => {
        if (item.label.toLowerCase().includes(needle)) {
          filteredItems.push({ ...item, label: MfFilterUtil.markMatches(item.label, needle) });
        }
      });

      if (filteredItems.length) {
        itemResults.push({
          ...group,
          items: filteredItems,
        });
      }

      if (group.label.toLowerCase().includes(needle)) {
        groupResults.push({
          ...group,
          label: MfFilterUtil.markMatches(group.label, needle),
        });
      }
    }

    return [...itemResults, ...groupResults];
  }
}
