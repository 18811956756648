import { Injectable } from '@angular/core';
import { MfUserModel } from '@app/shared/authentication/user/user.model';
import { Freshdesk } from '@app/shared/freshdesk/freshdesk.service';
import { SentryConfig } from '@app/shared/sentry/sentry-config';
import { SerializationUtil } from '@app/shared/util/serialization/serialization.util';

@Injectable({
  providedIn: 'root',
})
export class MfUserStorage {
  private readonly LOCAL_STORAGE_KEY = 'user';

  public getUser(): MfUserModel | undefined {
    const userString = localStorage.getItem(this.LOCAL_STORAGE_KEY);

    if (userString) {
      let user: MfUserModel | undefined;

      try {
        user = JSON.parse(userString) as MfUserModel;
      } catch {}

      if (user) {
        return SerializationUtil.toInstance(new MfUserModel(), user);
      }
    }

    return undefined;
  }

  public setUser(user: MfUserModel): MfUserStorage {
    localStorage.setItem(this.LOCAL_STORAGE_KEY, JSON.stringify(user));

    SentryConfig.setUser(user);
    Freshdesk.setUser(user);

    return this;
  }

  public clearUser(): void {
    localStorage.removeItem(this.LOCAL_STORAGE_KEY);
  }
}
