<div *transloco="let t" class="form-input-wrap">
  <ng-container *ngIf="type === 'multiple'">
    <mat-label *ngIf="label">{{ label }}</mat-label>

    <mat-selection-list *ngIf="showCheckAll; else multipleCheckboxes" class="checkboxes-list">
      <mat-list-option
        [disabled]="control.disabled"
        [disableRipple]="true"
        [selected]="selectedAll"
        (click)="toggleAll()"
        checkboxPosition="before"
        color="primary"
      >
        <div class="flex items-center space-x-2">
          <span>{{ checkAllLabel || t('SHARED.FORMS.SELECT_ALL') }}</span>
        </div>
      </mat-list-option>
      <div class="ml-7">
        <ng-container [ngTemplateOutlet]="multipleCheckboxes" />
      </div>
    </mat-selection-list>

    <ng-template #multipleCheckboxes>
      <mat-selection-list class="checkboxes-list" [formControl]="$any(control)" [ngClass]="name">
        <mat-list-option
          *ngFor="let item of items; let i = index"
          [disabled]="!!item.disabled || control.disabled"
          [disableRipple]="true"
          [value]="item.value"
          checkboxPosition="before"
          color="primary"
        >
          <div class="flex items-center space-x-2">
            <span [innerHTML]="item.label"></span>
            <ng-template [ngIf]="item.info">
              <mf-info-hover [text]="item.info.text" [title]="item.info.title" />
            </ng-template>
          </div>
          <ng-template [ngIf]="item.subLabel">
            <br />
            <span [innerHTML]="item.subLabel"></span>
          </ng-template>
        </mat-list-option>
      </mat-selection-list>
    </ng-template>
  </ng-container>

  <mat-checkbox
    *ngIf="type === 'single'"
    [disableRipple]="true"
    [formControl]="$any(control)"
    [ngClass]="name"
    color="primary"
  >
    <div class="flex items-center space-x-1">
      <span [class.whitespace-normal]="multilineLabel" [innerHTML]="label"></span>
      <mf-info-hover *ngIf="labelHint" [text]="labelHint" [title]="labelHintTitle" />
    </div>
  </mat-checkbox>

  <div *ngIf="!hideHintErrorArea" class="custom-hint-error-container mt-2">
    <mat-hint>
      <mf-form-util-hint-handler
        [field]="control"
        [hideInfoIcon]="hideInfoIcon"
        [hintLabel]="hintLabel"
        [isRequired]="isRequired"
        [patternName]="patternName"
        [showOptionalHint]="showOptionalHint"
        [showValidationSuccess]="showValidationSuccess"
      />
    </mat-hint>

    <mat-error [hidden]="hideErrorMessage">
      <mf-form-util-error-handler
        [field]="control"
        [patternName]="patternName"
        [translationPrefix]="translationPrefix"
        [translationPrefixScope]="translationPrefixScope"
      />
    </mat-error>
  </div>
</div>
