<mf-card
  class="is-data-upload flex flex-1 basis-full bg-white p-0"
  [class.deactivated]="isDeactivated"
  [ngClass]="isWidget ? 'used-in-widget shadow-lg' : ''"
>
  <div>
    <div *ngIf="iconAnimated && isWidget" class="card-icon flex items-center justify-center">
      <div class="spinner-wrapper">
        <mat-spinner [diameter]="24" />
      </div>
    </div>

    <div
      *ngIf="icon"
      class="card-icon flex items-center justify-center"
      [class.is-animated]="iconAnimated"
    >
      <div *ngIf="iconAnimated && !isWidget" class="spinner-wrapper">
        <mat-spinner [diameter]="48" />
      </div>

      <div class="icon-wrapper flex items-center justify-center" [ngClass]="iconClass">
        <mat-icon [ngClass]="iconClass" [svgIcon]="icon" />
      </div>
    </div>
  </div>
  <div
    class="card-in-widget flex w-full flex-col"
    [ngClass]="isWidget && icon ? 'p-3 pl-0' : isWidget && !icon ? 'py-3' : 'p-4 pb-3'"
  >
    <ng-content />
  </div>
</mf-card>
