import { AbstractControl, FormGroupDirective, NgForm, UntypedFormControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class UrlFieldErrorMatcherClass implements ErrorStateMatcher {
  constructor(private validationControl: AbstractControl) {}

  isErrorState(_: UntypedFormControl | null, __: FormGroupDirective | NgForm | null): boolean {
    return this.validationControl.touched && this.validationControl.invalid;
  }
}
