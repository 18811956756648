import { Injectable } from '@angular/core';
import { ScrollService } from '@app/shared/scroll/scroll.service';

@Injectable({
  providedIn: 'root',
})
export class MfFormErrorScrollUtilService {
  private readonly errorSelector = [
    'mat-form-field.ng-invalid',
    '.custom-form-field.invalid',
    'mf-form-html-input.ng-invalid',
    'mat-selection-list.ng-invalid',
    'quill-editor.error',
  ].join(', ');

  constructor(private scrollService: ScrollService) {}

  getFirstElementWithError(container: Pick<Element, 'querySelector'>) {
    return container.querySelector(this.errorSelector);
  }

  scrollToError(): void {
    let firstElementWithError = this.getFirstElementWithError(document);

    // When no single invalid element can be found, use the first invalid form
    if (!firstElementWithError) {
      firstElementWithError = document.querySelector('form.ng-invalid');
    }

    if (firstElementWithError) {
      const offset: number = 72;
      const scrollContainer = this.scrollService.getScrollContainer();

      if (scrollContainer) {
        const position: number =
          firstElementWithError.getBoundingClientRect().top + scrollContainer.scrollTop - offset;

        scrollContainer.scrollTo({
          top: position,
          behavior: 'smooth',
        });

        return;
      }

      firstElementWithError.scrollIntoView({ behavior: 'smooth' });
    }
  }

  scrollToErrorInContainer(scrollContainer?: HTMLElement): void {
    let firstElementWithError = this.getFirstElementWithError(document);

    // When no single invalid element can be found, use the first invalid form
    if (!firstElementWithError) {
      firstElementWithError = document.querySelector('form.ng-invalid');
    }

    if (firstElementWithError) {
      const offset: number = 40;

      if (scrollContainer) {
        const position: number =
          firstElementWithError.getBoundingClientRect().top -
          scrollContainer.getBoundingClientRect().top +
          scrollContainer.scrollTop -
          offset;

        scrollContainer.scrollTo({
          top: position,
          behavior: 'smooth',
        });

        return;
      }

      firstElementWithError.scrollIntoView({ behavior: 'smooth' });
    }
  }
}
