import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MaterialModule } from '@app/material/material.module';
import { MfFormAbstractButtonComponent } from '../abstract-button.component';

@Component({
  selector: 'mf-primary-button',
  templateUrl: './primary.component.html',
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class MfPrimaryButtonComponent extends MfFormAbstractButtonComponent {}
